import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { ReactSVG } from "react-svg";

import {
  Container,
  LatealMenu,
  //MiniTitleImput,
  Logo,
  Title,
  //Checkbox,
  InfoContainers,
  InfoContainer,
  LabelButton,
  ComponentTable,
  ContainerCadastro,
  //label,
  ContainerInput,
  TitleInput,
  Input,
  CardInput,
  Button,
  LabelButton2,
  Select,
} from "./styles";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import { ToastNotifier } from "../../../helpers/ToastNotifier";
import { useAuth } from "../../../hooks/useAuth";

function CadastroProduto() {
  let history = useHistory();
  const { user, setUser } = useAuth();
  const [Produtos, setProdutos] = useState([]);
  const [produto, setProduto] = useState();
  const [cliente_nome, setClienteNome] = useState();
  const [adesivo_nome, setAdesivoNome] = useState();
  const [area_adesivo, setAreaAdesivo] = useState();
  const [clientes, setClientes] = useState([]);
  const [adesivos, setAdesivos] = useState([]);
  const [adesivo, setAdesivo] = useState([]);
  const [planta_id, setPlantaId] = useState();
  const [plantas, setPlantas] = useState([]);
  const [cliente_id, setClienteId] = useState();
  const [adesivo_id, setAdesivoId] = useState();
  const [admin_id, setAdminId] = useState(user?.id);
  const [volume, setVolume] = useState();
  const [planta, setPlanta] = useState();
  const [local_planta_cliente, setLocalPlantaCliente] = useState();
  const [status_mudanca, setStatusMudanca] = useState(user?.nivel_acesso !== '999' ? "Pendente" : "Aprovado");
  const [isTrue, setIsTrue] = useState(true);


  async function postProdutos() {
    try {

      const data = {
        produto,
        cliente_nome,
        local_planta_cliente,
        planta_id,
        cliente_id,
        area_adesivo,
        adesivo_id,
        adesivo_nome,
        admin_id,
        volume,
        status_mudanca,
      };

      const response = await api.post("/produto", {
        data,
      });

      if (!adesivo_id) {

      }

      if (response.status === 201) {
        ToastNotifier({
          toastMessage: "produto cadastrado com sucesso!",
          toastType: "success",
        });
        history.push("/list_produto");
      } else if (response.status === 403) {
        ToastNotifier({
          toastMessage: "Produto já cadastrado.",
          toastType: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleLogout = () => {
    try {
      setUser(null);
      Cookies.remove("authToken");
      Cookies.remove("authUser");
      history.push("/login");
      window.location.reload();
    } catch (error) {
      console.log(error);
      console.log("Não foi possível deslogar");
    }
  }


  async function getPlanta() {
    try {
      const response = await api.post("/get_cliente_planta", {
        id_cliente: cliente_id,
      });
      console.log(response.data.local_planta[0]);
      setPlantas(response.data.local_planta[0].nome_planta);
      setIsTrue(true);
    } catch (error) {
      setIsTrue(false);
      console.log(error);
    }
  }
  async function getPlantaId() {
    try {
      const response = await api.post("/getPlantaId", {
        id: planta_id,
      });
      setLocalPlantaCliente(response.data.planta[0].nome)
      setIsTrue(true);
    } catch (error) {
      setIsTrue(false);
      console.log(error);
    }
  }
  async function getClientes() {
    const response = await api.get("/cliente");
    setClientes(response.data.clientes);
    setClienteId(response.data.clientes?.[0].id);
  }

  async function getAdesivos() {
    const response = await api.get("/adesivo");
    setAdesivos(response.data.adesivo);
    setAdesivoId(response.data.adesivo?.[0].id);
    console.log("dd", response.data.adesivo)
  }

  useEffect(() => {
    if (cliente_id) {
      getPlanta();
    }
  }, [cliente_id]);

  useEffect(() => {
    getClientes();
    getAdesivos();
  }, []);

  useEffect(() => {
    getPlantaId()
  }, [planta_id]);



  return (
    <Container>
      <LatealMenu>
        <Logo />
        <InfoContainers>
          <InfoContainer>
            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_relatorio");
              }}
            >
              <ReactSVG src="/images/Relatorio.svg" />
              Relatórios
            </LabelButton>) : (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_mudanca");
              }}
            >
              <ReactSVG src="/images/SolicitacaoMudanca.svg" />
              Solicitações de Mudança
            </LabelButton>) : (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_forms_respondidos");
              }}
            >
              <ReactSVG src="/images/FormRespondido.svg" />
              Formulários Respondidos
            </LabelButton>) : (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_adm_intermediario");
              }}
            >
              <ReactSVG src="/images/AdmInter.svg" />
              Cadastro de ADM Intermediário
            </LabelButton>) : (<></>)}

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_cliente");
              }}
            >
              <ReactSVG src="/images/CadastroCliente.svg" />
              Cadastro de Clientes
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_aparelho");
              }}
            >
              <ReactSVG src="/images/CadastroAparelho.svg" />
              Cadastro de Aparelhos
            </LabelButton>

            <LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_guia");
              }}
            >
              <ReactSVG src="/images/CadastroGuia.svg" />
              Cadastro de Guias
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_adesivo");
              }}
            >
              <ReactSVG src="/images/CadastroAdesivo.svg" />
              Cadastro de Adesivos
            </LabelButton>

            <LabelButton
              style={{ gap: "4%", fontWeight: "bold" }}
              onClick={() => {
                history.push("/list_produto");
              }}
            >
              <ReactSVG src="/images/CadastroProduto.svg" />
              Cadastro de Produtos
            </LabelButton>
            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_supervisor");
              }}
            >
              <ReactSVG src="/images/CadastroSupervisor.svg" />
              Cadastro de Supervisores
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_supervisor");
              }}
            >
              <ReactSVG src="/images/CadastroLinha.svg" />
              Cadastro de Linha
            </LabelButton>

            <LabelButton onClick={() => {
              handleLogout()
            }}>
              <ReactSVG src="/images/logOut.svg" />
              Log Out
            </LabelButton>

          </InfoContainer>
        </InfoContainers>
      </LatealMenu>
      <ContainerCadastro>
        <ComponentTable>
          <Title>Cadastro de Produtos</Title>
          <ContainerInput>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CardInput>
                  <TitleInput>Cliente</TitleInput>
                  <form>
                    <Select onChange={(v) => setClienteId(v.target.value)}>
                      <option defaultValue={""}>Selecione um cliente</option>
                      {clientes?.map((item) => (
                        <option value={item.id}>{item.nome}</option>
                      ))}
                    </Select>
                  </form>

                  <TitleInput>Nome do produto</TitleInput>
                  <Input
                    style={{ width: 320 }}
                    onChange={(e) => setProduto(e.target.value)}
                  />

                  <TitleInput>Adesivo utilizado</TitleInput>
                  <form>
                    <Select
                      onChange={(v) => setAdesivoId(v.target.value)}
                    >
                      {adesivos?.map((item) => (
                        <option value={item?.id}>{item.nome_adesivo}</option>
                      ))}
                    </Select>
                  </form>



                </CardInput>


                <CardInput>
                  <TitleInput>Local da Planta do Cliente</TitleInput>
                  <form>
                    <Select onChange={(v) => setPlantaId(v.target.value)}>
                      <option defaultValue={""}>Selecione uma planta</option>
                      {plantas?.map((item) => (
                        <>
                          <option value={item.id}>{item.nome}</option>
                        </>
                      ))}
                    </Select>
                  </form>

                  <TitleInput>Volume do Produto</TitleInput>
                  <form>
                    <Select onChange={(v) => setVolume(v.target.value)}>
                      <option defaultValue={""}>Selecione o volume</option>
                      <option value={"300ml"}>300 ml</option>
                      <option value={"600ml"}>600 ml</option>
                      <option value={"1litro"}>1 Litro</option>
                    </Select>
                  </form>

                  <TitleInput>Área do Adesivo</TitleInput>
                  <Input
                    style={{ width: 320 }}
                    onChange={(e) => setAreaAdesivo(e.target.value)}
                  />

                </CardInput>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "10%",
                  justifyContent: "center",
                }}
              >
                  <Button
                  style={{ borderColor: "red" }}
                  onClick={() => history.push("/list_produto")}>
                  <LabelButton2 style={{ color: "red", textAlign: "center" }}>
                    Cancelar
                  </LabelButton2>
                </Button>
                <Button
                  onClick={() => postProdutos()}
                  style={{ backgroundColor: "red" }}
                >
                  <LabelButton2 style={{ color: "white" }}>
                    Cadastrar
                  </LabelButton2>
                </Button>

              
              </div>

            </div>
          </ContainerInput>
        </ComponentTable>
      </ContainerCadastro>
    </Container>
  );
}
export default CadastroProduto;
