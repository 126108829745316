import React, { useEffect, useState, useContext } from "react";
import { ReactSVG } from "react-svg";
import Cookies from "js-cookie";

import {
  Container,
  LatealMenu,
  Logo,
  Title,
  InfoContainers,
  InfoContainer,
  LabelButton,
  ButtonSave,
  ComponentTable,
  ComponentEdit,
  CardInfos,
  CardInfo,
  Cardlabel,
  CardlabelTitle,
  TitleContainer,
  Button,
  ButtonTitle,
  ContainerCadastro,
  ContainerInput,
  CardInput,
  TitleInput,
  Select,
  Input,
  LabelButton2
} from "./styles";
import MaterialTable from "material-table";
import { FaTrashAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { BsSearch } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import Modal from 'react-modal';
import { useAuth } from "../../../hooks/useAuth";



    





function ListAdmIntermediario() {


  const customStyles = {
    content: {
      width: "70%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      height: "80%",
      zIndex: 999,
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      overflow: "scroll",
    },
  };


  let history = useHistory();
  const [admins, setAdmins] = useState([]);
  const [id_nome, setIdNome] = useState();
  const [area, setArea] = useState();
  const [senha, setSenha] = useState();
  const [nome, setNome] = useState();
  const [email, setEmail] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [currentAdmin, setCurrentAdimn] = React.useState({});
  const {user, setUser} = useAuth();
  async function putAdmins(id_admin) {
    try {
      const response = await api.put(`/update_admin/${id_admin}`, {
        id_nome,
        area,
        senha,
        nome,
        email,
        
      });
      
      console.log("adm atualizado com sucesso!");
      closeModal();
      window.location.reload();
      history.push("/list_adm_intermediario");
    } catch (error) {
      console.log(error);
      console.log("adm atualizado com sucesso!");
    }
  }

  const handleLogout = () => {
    try{
      setUser(null);
      Cookies.remove("authToken");
      Cookies.remove("authUser");
      history.push("/login");
      window.location.reload();
    }catch(error){
      console.log(error);
      console.log("Não foi possível deslogar");
    }
  }
  
  async function getAdmins() {
    const response = await api.get("/admin");
    setAdmins(response?.data?.admin);
    console.log(response.data?.admin);
  }
  // useEffect(
  //   () => window.location.reload(),

  //   []
  // );
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  async function deleted(id_admin) {
    console.log("delete: " + id_admin);
    try{
      const response = await api.delete(`/delete_admin/${id_admin}`)
      console.log(response.data);
    }catch(error){

    }
  }
  useEffect(() => {
    // const timer = setInterval(() => {
      getAdmins();
    // }, 500);
    // return () => clearInterval(timer);
  }, []);
  return (
    <Container>
      
       
      <LatealMenu>
        <Logo />
        <InfoContainers>
        <InfoContainer>
            <LabelButton
            style={{gap: "5%"}}
              onClick={() => {
                history.push("/list_relatorio");
              }}
            >
              <ReactSVG src="/images/Relatorio.svg" />
              Relatórios
            </LabelButton>

            <LabelButton
            style={{gap: "4.5%"}}
              onClick={() => {
                history.push("/list_mudanca");
              }}
            >
              <ReactSVG src="/images/SolicitacaoMudanca.svg" />
              Solicitações de Mudança
            </LabelButton>

            <LabelButton
            style={{gap: "5%"}}
              onClick={() => {
                history.push("/list_forms_respondidos");
              }}
            >
              <ReactSVG src="/images/FormRespondido.svg" />
              Formulários Respondidos
            </LabelButton>
            
            {user?.nivel_acesso === '999' ? (<LabelButton 
            style={{gap: "4.5%", fontWeight: "bold" }}
              onClick={() => {
                history.push("/list_adm_intermediario");
              }}
            >
              <ReactSVG src="/images/AdmInter.svg" />
              Cadastro de ADM Intermediário
            </LabelButton>): (<></>)}

            <LabelButton
            style={{gap: "5%"}}
              onClick={() => {
                history.push("/list_cliente");
              }}
            >
              <ReactSVG src="/images/CadastroCliente.svg" />
              Cadastro de Clientes
            </LabelButton>

            <LabelButton
            style={{gap: "5%"}}
              onClick={() => {
                history.push("/list_aparelho");
              }}
            >
              <ReactSVG src="/images/CadastroAparelho.svg" />
              Cadastro de Aparelhos
            </LabelButton>

            <LabelButton
            style={{gap: "4.5%"}}
              onClick={() => {
                history.push("/list_guia");
              }}
            >
              <ReactSVG src="/images/CadastroGuia.svg" />
              Cadastro de Guias
            </LabelButton>

            <LabelButton
            style={{gap: "5%"}}
              onClick={() => {
                history.push("/list_adesivo");
              }}
            >
              <ReactSVG src="/images/CadastroAdesivo.svg" />
              Cadastro de Adesivos
            </LabelButton>

            <LabelButton
            style={{gap: "4%"}}
              onClick={() => {
                history.push("/list_produto");
              }}
            >
              <ReactSVG src="/images/CadastroProduto.svg" />
              Cadastro de Produtos
            </LabelButton>
            <LabelButton
            style={{gap: "4%"}}
              onClick={() => {
                history.push("/list_supervisor");
              }}
            >
              <ReactSVG src="/images/CadastroSupervisor.svg" />
              Cadastro de Supervisores
            </LabelButton>

            <LabelButton
            style={{gap: "4%"}}
              onClick={() => {
                history.push("/list_linha");
              }}
            >
              <ReactSVG src="/images/CadastroLinha.svg" />
              Cadastro de Linha
            </LabelButton>

            <LabelButton onClick={()=> {
                handleLogout()
              }}>
                <ReactSVG src="/images/logOut.svg" />
              Log Out
            </LabelButton>
            
          </InfoContainer>
        </InfoContainers>
      </LatealMenu>
      <ComponentTable>
        <TitleContainer>
          <Title>Dashboard Henkel</Title>
          <Title>Seja muito bem-vindo</Title>
        </TitleContainer>
        <CardInfos>
          <CardInfo>
            <CardlabelTitle>Total de colaboradores</CardlabelTitle>
            <Cardlabel>350</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Total de x</CardlabelTitle>
            <Cardlabel>50</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Info Y</CardlabelTitle>
            <Cardlabel>20</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Info Z</CardlabelTitle>
            <Cardlabel>10</Cardlabel>
          </CardInfo>
        </CardInfos>

        <MaterialTable
          style={{
            maxHeight: '100%', 
            overflow: 'auto',
            marginTop: 20,
            width: "100%",
          }}
          localization={{
            header: {
              actions: "",
            },
          }}
          actions={[
            {
              icon: () => <FiEdit color="#E1000F" />,
              tooltip: "Update",
              onClick: (event, rowData) => {
                setCurrentAdimn(rowData)
                openModal()
              
              },
            },
            {
              icon: () => (
                <FaTrashAlt
                  color="#E1000F"
                  style={{ position: "absolute", left: "10px" }}
                />
              ),
              tooltip: "delete",
              onClick: 
              
              (event, rowData) => {
                deleted(rowData.id);
                window.location.reload();},
            },
            
            {
              icon: () => (
                <Button onClick={() => history.push("/cadastro_adm_intermediario")}>
                  <ButtonTitle>Cadastrar novo</ButtonTitle>
                </Button>
              ),
              isFreeAction: true,
            },
            
          ]}
          // icons={{
          //   Search:() => <BsSearch color="#E1000F" />
          // }}
          columns={[
            { title: "Nome", field: "nome" },
            { title: "Area", field: "area" },
            { title: "ID", field: "id_nome" },
            { title: "E-mail", field: "email" },
          ]}
          data={admins}
          options={{
            headerStyle: {
              zIndex: modalIsOpen && -1
            }
          }}
          title="Cadastro de ADM Intermediario"
        />
      </ComponentTable>
      <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={{customStyles}}
        >
        <ComponentEdit>
         <Title>Edição de Administradores Intermediários</Title>
          <ContainerInput>
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "row"}}>
            <CardInput>
            <TitleInput>Nome</TitleInput>
              <Input
                style={{ width: 320 }}
                defaultValue={currentAdmin.nome}
                onChange={(e) => setNome(e.target.value)}
              />
              <TitleInput>Email</TitleInput>
              <Input
                style={{ width: 320 }}
                defaultValue={currentAdmin.email}
                onChange={(e) => setEmail(e.target.value)}
              />              
             
            </CardInput>

            <CardInput>
              
              <TitleInput>Área</TitleInput>
              <Input
                style={{ width: 320 }}
                defaultValue={currentAdmin.area}
                onChange={(e) => setArea(e.target.value)}
              />

              <TitleInput>Senha</TitleInput>
              <Input
                style={{ width: 320 }}
                
                onChange={(e) => setSenha(e.target.value)}
              />
              
            </CardInput>
          </div>


          <div
              style={{display: "flex",
              justifyContent: "center",
              alignItens: "center",
              gap: "10%",
              }}>
            

              <ButtonSave
                style={{ borderColor: "red" }}
                onClick={() => setIsOpen(false)}
              >
                <ButtonTitle style={{ color: "red", textAlign: "center" }}>Cancelar</ButtonTitle>
              </ButtonSave>
              <ButtonSave 
                onClick={() => putAdmins(currentAdmin.id)}
                style={{ backgroundColor: "red",  }}>
                <ButtonTitle style={{ color: "white", textAlign: "center" }}>Salvar</ButtonTitle>
              </ButtonSave>
              </div>

            </div>
          </ContainerInput>
          </ComponentEdit>

      </Modal>
    </Container>
  );
}

export default ListAdmIntermediario;
