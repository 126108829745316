import styled from 'styled-components'
import Background from '../../assets/Background.png'
import Logoimg from '../../assets/logo.png'

export const Container = styled.div`
position: fixed;
display: flex;
align-items: center;
justify-content: center;
top: 0;
left: 0;
width: 100%;
height: 100vh;

`
export const LabelButton2 = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  justify-content: center;
  align-self:center;
  text-align:center;
`;

export const Logo = styled.div`
width: 117px;
height: 72px;
background-image: url(${Logoimg});
background-size: cover;
background-repeat: no-repeat;
padding:50px;
margin-bottom:25px;
padding-left:80px;
`
export const Title = styled.h1`
font-family: Arial, Helvetica, sans-serif;
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 30px;
text-align: center;
letter-spacing: 0.3px;
margin-bottom: 40px;

`
export const Body = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 626px;
width: 643px;
background-color: #FFFFFF;
`