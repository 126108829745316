import React, { useEffect, useState, useContext } from "react";
import { ReactSVG } from "react-svg";
import Cookies from "js-cookie";

import {
  Container,
  LatealMenu,
  Logo,
  Title,
  InfoContainers,
  InfoContainer,
  LabelButton,
  ComponentTable,
  ContainerCadastro,
  ContainerInput,
  TitleInput,
  Input,
  CardInput,
  Button,
  LabelButton2,
  Select,
} from "./styles";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import { ToastNotifier } from "../../../helpers/ToastNotifier";
import { useAuth } from "../../../hooks/useAuth";

function CadastroAdmIntermediario() {
  let history = useHistory();
  const [id_nome, setIdNome] = useState();
  const [area, setArea] = useState();
  const [senha, setSenha] = useState();
  const [nome, setNome] = useState();
  const [email, setEmail] = useState();
  const [isTrue, setIsTrue] = useState(true);
  const [status_mudanca, setStatusMudanca] = useState("Aprovado");
  const {user, setUser} = useAuth();
  async function postAdmins() {
    try {
      const response = await api.post("/admin", {
        id_nome,
        area,
        senha,
        nome,
        email,
      });
      if (response.status === 201) {
        ToastNotifier({
          toastMessage: "Administrador cadastrado com sucesso!",
          toastType: "success",
        });
        history.push("/list_adm_intermediario");
      } else if (response.status === 403) {
        ToastNotifier({
          toastMessage: "Email já cadastrado.",
          toastType: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleLogout = () => {
    try{
      setUser(null);
      Cookies.remove("authToken");
      Cookies.remove("authUser");
      history.push("/login");
      window.location.reload();
    }catch(error){
      console.log(error);
      console.log("Não foi possível deslogar");
    }
  }

  return (
    <Container>
      <LatealMenu>
        <Logo />
        <InfoContainers>
        <InfoContainer>
          {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_relatorio");
              }}
            >
              <ReactSVG src="/images/Relatorio.svg" />
              Relatórios
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_mudanca");
              }}
            >
              <ReactSVG src="/images/SolicitacaoMudanca.svg" />
              Solicitações de Mudança
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_forms_respondidos");
              }}
            >
              <ReactSVG src="/images/FormRespondido.svg" />
              Formulários Respondidos
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton 
            style={{gap: "4.5%", fontWeight: "bold" }}
              onClick={() => {
                history.push("/list_adm_intermediario");
              }}
            >
              <ReactSVG src="/images/AdmInter.svg" />
              Cadastro de ADM Intermediário
            </LabelButton>): (<></>)}

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_cliente");
              }}
            >
              <ReactSVG src="/images/CadastroCliente.svg" />
              Cadastro de Clientes
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_aparelho");
              }}
            >
              <ReactSVG src="/images/CadastroAparelho.svg" />
              Cadastro de Aparelhos
            </LabelButton>

            <LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_guia");
              }}
            >
              <ReactSVG src="/images/CadastroGuia.svg" />
              Cadastro de Guias
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_adesivo");
              }}
            >
              <ReactSVG src="/images/CadastroAdesivo.svg" />
              Cadastro de Adesivos
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_produto");
              }}
            >
              <ReactSVG src="/images/CadastroProduto.svg" />
              Cadastro de Produtos
            </LabelButton>
            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_supervisor");
              }}
            >
              <ReactSVG src="/images/CadastroSupervisor.svg" />
              Cadastro de Supervisores
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_linha");
              }}
            >
              <ReactSVG src="/images/CadastroLinha.svg" />
              Cadastro de Linha
            </LabelButton>

            <LabelButton onClick={()=> {
                handleLogout()
              }}>
                <ReactSVG src="/images/logOut.svg" />
              Log Out
            </LabelButton>
            
          </InfoContainer>
        </InfoContainers>
      </LatealMenu>
      <ContainerCadastro>
        <ComponentTable>
          <Title>Cadastro de ADM Intermediário</Title>
          <ContainerInput>
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "row"}}>
            <CardInput>
              <TitleInput>Nome</TitleInput>
              <Input
                style={{ width: 320 }}
                onChange={(e) => setNome(e.target.value)}
              />

              <TitleInput>E-mail</TitleInput>
              <Input
                style={{ width: 320 }}
                onChange={(e) => setEmail(e.target.value)}
              />
              
            </CardInput>

            <CardInput>
              <TitleInput>Área</TitleInput>
              <Input
                style={{ width: 320 }}
                onChange={(e) => setArea(e.target.value)}
              />

              <TitleInput>Senha</TitleInput>
              <Input
                style={{ width: 320 }}
                onChange={(e) => setSenha(e.target.value)}
              />
              
            </CardInput>
                </div>

                <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10%",
                }}
              >
                <Button
                  style={{ borderColor: "red" }}
                  onClick={() => history.push("/list_adm_intermediario")}
                >
                  <LabelButton2 style={{ color: "red", textAlign: "center" }}>
                    Cancelar
                  </LabelButton2>
                </Button>

                
                <Button
                  onClick={() => postAdmins()}
                  style={{ backgroundColor: "red" }}
                >
                  <LabelButton2 style={{ color: "white" }}>
                    Cadastrar
                  </LabelButton2>
                </Button>
              </div>


            </div>
          </ContainerInput>
        </ComponentTable>
      </ContainerCadastro>
    </Container>
  );
}
export default CadastroAdmIntermediario;
