import React from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { history } from "../history";
import Login from "../views/Login";
import ListAparelho  from "../views/Aparelho/ListAparelho";
import CadastroLogin from "../views/Aparelho/CadastroAparelho";
import NotFound from "../views/NotFound";
import Unauthorized from "../views/Unauthorized";



import ListAdmIntermediario from "../views/AdmIntermediario/ListAdmIntermediario";
import CadastroAdmIntermediario from "../views/AdmIntermediario/CadastroAdmIntermediario";
import ListFormsRespondidos from "../views/FormsRespondidos/ListFormsRespondidos";

import ListRelatorio from "../views/Relatorio/ListRelatorio";
import ListCliente from "../views/Cliente/ListCliente";
import CadastroCliente from "../views/Cliente/CadastroCliente";
import ListGuia from "../views/Guia/ListGuia";
import CadastroGuia from "../views/Guia/CadastroGuia";
import ListAdesivo from "../views/Adesivo/ListAdesivo";
import CadastroAdesivo from "../views/Adesivo/CadastroAdesivo";
import ListProduto from "../views/Produto/ListProduto";
import CadastroProduto from "../views/Produto/CadastroProduto";
import ListSupervisor from "../views/Supervisor/ListSupervisor";
import CadastroSupervisor from "../views/Supervisor/CadastroSupervisor";
import ListLinha from "../views/Linha/ListLinha";
import CadastroLinha from "../views/Linha/CadastroLinha";


import ListMudanca from "../views/Mudanca/ListMudanca";
import CadastroMudanca from "../views/Mudanca/CadastroMudanca";
export default function OtherRoutes() {
  const { user } = useAuth();
  console.log(user, "aa")

  return (
    <>
      <Router history={history}>
      <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} /> 
      
      <Route exact path="/list_aparelho" component={ListAparelho} />
      <Route exact path="/cadastro_aparelho" component={CadastroLogin} />

      
      <Route exact path="/list_relatorio" component={user.nivel_acesso === '999' ? ListRelatorio : Unauthorized} />
      <Route exact path="/list_adm_intermediario" component={user.nivel_acesso === '999' ? ListAdmIntermediario : Unauthorized} />
      <Route exact path="/cadastro_adm_intermediario" component={user.nivel_acesso === '999' ? CadastroAdmIntermediario : Unauthorized} />
      <Route exact path="/list_forms_respondidos" component={user.nivel_acesso === '999' ? ListFormsRespondidos : Unauthorized} />
      

      <Route exact path="/list_cliente" component={ListCliente} />
      <Route exact path="/cadastro_cliente" component={CadastroCliente} />
      <Route exact path="/list_guia" component={ListGuia} />
      <Route exact path="/cadastro_guia" component={CadastroGuia} />
      <Route exact path="/list_adesivo" component={ListAdesivo} /> 
      <Route exact path="/cadastro_adesivo" component={CadastroAdesivo} />
      <Route exact path="/list_produto" component={ListProduto} /> 
      <Route exact path="/cadastro_produto" component={CadastroProduto} />
      <Route exact path="/list_supervisor" component={ListSupervisor} /> 
      <Route exact path="/cadastro_supervisor" component={CadastroSupervisor} />
      <Route exact path="/list_linha" component={ListLinha} /> 
      <Route exact path="/cadastro_linha" component={CadastroLinha} />

      
      <Route exact path="/list_mudanca" component={user.nivel_acesso === '999' ? ListMudanca : Unauthorized} />
      <Route exact path="/cadastro_mudanca" component={user.nivel_acesso === '999' ? CadastroMudanca : Unauthorized} />
      <Route component={NotFound} /> 
      <Route component={Unauthorized} />
      </Switch>
      </Router>
    </>
  );
}