import React, { useEffect, useState, useContext } from "react";
import { IoAddCircle } from "react-icons/io5";
import { ReactSVG } from "react-svg";
import Cookies from "js-cookie";


import ModalCausa from "./ModalCausa";

import {
  Container,
  LatealMenu,
  Logo,
  Title,
  InfoContainers,
  ButtonSave,
  ComponentEdit,
  InfoContainer,
  LabelButton2,
  ButtonIcon,
  LabelButton,
  ComponentTable,
  CardInfos,
  CardInfo,
  Cardlabel,
  CardlabelTitle,
  TitleContainer,
  Button,
  ButtonTitle,
  ContainerInput,
  CardInput,
  TitleInput,
  Select,
  Input,
} from "./styles";
import MaterialTable from "material-table";
import { FaTrashAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import Modal from 'react-modal';
import { useAuth } from "../../../hooks/useAuth";


function ListGuia() {
  const customStyles = {
    content: {
      width: "70%",
      fontFamily: "MiloWebPro, Arial, sans-serif",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      height: "80%",
      zIndex: 999,
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      overflow: "scroll",
    },
  };

  let history = useHistory();

  const {user, setUser} = useAuth();
  const [problema, setProblemas] = useState([]);
  const [palavras_array, setPalavrasArray] = useState([{ palavra: "" }]);
  const [palavras, setPalavras] = useState([]);
  const [titulo, setTitulo] = useState();
  const [id_categoria, setIdCategoria] = useState();
  const [admin_id, setAdminId] = useState(user?.id);

  const [palavra_chave, setPalavraChave] = useState();
  const [formulario_aceito, setFormularioAceito] = useState();
  const [descricao, setDescricao] = useState();
  const [topico, setTopico] = useState();
  const [email_id, setEmailId] = useState();
  const [problema_id, setProblemaId] = useState();
  const [formularios, setFormularios] = useState();
  const [currentCausa, setCurrentCausa] = React.useState();
  const [currentProblema, setCurrentProblema] = React.useState({
    id: 0,
    descricao_problema: "",
    img_file: "",
    form_problemas: 0,
    id_categoria: 0,
    titulo: "",
    causas: 
      [
        {
        id_problema: 0,
        id: 0,
        descricao_causa: "",
        id_problemas: 0,
        solucoes: [
          {
            id: 0,
            descricao_acao: "",
            id_causas: 0,
            id_causa: 0
          }
        ]
      }
    ]
    });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [status_mudanca, setStatusMudanca] = useState(user?.nivel_acesso !== '999' ? "Pendente" : "Aprovado");
  const [isTrue, setIsTrue] = useState(true);

  

  const [form_problemas, setFormProblemas] = useState();
  const [descricao_problema, setDescricaoProblema] = useState();
  const [img_file, setImage] = useState(false);
  const [currentCausaIdx, setCurrentCausaIdx] = useState();
  const [isOpenModalCausa, setIsOpenCausa] = useState(false);
  

  const [isEditCausa, setIsEditCausa] = useState(false)

  const [loadingPalavra, setLoadingPalavra] = useState(true);
  const [arrayDePalavraChave, setArrayDePalavraChave] = useState([{id: 'nova palavra', descricao_chave: ''}]);
  const [errorPalavraChave, setErrorPalavraChave] = useState(false);

  const [loadingCausasSolucoes, setLoadingCausasSolucoes] = useState(true);
  const [arrayCausasSolucoes, setArrayCausasSolucoes] = useState([ {
    nomeCausa: "",
    solucoes: ["",],
  },
  
]);
 

async function updateGuia() {
  console.log("testando! entrou na função ", currentProblema, arrayDePalavraChave);

  let palavraChaves = arrayDePalavraChave.map((palavra)=> {return {id: palavra.id, nome: palavra.descricao_chave}})

  let causas = currentProblema.causas.map((causa)=>{
    let solucoes =  causa.solucoes.map((solucao)=>{return {id: solucao.id, nome: solucao.descricao_acao, id_causa: causa.id}})
    return {
      causa: {
        id: causa.id,
        nome: causa.descricao_causa,
      },
      solucoes
    }
  })
  
  if (true) {
    try {
      console.log("testando! entrou na try ");

      const formData = new FormData();
      formData.append("img_file", img_file);
      formData.append("id", currentProblema.id);
      formData.append("admin_id", admin_id);

      formData.append("id_categoria", id_categoria);
      formData.append("titulo", currentProblema.titulo);
      formData.append("descricao_problema", currentProblema.descricao_problema);
      formData.append("form_problemas",  currentProblema.form_problemas);
      formData.append("topico",  currentProblema.topico);
      formData.append("palavras_chave", JSON.stringify(palavraChaves));
      formData.append("status_mudanca", status_mudanca);
      formData.append(
        "causas_solucoes",
        JSON.stringify(causas)
      );
        console.log("testando!", formData )
      const response = await api.put(`/update_problema/${currentProblema.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if(response.status === 200){
        closeModal();
        window.location.reload();
      }
    } catch (error) {
      console.log("testando!",error);

      console.log(error);
    }
  }
}
  
 
  async function getProblemas() {
    const response = await api.get("/problemas");
    setProblemas(response.data.problema);
    console.log(response,  'vendos get problemas ');
  }

  const handleLogout = () => {
    try{
      setUser(null);
      Cookies.remove("authToken");
      Cookies.remove("authUser");
      history.push("/login");
      window.location.reload();
    }catch(error){
      console.log(error);
      console.log("Não foi possível deslogar");
    }
  }

  async function getProblema(id) {
    setLoadingPalavra(false);
    console.log( 'vendos get problemas ssss ');

    const response = await api.get(`/problemas/${id}`);
    setCurrentProblema(response.data.problema);
    console.log(response.data.problema,  'vendos get problemas ssss ');

    // setArrayCausasSolucoes(response.data.problema.causas)
    setArrayDePalavraChave(response.data.problema.palavrasChaves.length >= 1 ? response.data.problema.palavrasChaves  : [{id: 'nova palavra', descricao_chave: ''}])
    openModal()
  }

  async function verifyCode(palavra_chave) {
    let result;

    try {
      result = await api.get(
        `palavraChaves-validate?palavra_chave=${palavra_chave}`
      );
      console.log(result);
    } finally {
      if (result?.status === 200) {
        setErrorPalavraChave(true);
        console.log("Código já cadastrado !");
      } else if (result?.status === 500) {
        console.log("Erro ao validar código.");
      } else {
        setErrorPalavraChave(false);
      }
    }
  }

  const handleAddPalavraChave = (idx) => {
    setLoadingPalavra(false);
    // alterar o map e o array
    let temp = currentProblema.palavrasChaves;
    temp.push({id: 'nova palavra',descricao_chave: ''});
    setArrayDePalavraChave(temp);
    
    currentProblema.palavrasChaves = temp;
    console.log(arrayDePalavraChave, "vendo o valor de temp");
  };


  //Adiciona nova Causa para o problema
  const handleAddCausasSolucoes = (novaCausa) => {
    setLoadingCausasSolucoes(false);

    currentProblema.causas.push(novaCausa);

    setLoadingCausasSolucoes(true);

  };

  const handleEditCausasSolucoes = (obj, index) => {
    setLoadingPalavra(false);
    setCurrentProblema(obj);
  }

  const onChangeProblema = (causa, novasSolucoes =[], index, teste)=>{
    setLoadingPalavra(false);
    let solucoes = [];

    //Atualiza soluções que já existe
    novasSolucoes.forEach((nSolucao)=>{
      currentProblema.causas[index].solucoes.forEach((vSolucao)=>{
        if(nSolucao.id === vSolucao.id && nSolucao.id !== 'nova solucao'){
          solucoes.push({id: nSolucao.id, descricao_acao: nSolucao.descricao_acao});
        }
      })
    })

    //Adiciona soluções
    const arrayDeNovasSolucoes = novasSolucoes.filter((s)=> s.id === 'nova solucao');
    arrayDeNovasSolucoes.forEach(((s)=>{
      solucoes.push({id: s.id, descricao_acao: s.descricao_acao})
    }))

    currentProblema.causas[index].descricao_causa = causa;
    currentProblema.causas[index].solucoes = solucoes;
    setCurrentProblema(currentProblema);
  }

  const handleRemoveCausa = (causa, idx)=>{
    setLoadingPalavra(false);
   if(currentProblema.causas.length > 1){
    let newCausasSolucoes = currentProblema.causas.filter((causa, index)=> index !== idx  );

    currentProblema.causas = newCausasSolucoes;
    console.log(newCausasSolucoes, idx)
   }
  }

  const handleRemovePalavraChave = (palavra, idx)=>{
    setLoadingPalavra(false);
   if(currentProblema.palavrasChaves.length > 1){
    let newPalavrasChaves = currentProblema.palavrasChaves.filter((causa, index)=> index !== idx  );

    currentProblema.palavrasChaves = newPalavrasChaves;
    setArrayDePalavraChave(newPalavrasChaves)
   }
  }


  async function deleted(id_problema) {
    console.log("deleted: " + id_problema);
    try{
      const response = await api.delete(`/delete_problema/${id_problema}`)
      console.log(response.data);
    }
    catch(error){
    }
  }

  useEffect(()=>{
    console.log('ghost ')
  },[currentProblema])


  function openModalCausa() {
    setLoadingPalavra(false);

    setIsOpenCausa(true);
  }
  function closeModalCausa() {
    setIsEditCausa(false);
    setIsOpenCausa(false);
  }
  

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsEditCausa(false);
    setCurrentProblema({})
    setIsOpen(false);
  }


  useEffect(() => {
      getProblemas();

  }, []);
  if(!loadingPalavra){

    setTimeout(function(){
      setLoadingPalavra(true);
      setCurrentProblema(currentProblema);
  }, 100);
  
  }

  console.log('vendo as alterass', loadingPalavra)

  return ( !isOpenModalCausa ?
    <Container>
      
      <LatealMenu>
        <Logo />
        <InfoContainers>
        <InfoContainer>
          {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_relatorio");
              }}
            >
              <ReactSVG src="/images/Relatorio.svg" />
              Relatórios
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_mudanca");
              }}
            >
              <ReactSVG src="/images/SolicitacaoMudanca.svg" />
              Solicitações de Mudança
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_forms_respondidos");
              }}
            >
              <ReactSVG src="/images/FormRespondido.svg" />
              Formulários Respondidos
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton 
            style={{gap: "4.5%"}}
              onClick={() => {
                history.push("/list_adm_intermediario");
              }}
            >
              <ReactSVG src="/images/AdmInter.svg" />
              Cadastro de ADM Intermediário
            </LabelButton>): (<></>)}

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_cliente");
              }}
            >
              <ReactSVG src="/images/CadastroCliente.svg" />
              Cadastro de Clientes
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_aparelho");
              }}
            >
              <ReactSVG src="/images/CadastroAparelho.svg" />
              Cadastro de Aparelhos
            </LabelButton>

            <LabelButton
              style={{ gap: "4.5%", fontWeight: "bold" }}
              onClick={() => {
                history.push("/list_guia");
              }}
            >
              <ReactSVG src="/images/CadastroGuia.svg" />
              Cadastro de Guias
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_adesivo");
              }}
            >
              <ReactSVG src="/images/CadastroAdesivo.svg" />
              Cadastro de Adesivos
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_produto");
              }}
            >
              <ReactSVG src="/images/CadastroProduto.svg" />
              Cadastro de Produtos
            </LabelButton>
            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_supervisor");
              }}
            >
              <ReactSVG src="/images/CadastroSupervisor.svg" />
              Cadastro de Supervisores
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_linha");
              }}
            >
              <ReactSVG src="/images/CadastroLinha.svg" />
              Cadastro de Linha
            </LabelButton>

            <LabelButton onClick={()=> {
                handleLogout()
              }}>
                <ReactSVG src="/images/logOut.svg" />
              Log Out
            </LabelButton>
            
          </InfoContainer>
        </InfoContainers>
      </LatealMenu>
      <ComponentTable>
        <TitleContainer>
          <Title>Dashboard Henkel</Title>
          <Title>Seja muito bem-vindo</Title>
        </TitleContainer>
        <CardInfos>
          <CardInfo>
            <CardlabelTitle>Total de Guias</CardlabelTitle>
            <Cardlabel>350</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Guias Labeling</CardlabelTitle>
            <Cardlabel>50</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Guias EOL</CardlabelTitle>
            <Cardlabel>20</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Guias Higiênicos</CardlabelTitle>
            <Cardlabel>10</Cardlabel>
          </CardInfo>
        </CardInfos>

        <MaterialTable
          style={{
            marginTop: 20,
            width: "100%",
            maxHeight: '100%', 
            overflow: 'auto',
          }}
          localization={{
            header: {
              actions: "",
            },
          }}
          //Botão grande de edição - botão da listagem
          actions={[
            {
              icon: () => <FiEdit color="#E1000F" />,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                getProblema(rowData.id)
                
                },
            },
            {
              icon: () => (
                <FaTrashAlt
                  color="#E1000F"
                  style={{ position: "absolute", left: "10px" }}
                />
              ),
              tooltip: "Delete",
              onClick: (event, rowData) => {
                deleted(rowData.id);
                window.location.reload();
              }
            },
            {
              icon: () => (
                <Button onClick={() => history.push("/cadastro_guia")}>
                  <ButtonTitle>Cadastrar novo</ButtonTitle>
                </Button>
              ),
              isFreeAction: true,
            },
            
          ]}
          // icons={{
          //   Search:() => <BsSearch color="#E1000F" />
          // }}
          columns={[
            { title: "Título", field: "titulo" },
            { title: "Descrição", field: "descricao_problema" },
            { title: "Formulário problemas", field: "form_problemas" },
          
            { title: "Imagens", field: "img_file" },
            { title: "Status de Mudança", field: "status_mudanca" },
          ]}
          data={problema ? problema : [{titulo: '', descricao_problema: '',
        form_problemas: '', img_file: ''}]}
          title="Cadastro de Guias - Labeling"
          options={{
            headerStyle: {
              zIndex: modalIsOpen && -1,
            },
          }}
        />
      </ComponentTable>
      

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={{customStyles}} 
      >

       <Title>Edição de Guias</Title>
       <ContainerInput>
       <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "row"}}>
              <CardInput>
                <TitleInput>Título</TitleInput>
                <Input
                  style={{ width: 320 }}
                  defaultValue={currentProblema.titulo}
                  onChange={(e) => {
                    currentProblema.titulo = e.target.value;

                    setTitulo(e.target.value);
                  }}
                />

                <TitleInput>Descrição</TitleInput>
                <Input
                  
                  style={{ width: 320 }}
                  defaultValue={currentProblema.descricao_problema}
                  onChange={(e) => {
                    currentProblema.descricao_problema = e.target.value;
                     setDescricaoProblema(e.target.value)}}
                />

                <TitleInput>Tópico</TitleInput>
                <form>
                  <Select
                  defaultValue={currentProblema.id_categoria}
                  onChange={(e) =>{
                    currentProblema.id_categoria = e.target.value;
                    setIdCategoria(e.target.value)}}>
                    <option defaultValue={"1"}>Rótulo</option>
                    <option defaultValue={"2"}>Máquina</option>
                    <option value={"3"}>Adesivo</option>
                    <option value={"4"}>Abas aberta</option>
                  </Select>
                </form>

                <div
                  style={{ display: "flex", gap: "17%" }}
                >
                  <TitleInput>Adicionar Causa e Solução</TitleInput>
                  <ButtonIcon
                    onClick={() => {
                      console.log('tenste')
                      openModalCausa();
                    }}
                    style={{
                      width: 30,
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 30,
                      borderCollor: "white",
                    }}
                  >
                    <IoAddCircle size={30} color="red" />
                  </ButtonIcon>
                  
                </div>
                <div>
                  {loadingPalavra && currentProblema.causas?.map((causa,index)=>(
                   <div style={{
                    display: 'flex', 
                    justifyContent: "space-around",
                    alignItens: "center", 
                    marginLeft: '0%', 
                    fontFamily: '"MiloWebPro",Arial,sans-serif',}}>
                    {console.log(causa, 'vendo causa')}
                    <p
                  
                    style={{  padding: '10px', marginLeft: '0%'}}
                    >{index+1} - {'causa'}</p> 
                    {/**botão pequeno de edicao da causa */}
                    <FiEdit  onClick={()=>{
                      setIsEditCausa(true);
                      setCurrentCausaIdx(index);
                      setCurrentCausa(causa);
                      openModalCausa()
                    }}
                    style={{  padding: '10px', margin: '0'}}
                    color="#E1000F" />
                    <FaTrashAlt 

                      onClick={()=>{
                      handleRemoveCausa(causa,index)
                      }}
                     style={{  padding: '10px',margin: '0'}}
                    color="#E1000F"  />
                   </div>
                  ))}
                </div>

                
              </CardInput>

              <CardInput>
                <TitleInput>Imagens</TitleInput>
                <input
                style={{padding:'10px', marginLeft:'5%'}}
                  type="file"
                  
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                  accept=".jpg, .jpeg, .png"
                />
                

                <TitleInput style={{marginTop:'8%'}}>Formulário Aceito</TitleInput>
                <form>
                  <Select 
                  defaultValue={currentProblema.form_problemas}
                  onChange={(e) =>{
                    currentProblema.form_problemas = e.target.value;
                    setFormProblemas(e.target.value)}}>
                    <option value={"0"}>Labeling</option>
                    <option value={"1"}>EOL</option>
                    <option value={"2"}>Higiênicos</option>
                  </Select>
                </form>

                {loadingPalavra &&
                  (() => {
                    return currentProblema.palavrasChaves && currentProblema.palavrasChaves.map((palavra, idx) => (
                      <>
                        <TitleInput key={idx}>{idx+1} - Palavra Chave</TitleInput>
                        <div
                          key={idx}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItens: "center",
                          }}
                        >
                          <div>
                            <Input
                            defaultValue={palavra.descricao_chave}
                              style={{ width: 320, height: 45 }}
                              
                              onChange={async (e) => {
                               
                                arrayDePalavraChave[idx].descricao_chave = e.target.value;
                                setArrayDePalavraChave(arrayDePalavraChave)
                                verifyCode(e.target.value);
                              }}
                            />
                            {errorPalavraChave && (
                              <p style={{ color: "red" }}>
                                Palavra já cadastrada
                              </p>
                            )}
                          </div>

                          <ButtonIcon
                            onClick={() => {
                              handleAddPalavraChave(idx);
                            }}
                            style={{
                              width: 30,
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: 30,
                              borderCollor: "white",
                            }}
                          >
                            <IoAddCircle size={30} color="red" />
                          </ButtonIcon>
                          <ButtonIcon
                            onClick={() => {
                              handleRemovePalavraChave(palavra, idx);
                            }}
                            style={{
                              width: 30,
                              border: "none",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: 30,
                              borderCollor: "white",
                            }}
                          >
                            <FaTrashAlt size={25} color="red" />
                          </ButtonIcon>
                        </div>
                      </>
                    ));
                  })()}
              </CardInput>
              </div>

              <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10%",
                  }}
                >
                  

                  <Button
                    style={{ borderColor: "red" }}
                    onClick={() =>     closeModal()
                  }
                  >
                    <LabelButton2 style={{ color: "red", textAlign: "center" }}>
                      Cancelar
                    </LabelButton2>
                  </Button>
                  <Button
                    onClick={() =>{ 
                    updateGuia()
                    closeModal()
                    }}
                    style={{ backgroundColor: "red"}}
                  >
                    <LabelButton2 style={{ color: "white", textAlign: "center" }}>
                      Salvar
                    </LabelButton2>
                  </Button>
                </div>

              </div>
            </ContainerInput>
           
      </Modal>
      
    </Container> :  <ModalCausa
              currentCausaIdx={currentCausaIdx}
              isOpenCausa={isOpenModalCausa}
              contentLabel="Example Modal"
              styled={customStyles}
              closeModal={closeModalCausa}
              handleAdd={handleAddCausasSolucoes}
              handleEdit={handleEditCausasSolucoes}
              loadingCausasSolucoes={loadingCausasSolucoes}
              currentCausa={currentCausa}

              currentProblema={currentProblema}
              onChangeProblema={onChangeProblema}
              isEditCausa={isEditCausa}
              array={arrayCausasSolucoes}
              changeArray={setArrayCausasSolucoes}/>
  );
}
export default ListGuia;
