import React, { useEffect, useState } from 'react'
import { IoAddCircle } from 'react-icons/io5'
import { IoRemoveCircle } from 'react-icons/io5'
import { ReactSVG } from 'react-svg'
import Cookies from 'js-cookie'

import {
  Container,
  LatealMenu,
  Logo,
  Title,
  InfoContainers,
  ButtonSave,
  ComponentEdit,
  InfoContainer,
  ButtonIcon,
  LabelButton,
  ComponentTable,
  CardInfos,
  CardInfo,
  Cardlabel,
  CardlabelTitle,
  TitleContainer,
  Button,
  ButtonTitle,
  ContainerInput,
  CardInput,
  TitleInput,
  Select,
  Input,
} from './styles'
import MaterialTable from 'material-table'
import { FaTrashAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import api from '../../../services/api'
import Modal from 'react-modal'
import { useAuth } from '../../../hooks/useAuth'

function ListAparelhos() {
  const customStyles = {
    content: {
      width: '70%',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
      height: '80%',
      zIndex: 999,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      overflow: 'scroll',
    },
  }
  let history = useHistory()
  const { user, setUser } = useAuth()
  const [aparelhos, setAparelhos] = useState([])
  const [clientes, setClientes] = useState([])
  const [plantas, setPlantas] = useState([])
  const [plantasNome, setPlantasNome] = useState([])
  const [emails_array, setEmailsArray] = useState([{ email: '' }])
  const [cliente_id, setClienteId] = useState()
  const [nome, setClienteNome] = useState()
  const [vendedor, setVendedor] = useState()
  const [senha, setSenha] = useState()
  const admin_id = user?.id
  const [emails, setEmails] = useState([])
  const [id_aparelho, setIdAparelho] = useState()

  const [aparelho_id, setAparelhoId] = useState()
  const [local_planta_cliente, setLocalPlantaCliente] = useState()
  const [formularios, setFormularios] = useState()
  const [aparelho_emails, setAparelhoEmails] = useState()
  const [tcs_responsavel, setTcsResponsavel] = useState()
  const status_mudanca = user?.nivel_acesso !== '999' ? 'Pendente' : 'Aprovado'
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [currentAparelho, setCurrentAparelho] = React.useState({})

  async function putAparelhos(idd_aparelho) {
    try {
      let emailsAux = []
      for (let i = 0; i < emails_array?.length; i++) {
        emailsAux.push({
          nome: emails_array[i].email,
          id: emails_array[i].email_id,
        })
      }

      let data
      if (emails.length === 0) {
        data = {
          cliente_id,
          nome,
          vendedor,
          admin_id,
          email_id: emailsAux,
          plantas,
          senha,
          local_planta_cliente,
          formularios,
          aparelho_emails,
          id_aparelho,
          tcs_responsavel,
          status_mudanca,
        }
      } else {
        data = {
          cliente_id,
          nome,
          emails,
          vendedor,
          email_id: emailsAux,
          plantas,
          admin_id,
          senha,
          local_planta_cliente,
          formularios,
          aparelho_emails,
          id_aparelho,
          tcs_responsavel,
          status_mudanca,
        }
      }

      await api.put(`/update_aparelho/${idd_aparelho}`, data)

      closeModal()
      window.location.reload()
      history.push('/list_aparelho')
    } catch (error) {
      console.log(error)
    }
  }

  const handleLogout = () => {
    try {
      setUser(null)
      Cookies.remove('authToken')
      Cookies.remove('authUser')
      history.push('/login')
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }

  async function getAparelhos() {
    const response = await api.get('/aparelho')
    setAparelhos(response?.data?.aparelhos)
    setAparelhoId(response.data.aparelhos?.id)
  }

  async function getEmail() {
    try {
      const response = await api.post('/get_aparelho_email', {
        idd_aparelho: aparelho_id,
      })
      setEmails(response.data.local_email[0].nome_email)
    } catch (error) {
      console.log(error)
    }
  }

  async function getEmailsByAparelho() {
    try {
      const response = await api.get(
        `/getAparelhos/${currentAparelho.id}/emails`
      )
      setEmailsArray(response?.data?.email)
    } catch (error) {
      console.log(error)
    }
  }

  async function getPlanta() {
    try {
      const response = await api.post('/get_cliente_planta', {
        id_cliente: cliente_id,
      })
      setPlantas(response?.data?.local_planta[0]?.nome_planta)
    } catch (error) {
      console.log(error)
    }
  }

  async function getClientes() {
    const response = await api.get('/cliente')
    setClienteNome(response?.data?.clientes.nome)
    setClientes(response?.data?.clientes)
    setClienteId(response?.data?.clientes?.id)
  }

  const handleRemoveEmail = (email, idx) => {
    if (emails_array.length > 1) {
      let newEmails = emails_array.filter((s, index) => index !== idx)

      setEmailsArray(newEmails)
    }
  }

  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }
  async function deleted(idd_aparelho) {
    try {
      await api.delete(`/delete_aparelho/${idd_aparelho}`)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAparelhos()
  }, [])

  useEffect(() => {
    if (currentAparelho && clientes) {
      getEmailsByAparelho()
      const aparelhoCliente = clientes.filter(
        (c) => c.nome === currentAparelho.cliente_id
      )[0]
      if (aparelhoCliente) {
        const idClienteAparelho = aparelhoCliente.id
        setClienteId(idClienteAparelho)
        const plantasCliente = aparelhoCliente.planta.map((p) => p.nome[0].nome)
        setPlantasNome(plantasCliente)
      }
      getPlanta()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAparelho])

  useEffect(() => {
    if (aparelho_id) {
      getEmail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aparelho_id])

  useEffect(() => {
    getClientes()
  }, [])

  return (
    <Container>
      <LatealMenu>
        <Logo />
        <InfoContainers>
          <InfoContainer>
            {user?.nivel_acesso === '999' ? (
              <LabelButton
                style={{ gap: '5%' }}
                onClick={() => {
                  history.push('/list_relatorio')
                }}
              >
                <ReactSVG src="/images/Relatorio.svg" />
                Relatórios
              </LabelButton>
            ) : (
              <></>
            )}

            {user?.nivel_acesso === '999' ? (
              <LabelButton
                style={{ gap: '4.5%' }}
                onClick={() => {
                  history.push('/list_mudanca')
                }}
              >
                <ReactSVG src="/images/SolicitacaoMudanca.svg" />
                Solicitações de Mudança
              </LabelButton>
            ) : (
              <></>
            )}

            {user?.nivel_acesso === '999' ? (
              <LabelButton
                style={{ gap: '5%' }}
                onClick={() => {
                  history.push('/list_forms_respondidos')
                }}
              >
                <ReactSVG src="/images/FormRespondido.svg" />
                Formulários Respondidos
              </LabelButton>
            ) : (
              <></>
            )}

            {user?.nivel_acesso === '999' ? (
              <LabelButton
                style={{ gap: '4.5%' }}
                onClick={() => {
                  history.push('/list_adm_intermediario')
                }}
              >
                <ReactSVG src="/images/AdmInter.svg" />
                Cadastro de ADM Intermediário
              </LabelButton>
            ) : (
              <></>
            )}

            <LabelButton
              style={{ gap: '5%' }}
              onClick={() => {
                history.push('/list_cliente')
              }}
            >
              <ReactSVG src="/images/CadastroCliente.svg" />
              Cadastro de Clientes
            </LabelButton>

            <LabelButton
              style={{ gap: '5%', fontWeight: 'bold' }}
              onClick={() => {
                history.push('/list_aparelho')
              }}
            >
              <ReactSVG src="/images/CadastroAparelho.svg" />
              Cadastro de Aparelhos
            </LabelButton>

            <LabelButton
              style={{ gap: '4.5%' }}
              onClick={() => {
                history.push('/list_guia')
              }}
            >
              <ReactSVG src="/images/CadastroGuia.svg" />
              Cadastro de Guias
            </LabelButton>

            <LabelButton
              style={{ gap: '5%' }}
              onClick={() => {
                history.push('/list_adesivo')
              }}
            >
              <ReactSVG src="/images/CadastroAdesivo.svg" />
              Cadastro de Adesivos
            </LabelButton>

            <LabelButton
              style={{ gap: '4%' }}
              onClick={() => {
                history.push('/list_produto')
              }}
            >
              <ReactSVG src="/images/CadastroProduto.svg" />
              Cadastro de Produtos
            </LabelButton>
            <LabelButton
              style={{ gap: '4%' }}
              onClick={() => {
                history.push('/list_supervisor')
              }}
            >
              <ReactSVG src="/images/CadastroSupervisor.svg" />
              Cadastro de Supervisores
            </LabelButton>

            <LabelButton
              style={{ gap: '4%' }}
              onClick={() => {
                history.push('/list_linha')
              }}
            >
              <ReactSVG src="/images/CadastroLinha.svg" />
              Cadastro de Linha
            </LabelButton>

            <LabelButton
              onClick={() => {
                handleLogout()
              }}
            >
              <ReactSVG src="/images/logOut.svg" />
              Log Out
            </LabelButton>
          </InfoContainer>
        </InfoContainers>
      </LatealMenu>

      <ComponentTable>
        <TitleContainer>
          <Title>Dashboard Henkel</Title>
          <Title>Seja muito bem-vindo</Title>
        </TitleContainer>
        <CardInfos>
          <CardInfo>
            <CardlabelTitle>Total de aparelhos</CardlabelTitle>
            <Cardlabel>350</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Total de x</CardlabelTitle>
            <Cardlabel>50</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Info Y</CardlabelTitle>
            <Cardlabel>20</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Info Z</CardlabelTitle>
            <Cardlabel>10</Cardlabel>
          </CardInfo>
        </CardInfos>

        <MaterialTable
          style={{
            marginTop: 20,
            width: '100%',

            maxHeight: '100%',
            overflow: 'auto',
          }}
          localization={{
            header: {
              actions: '',
            },
          }}
          actions={[
            {
              icon: () => <FiEdit color="#E1000F" />,
              tooltip: 'Update',
              onClick: (event, rowData) => {
                setAparelhoId(rowData.id)
                setCurrentAparelho(rowData)
                getEmailsByAparelho()
                setEmailsArray(rowData.email)
                getPlanta(rowData.planta)
                openModal()
              },
            },
            {
              icon: () => (
                <FaTrashAlt
                  color="#E1000F"
                  style={{ position: 'absolute', left: '10px' }}
                />
              ),
              tooltip: 'Delete',
              onClick: (event, rowData) => {
                deleted(rowData?.id)
                window.location.reload()
              },
            },
            {
              icon: () => (
                <Button onClick={() => history.push('/cadastro_aparelho')}>
                  <ButtonTitle>Cadastrar novo</ButtonTitle>
                </Button>
              ),
              isFreeAction: true,
            },
          ]}
          columns={[
            { title: 'Cliente', field: 'cliente_id' },
            { title: 'Local da Planta', field: 'local_planta_cliente' },
            { title: 'Vendedor', field: 'vendedor' },
            { title: 'ID', field: 'id_aparelho' },
            { title: 'TCS Responsável', field: 'tcs_responsavel' },
            { title: 'Formulários', field: 'formularios' },
            { title: 'Emails', field: 'emailTotal' },
            { title: 'Status da mudança', field: 'status_mudanca' },
          ]}
          data={aparelhos}
          options={{
            headerStyle: {
              zIndex: modalIsOpen && -1,
            },
          }}
          title="Cadastro de aparelhos"
        />
      </ComponentTable>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ComponentEdit>
          <Title>Edição de Aparelhos</Title>
          <ContainerInput>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <CardInput>
                  <TitleInput>Cliente</TitleInput>
                  <form>
                    <Select
                      onChange={(v) => {
                        setClienteId(v.target.value)
                        let temp = clientes.find(
                          (c) => c.id === +v.target.value
                        )
                        setPlantas(temp.planta)
                        const p = temp.planta.map((p) => p.nome[0].nome)
                        setPlantasNome(p)
                        setLocalPlantaCliente(p[0])
                      }}
                    >
                      {clientes?.map((item, index) => (
                        <option
                          key={index}
                          value={item?.id}
                          selected={item.nome === currentAparelho.cliente_id}
                        >
                          {item?.nome}
                        </option>
                      ))}
                    </Select>
                  </form>

                  <TitleInput>Vendedor</TitleInput>
                  <Input
                    style={{ width: 320 }}
                    defaultValue={currentAparelho?.vendedor}
                    onChange={(e) => setVendedor(e.target.value)}
                  />
                  <TitleInput>Senha</TitleInput>
                  <Input
                    style={{ width: 320 }}
                    defaultValue={''}
                    onChange={(e) => setSenha(e.target.value)}
                  />

                  <TitleInput>Guias de soluções</TitleInput>
                  <form>
                    <Select
                      defaultValue={currentAparelho?.formularios}
                      onChange={(v) => setFormularios(v.target.value)}
                    >
                      <option value={'EOL'}>EOL</option>
                      <option value={'Labeling'}>Labeling</option>
                      <option value={'Higiênicos'}>Higiênicos</option>
                      <option value={'Labeling/EOL'}>Labeling/EOL</option>
                      <option value={'Higiênicos/EOL'}>Higiênicos/EOL</option>
                    </Select>
                  </form>
                </CardInput>

                <CardInput>
                  <TitleInput>Local da planta do cliente</TitleInput>
                  <form>
                    <Select
                      defaultValue={currentAparelho?.local_planta_cliente}
                      onChange={(v) => {
                        setLocalPlantaCliente(v.target.value)
                      }}
                    >
                      {plantasNome?.map((item, index) => (
                        <>
                          <option value={item} key={index}>
                            {item}
                          </option>
                        </>
                      ))}
                    </Select>
                  </form>
                  <TitleInput>ID aparelho</TitleInput>
                  <Input
                    style={{ width: 320 }}
                    defaultValue={currentAparelho?.id_aparelho}
                    onChange={(e) => setIdAparelho(e.target.value)}
                  />
                  <TitleInput>TCS Responsável</TitleInput>
                  <Input
                    style={{ width: 320 }}
                    defaultValue={currentAparelho?.tcs_responsavel}
                    onChange={(e) => setTcsResponsavel(e.target.value)}
                  />

                  <TitleInput>Emails</TitleInput>
                  {emails_array?.map((item, idx) => (
                    <div key={item.id}>
                      <TitleInput></TitleInput>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItens: 'center',
                          gap: '10%',
                        }}
                      >
                        <Input
                          required
                          type="email"
                          placeholder="E-mail"
                          style={{ width: 320, height: 45 }}
                          defaultValue={item?.nome?.[0]?.nome}
                          onChange={(e) => {
                            item.email = e.target.value
                            setEmailsArray([...emails_array])
                          }}
                        />

                        <ButtonIcon
                          style={{
                            width: 30,
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 30,
                            borderCollor: 'white',
                          }}
                          onClick={() => {
                            handleRemoveEmail(item, idx)
                          }}
                        >
                          <IoRemoveCircle size={30} color="red" />
                        </ButtonIcon>
                      </div>
                    </div>
                  ))}

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      alignItens: 'center',
                      gap: '10%',
                    }}
                  >
                    <ButtonIcon
                      style={{
                        width: 30,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 30,
                        borderCollor: 'white',
                      }}
                      onClick={() =>
                        setEmailsArray([...emails_array, { email: '' }])
                      }
                    >
                      <IoAddCircle size={30} color="red" />
                    </ButtonIcon>
                  </div>
                </CardInput>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '10%',
                }}
              >
                <ButtonSave
                  style={{ borderColor: 'red' }}
                  onClick={() => setIsOpen(false)}
                >
                  <ButtonTitle style={{ color: 'red', textAlign: 'center' }}>
                    Cancelar
                  </ButtonTitle>
                </ButtonSave>
                <ButtonSave
                  onClick={() => putAparelhos(currentAparelho?.id)}
                  style={{ backgroundColor: 'red' }}
                >
                  <ButtonTitle style={{ color: 'white', textAlign: 'center' }}>
                    Salvar
                  </ButtonTitle>
                </ButtonSave>
              </div>
            </div>
          </ContainerInput>
        </ComponentEdit>
      </Modal>
    </Container>
  )
}
export default ListAparelhos
