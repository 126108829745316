import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { ReactSVG } from "react-svg";

import {
  Container,
  LatealMenu,
  Logo,
  Title,
  InfoContainers,
  InfoContainer,
  ButtonSave,
  ComponentEditEol,
  ComponentEditLabeling,
  LabelButton,
  ComponentTable,
  ComponentEdit,
  CardInfos,
  CardInfo,
  Cardlabel,
  CardlabelTitle,
  TitleContainer,
  Button,
  ButtonTitle,
  ContainerInput,
  CardInput,
  TitleInput,
  ButtonIcon,
  Input,
} from "./styles";
import MaterialTable from "material-table";
import { FaTrashAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import Modal from "react-modal";
import { useAuth } from "../../../hooks/useAuth";

function ListRelatorio() {
  const customStyles = {
    content: {
      width: "70%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      height: "80%",
      zIndex: 999,
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      overflow: "scroll",
    },
  };

  let history = useHistory();
  const { user, setUser } = useAuth();
  const [Formulario, setFormulario] = useState([]);
  const [formEols, setFormEols] = useState([]);
  const [formLabelings, setFormLabelings] = useState([]);
  const [num_registro, setNumRegistro] = useState();

  const [clientes, setClientes] = useState([]);
  const [nome, setNome] = useState();
  const [plantas_array, setPlantasArray] = useState([{ planta: "" }]);
  const [id_henkel, setIdHenkel] = useState();
  const [responsavel, setResponsavel] = useState();
  const [plantas, setPlantas] = useState([]);
  const [loadingPlanta, setLoadingPlanta] = useState(true);
  const [planta_id, setPlantaId] = useState();
  const [cliente_id, setClienteId] = useState();
  const [vendedor, setVendedor] = useState();
  const [tcs_responsavel, setTcsResponsavel] = useState();
  const [frase_abertura_app, setFraseAberturaApp] = useState();
  const [status_mudanca, setStatusMudanca] = useState(
    user?.nivel_acesso !== "999" ? "Pendente" : "Aprovado"
  );
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenLabeling, setIsOpenLabeling] = React.useState(false);
  const [currentCliente, setCurrentCliente] = React.useState({});
  const [currentPlantas, setCurrentPlantas] = React.useState([]);
  const [isTrue, setIsTrue] = useState(true);

  async function getFormulario() {
    const response = await api.get("/formulario");

    const FormularioEol = response.data.formEols.map((formEol) => {
      return {
        id: formEol.id,
        categoria: "EOL",
        nome: formEol.users.nome,
        num_registro: formEol.users.num_registro,
        email_user: formEol.email_user,
        nome_supervisor: formEol.nome_supervisor,
        email_supervisor: formEol.email_supervisor,
        linha_de_producao: formEol.linha_de_producao,
        nome_do_produto: formEol.nome_do_produto,
        formato_do_produto: formEol.formato_do_produto,
        temperatura_ambiente: formEol.temperatura_ambiente,
        turno: formEol.turno,
        adesivo: formEol.adesivo,
        lote: formEol.lote,
        nome_maquina: formEol.nome_maquina,
        modelo_maquina: formEol.modelo_maquina,
        velocidade_maquina: formEol.velocidade_maquina,
        tempo_compressao: formEol.tempo_compressao,
        coleiro: formEol.coleiro,
        modelo_coleiro: formEol.modelo_coleiro,
        fornecedor_substrato: formEol.fornecedor_substrato,
        tratamento: formEol.tratamento,
        area_reserva: formEol.area_reserva,
        adesivo_achatado: formEol.adesivo_achatado,
        adesivo_abas: formEol.adesivo_abas,
        posicionado: formEol.posicionado,
        updated_at: formEol.updated_at,
        maquina_id: formEol.maquina_id,
        user_id: formEol.user_id.nome,
      };
    });

    const FormularioLabeling = response.data.formLabelings.map(
      (formLabeling) => {
        return {
          id: formLabeling.id,
          categoria: "Labeling",
          nome: formLabeling.users.nome,
          num_registro: formLabeling.users.num_registro,
          user_id: formLabeling.user_id.nome,
          email_user: formLabeling.email_user,
          nome_supervisor: formLabeling.nome_supervisor,
          email_supervisor: formLabeling.email_supervisor,
          linha_de_producao: formLabeling.linha_de_producao,
          nome_do_produto: formLabeling.nome_do_produto,
          formato_do_produto: formLabeling.formato_do_produto,
          temperatura_ambiente: formLabeling.temperatura_ambiente,
          turno: formLabeling.turno,
          corpo_adesivo: formLabeling.corpo_adesivo,
          corpo_lote: formLabeling.corpo_lote,
          neck_adesivo: formLabeling.neck_adesivo,
          neck_lote: formLabeling.neck_lote,
          contra_adesivo: formLabeling.contra_adesivo,
          contra_lote: formLabeling.contra_lote,
          maquina: formLabeling.maquina,
          modelo: formLabeling.modelo,
          velocidade_maquina: formLabeling.velocidade_maquina,
          corpo_bomba: formLabeling.corpo_bomba,
          corpo_filme: formLabeling.corpo_filme,
          neck_bomba: formLabeling.neck_bomba,
          neck_filme: formLabeling.neck_filme,
          contra_bomba: formLabeling.contra_bomba,
          contra_filme: formLabeling.contra_filme,
          dificuldade_processo: formLabeling.dificuldade_processo,
          verifica_qtde_adesivo: formLabeling.verifica_qtde_adesivo,
          verifica_paletes_alinhados: formLabeling.verifica_paletes_alinhados,
          updated_at: formLabeling.updated_at,
        };
      }
    );

    setFormulario(FormularioEol.concat(FormularioLabeling));
    console.log(FormularioEol.concat(FormularioLabeling));
  }

  const handleLogout = () => {
    try{
      setUser(null);
      Cookies.remove("authToken");
      Cookies.remove("authUser");
      history.push("/login");
      window.location.reload();
    }catch(error){
      console.log(error);
      console.log("Não foi possível deslogar");
    }
  }

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  function openModalLabeling() {
    setIsOpenLabeling(true);
  }
  function closeModalLabeing() {
    setIsOpenLabeling(false);
  }

  async function deleted(id_cliente) {
    console.log("deleted: " + id_cliente);
    try {
      const response = await api.delete(`/delete_cliente/${id_cliente}`);
      console.log(response.data);
    } catch (error) {}
  }

  useEffect(() => {
    getFormulario();
  }, []);

  return (
    <Container>
      <LatealMenu>
        <Logo />
        <InfoContainers>
          <InfoContainer>
            {user?.nivel_acesso === "999" ? (
              <LabelButton
                style={{ gap: "5%", fontWeight: "bold" }}
                onClick={() => {
                  history.push("/list_relatorio");
                }}
              >
                <ReactSVG src="/images/Relatorio.svg" />
                Relatórios
              </LabelButton>
            ) : (
              <></>
            )}

            {user?.nivel_acesso === "999" ? (
              <LabelButton
                style={{ gap: "4.5%" }}
                onClick={() => {
                  history.push("/list_mudanca");
                }}
              >
                <ReactSVG src="/images/SolicitacaoMudanca.svg" />
                Solicitações de Mudança
              </LabelButton>
            ) : (
              <></>
            )}

            {user?.nivel_acesso === "999" ? (
              <LabelButton
                style={{ gap: "5%" }}
                onClick={() => {
                  history.push("/list_forms_respondidos");
                }}
              >
                <ReactSVG src="/images/FormRespondido.svg" />
                Formulários Respondidos
              </LabelButton>
            ) : (
              <></>
            )}

            {user?.nivel_acesso === "999" ? (
              <LabelButton
                style={{ gap: "4.5%" }}
                onClick={() => {
                  history.push("/list_adm_intermediario");
                }}
              >
                <ReactSVG src="/images/AdmInter.svg" />
                Cadastro de ADM Intermediário
              </LabelButton>
            ) : (
              <></>
            )}

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_cliente");
              }}
            >
              <ReactSVG src="/images/CadastroCliente.svg" />
              Cadastro de Clientes
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_aparelho");
              }}
            >
              <ReactSVG src="/images/CadastroAparelho.svg" />
              Cadastro de Aparelhos
            </LabelButton>

            <LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_guia");
              }}
            >
              <ReactSVG src="/images/CadastroGuia.svg" />
              Cadastro de Guias
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_adesivo");
              }}
            >
              <ReactSVG src="/images/CadastroAdesivo.svg" />
              Cadastro de Adesivos
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_produto");
              }}
            >
              <ReactSVG src="/images/CadastroProduto.svg" />
              Cadastro de Produtos
            </LabelButton>
            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_supervisor");
              }}
            >
              <ReactSVG src="/images/CadastroSupervisor.svg" />
              Cadastro de Supervisores
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_linha");
              }}
            >
              <ReactSVG src="/images/CadastroLinha.svg" />
              Cadastro de Linha
            </LabelButton>

            <LabelButton onClick={()=> {
                handleLogout()
              }}>
                <ReactSVG src="/images/logOut.svg" />
              Log Out
            </LabelButton>
            
          </InfoContainer>
        </InfoContainers>
      </LatealMenu>
      <ComponentTable>
        <TitleContainer>
          <Title>Dashboard Henkel</Title>
          <Title>Seja muito bem-vindo</Title>
        </TitleContainer>
        <CardInfos>
          <CardInfo>
            <CardlabelTitle>Total de colaboradores</CardlabelTitle>
            <Cardlabel>350</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Total de x</CardlabelTitle>
            <Cardlabel>50</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Info Y</CardlabelTitle>
            <Cardlabel>20</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Info Y</CardlabelTitle>
            <Cardlabel>10</Cardlabel>
          </CardInfo>
        </CardInfos>

        <MaterialTable
          style={{
            marginTop: 20,
            width: "100%",
            maxHeight: "100%",
            overflow: "auto",
          }}
          localization={{
            header: {
              actions: "",
            },
          }}
          actions={[
            {
              icon: () => <FiEdit color="#E1000F" />,
              tooltip: "Update",
              onClick: (event, rowData) => {
                console.log(rowData.categoria)
                
              },
            },
            {
              icon: () => (
                <FaTrashAlt
                  color="#E1000F"
                  style={{ position: "absolute", left: "10px" }}
                />
              ),
              tooltip: "Delete",
              onClick: (event, rowData) => {
                deleted(rowData.id);
                window.location.reload();
              },
            },
          ]}
          columns={[
            { title: "Aparelho", field: "aparelho" },
            { title: "Cliente", field: "cliente" },
            { title: "Planta", field: "cliente_planta" },
            { title: "Problemas resolvidos", field: "resolvidos" },
            
          ]}
          data={Formulario}
          options={{
            headerStyle: {
              zIndex: 
              modalIsOpen || 
               modalIsOpenLabeling ? -1 : false,

            },
          }}
          {...console.log(Formulario)}
          title="Relatório de acesso"
        />
      </ComponentTable>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={{ customStyles }}
      >
        <ComponentEditEol>
          <Title>Formulário respondido</Title>
          <ContainerInput>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CardInput>
                  <TitleInput>Usuário: {formEols.nome}</TitleInput>
                  <TitleInput>CPF: {formEols.num_registro}</TitleInput>
                  <TitleInput>Linha de produção</TitleInput>
                  <Input disabled defaultValue={formEols.linha_de_producao} />
                  <Input disabled defaultValue={formEols.nome_do_produto} />

                  <TitleInput>Formato do produto:</TitleInput>
                  <TitleInput>{formEols.formato_do_produto}</TitleInput>
                  <Input disabled defaultValue={formEols.temperatura_ambiente} />
                  <Input disabled defaultValue={formEols.turno} />
                  <TitleInput>Adesivo</TitleInput>
                  <Input disabled defaultValue={formEols.adesivo} />
                  <Input disabled defaultValue={formEols.lote} />
                </CardInput>

                <CardInput>
                  <TitleInput>Data e horário: {formEols.updated_at}</TitleInput>
                  <TitleInput>.</TitleInput>
                  <TitleInput>Coleiro:</TitleInput>
                  <Input disabled defaultValue={formEols.coleiro} />
                  <Input disabled defaultValue={formEols.modelo_coleiro} />

                  <TitleInput>Máquina:</TitleInput>
                  <Input disabled defaultValue={formEols.nome_maquina} />
                  <Input disabled defaultValue={formEols.modelo_maquina} />
                  <Input disabled defaultValue={formEols.velocidade_maquina} />
                  <Input disabled defaultValue={formEols.tempo_compressao} />
                </CardInput>

                <CardInput>
                  <TitleInput>{formEols.categoria}</TitleInput>
                  <TitleInput>.</TitleInput>

                  <TitleInput>Área:</TitleInput>
                  <Input disabled defaultValue={formEols.area_reserva} />
                  <Input disabled defaultValue={formEols.adesivo_achatado} />
                  <Input disabled defaultValue={formEols.adesivo_abas} />
                  <Input disabled defaultValue={formEols.posicionado} />

                  <TitleInput>Fornecedor:</TitleInput>
                  <Input disabled defaultValue={formEols.fornecedor_substrato} />
                  <Input disabled defaultValue={formEols.tratamento} />
                </CardInput>
              </div>

              
            </div>
          </ContainerInput>
        </ComponentEditEol>
      </Modal>

      <Modal
        isOpen={modalIsOpenLabeling}
        onRequestClose={closeModalLabeing}
        contentLabel="Example Modal"
        style={{ customStyles }}
      >
        <ComponentEditLabeling>
          <Title>Formulário respondido</Title>
          <ContainerInput>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CardInput>
                  <TitleInput>Usuário: {formLabelings.nome}</TitleInput>
                  <TitleInput>CPF: {formLabelings.num_registro}</TitleInput>
                  <TitleInput>Linha de produção</TitleInput>
                  <Input disabled defaultValue={formLabelings.linha_de_producao} />
                  <Input disabled defaultValue={formLabelings.nome_do_produto} />

                  <TitleInput>Formato da garrafa:</TitleInput>
                  <TitleInput>{formLabelings.formato_do_produto}</TitleInput>
                  <Input disabled defaultValue={formLabelings.temperatura_ambiente} />
                  <Input disabled defaultValue={formLabelings.turno} />
                  <TitleInput>Adesivo</TitleInput>
                  <TitleInput>Corpo:</TitleInput>
                  <Input disabled defaultValue={formLabelings.corpo_adesivo} />
                  <Input disabled defaultValue={formLabelings.corpo_lote} />
                </CardInput>

                <CardInput>
                  <TitleInput>
                    Data e horário: {formLabelings.updated_at}
                  </TitleInput>
                  <TitleInput>.</TitleInput>
                  <TitleInput>Neck/Foil:</TitleInput>
                  <Input disabled defaultValue={formLabelings.neck_adesivo} />
                  <Input disabled defaultValue={formLabelings.neck_lote} />

                  <TitleInput>Contra:</TitleInput>
                  <Input disabled defaultValue={formLabelings.contra_adesivo} />
                  <Input disabled defaultValue={formLabelings.contra_lote} />

                  <TitleInput>Máquina:</TitleInput>
                  <Input disabled defaultValue={formLabelings.maquina} />
                  <Input disabled defaultValue={formLabelings.modelo} />
                  <Input disabled defaultValue={formLabelings.velocidade_maquina} />
                </CardInput>

                <CardInput>
                  <TitleInput>{formLabelings.categoria}</TitleInput>
                  <TitleInput>.</TitleInput>

                  <TitleInput>Corpo:</TitleInput>
                  <Input disabled defaultValue={formLabelings.corpo_bomba} />
                  <Input disabled defaultValue={formLabelings.corpo_filme} />

                  <TitleInput>Neck/Foil:</TitleInput>
                  <Input disabled defaultValue={formLabelings.neck_bomba} />
                  <Input disabled defaultValue={formLabelings.neck_filme} />

                  <TitleInput>Contra:</TitleInput>
                  <Input disabled defaultValue={formLabelings.contra_bomba} />
                  <Input disabled defaultValue={formLabelings.contra_filme} />
                </CardInput>
              </div>

            </div>
          </ContainerInput>
        </ComponentEditLabeling>
      </Modal>
    </Container>
  );
}
export default ListRelatorio;
