import React, { useEffect, useState, useContext } from "react";
import { IoAddCircle } from "react-icons/io5";
import { ReactSVG } from "react-svg";
import { FaTrashAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import Cookies from "js-cookie";
import {
  Container,
  LatealMenu,
  Logo,
  Title,
  InfoContainers,
  InfoContainer,
  LabelButton,
  ComponentTable,
  ContainerCadastro,
  ContainerInput,
  TitleInput,
  Input,
  CardInput,
  Button,
  LabelButton2,
  Select,
  ButtonIcon,
} from "./styles";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import Modal from "react-modal";


import ModalCausa from "./ModalCausa";
import { useAuth } from "../../../hooks/useAuth";

function CadastroGuia() {
  const customStyles = {
    content: {
      width: "70%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      height: "80%",
      zIndex: 999,
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      overflow: "scroll",
    },
  };

  let history = useHistory();
  const {user, setUser} = useAuth();
  const [titulo, setTitulo] = useState();
  const [id_categoria, setIdCategoria] = useState();
  const [palavra_chave, setPalavraChave] = useState([]);
  const [form_problemas, setFormProblemas] = useState();
  const [admin_id, setAdminId] = useState(user?.id);

  const [causa, setCausa] = useState();
  const [descricao_problema, setDescricaoProblema] = useState();
  const [topico, setTopico] = useState();
  const [img_file, setImage] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [tcs_responsavel, setTcsResponsavel] = useState();
  const [frase, setFrase] = useState();
  const [status_mudanca, setStatusMudanca] = useState(user?.nivel_acesso !== '999' ? "Pendente" : "Aprovado");
  const [isTrue, setIsTrue] = useState(true);
  


  const [isEditCausa, setIsEditCausa] = useState(false)

  const [loadingPalavra, setLoadingPalavra] = useState(true);
  const [arrayDePalavraChave, setArrayDePalavraChave] = useState([null]);
  const [errorPalavraChave, setErrorPalavraChave] = useState(false);

  const [loadingCausasSolucoes, setLoadingCausasSolucoes] = useState(true);
  const [arrayCausasSolucoes, setArrayCausasSolucoes] = useState([ {
    nomeCausa: "",
    solucoes: ["",],
  },
  
]);

const [currentCausaIdx, setCurrentCausaIdx] = useState();

  

  async function postGuias() {
    if (!errorPalavraChave) {
      try {
        const formData = new FormData();
        formData.append("img_file", img_file);
        formData.append("titulo", titulo);
        formData.append("admin_id", admin_id);
        formData.append("id_categoria", id_categoria);
        formData.append("descricao_problema", descricao_problema);
        formData.append("form_problemas", form_problemas);
        formData.append("topico", topico);
        formData.append("tcs_responsavel", tcs_responsavel);
        formData.append("frase", frase);
        formData.append("palavra_chave", palavra_chave);
        formData.append("status_mudanca", status_mudanca);
        formData.append(
          "causa_solucao",
          JSON.stringify(arrayCausasSolucoes)
        );
          console.log(arrayCausasSolucoes, "Array causas")
        const response = await api.post("/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("testando!", formData);
         history.push("/list_guia");
      } catch (error) {
        console.log(error);
      }
    }
  }
  async function verifyCode(palavra_chave) {
    let result;

    try {
      result = await api.get(
        `palavraChaves-validate?palavra_chave=${palavra_chave}`
      );
      console.log(result);
    } finally {
      if (result?.status === 200) {
        setErrorPalavraChave(true);
        console.log("Código já cadastrado !");
      } else if (result?.status === 500) {
        console.log("Erro ao validar código.");
      } else {
        setErrorPalavraChave(false);
      }
    }
  }

  const handleLogout = () => {
    try{
      setUser(null);
      Cookies.remove("authToken");
      Cookies.remove("authUser");
      history.push("/login");
      window.location.reload();
    }catch(error){
      console.log(error);
      console.log("Não foi possível deslogar");
    }
  }

  const handleAddPalavraChave = (idx) => {
    setLoadingPalavra(false);
    let temp = arrayDePalavraChave;
    temp.push(idx);
    setArrayDePalavraChave(temp);
    console.log(arrayDePalavraChave, "vendo o valor de temp");
  };

  const handleAddCausasSolucoes = (obj) => {
    setLoadingCausasSolucoes(false);
    let temp = arrayCausasSolucoes;
    arrayCausasSolucoes.push(obj);
    console.log(temp, "vendo o valor de arrayCausasSolucoes");
    setArrayCausasSolucoes(arrayCausasSolucoes);
    setLoadingCausasSolucoes(true);

  };

  const handleEditCausasSolucoes = (obj, index) => {
    setLoadingCausasSolucoes(false);
    let temp = arrayCausasSolucoes;
    temp[index]= obj;
    setArrayCausasSolucoes(temp);
    setLoadingCausasSolucoes(true);                                                                                       

  }

  const handleRemovePalavraChave = (palavra, idx)=>{
    setLoadingPalavra(false);
    console.log(arrayDePalavraChave, "aaaaaa");
    
   if(arrayDePalavraChave.length > 1){
    let newPalavrasChaves = arrayDePalavraChave.filter((causa, index)=> index !== idx  );

    setArrayDePalavraChave(newPalavrasChaves)

   }
  }


  const handleRemoveCausa = (causa, idx)=>{
    setLoadingPalavra(false);
   if(arrayCausasSolucoes.length > 1){
    let newCausasSolucoes = arrayCausasSolucoes.filter((causa, index)=> index !== idx );

    setArrayCausasSolucoes(newCausasSolucoes);
    console.log(newCausasSolucoes, idx)
   }
  }

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsEditCausa(false);
    setIsOpen(false);
  }

                                            
  if(!loadingPalavra){
    setLoadingPalavra(true);
  }
  return (
    <Container>
      <LatealMenu>
        <Logo />
        <InfoContainers>
        <InfoContainer>
          {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_relatorio");
              }}
            >
              <ReactSVG src="/images/Relatorio.svg" />
              Relatórios
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_mudanca");
              }}
            >
              <ReactSVG src="/images/SolicitacaoMudanca.svg" />
              Solicitações de Mudança
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_forms_respondidos");
              }}
            >
              <ReactSVG src="/images/FormRespondido.svg" />
              Formulários Respondidos
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton 
            style={{gap: "4.5%"}}
              onClick={() => {
                history.push("/list_adm_intermediario");
              }}
            >
              <ReactSVG src="/images/AdmInter.svg" />
              Cadastro de ADM Intermediário
            </LabelButton>): (<></>)}

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_cliente");
              }}
            >
              <ReactSVG src="/images/CadastroCliente.svg" />
              Cadastro de Clientes
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_aparelho");
              }}
            >
              <ReactSVG src="/images/CadastroAparelho.svg" />
              Cadastro de Aparelhos
            </LabelButton>

            <LabelButton
              style={{ gap: "4.5%", fontWeight: "bold" }}
              onClick={() => {
                history.push("/list_guia");
              }}
            >
              <ReactSVG src="/images/CadastroGuia.svg" />
              Cadastro de Guias
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_adesivo");
              }}
            >
              <ReactSVG src="/images/CadastroAdesivo.svg" />
              Cadastro de Adesivos
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_produto");
              }}
            >
              <ReactSVG src="/images/CadastroProduto.svg" />
              Cadastro de Produtos
            </LabelButton>
            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_supervisor");
              }}
            >
              <ReactSVG src="/images/CadastroSupervisor.svg" />
              Cadastro de Supervisores
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_linha");
              }}
            >
              <ReactSVG src="/images/CadastroLinha.svg" />
              Cadastro de Linha
            </LabelButton>

            <LabelButton onClick={()=> {
                handleLogout()
              }}>
                <ReactSVG src="/images/logOut.svg" />
              Log Out
            </LabelButton>
            
          </InfoContainer>
        </InfoContainers>
      </LatealMenu>
      <ContainerCadastro>
        
        <ComponentTable>
       <ModalCausa

       currentCausaIdx={currentCausaIdx}
        isOpen={modalIsOpen}
        contentLabel="Example Modal"
        styled={customStyles}

        closeModal={closeModal}
        handleAdd={handleAddCausasSolucoes}
        handleEdit={handleEditCausasSolucoes}
        loadingCausasSolucoes={loadingCausasSolucoes}

        isEditCausa={isEditCausa}
        array={arrayCausasSolucoes}
        changeArray={setArrayCausasSolucoes}
       
       />
          <Title>Cadastro de Guias</Title>
          {
            <ContainerInput>
              <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "row"}}>
              <CardInput>
                <TitleInput>Título</TitleInput>
                <Input
                  style={{ width: 320 }}
                  onChange={(e) => setTitulo(e.target.value)}
                />

                <TitleInput>Descrição</TitleInput>
                <Input
                  style={{ width: 320 }}
                  onChange={(e) => setDescricaoProblema(e.target.value)}
                />

                <TitleInput>Tópico</TitleInput>
                <form>
                  <Select onChange={(v) => setIdCategoria(v.target.value)}>
                  <option defaultValue={""}>Selecione um tópico</option>
                    <option defaultValue={"1"}>Rótulo</option>
                    <option defaultValue={"2"}>Máquina</option>
                    <option value={"3"}>Adesivo</option>
                    <option value={"4"}>Abas aberta</option>
                  </Select>
                </form>

                <div
                  style={{ display: "flex", gap: "17%" }}
                >
                  <TitleInput>Adicionar Causa e Solução</TitleInput>
                  <ButtonIcon
                    onClick={() => {
                      openModal();
                    }}
                    style={{
                      width: 30,
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 30,
                      borderCollor: "white",
                    }}
                  >
                    <IoAddCircle size={30} color="red" />
                  </ButtonIcon>
                  
                </div>
                <div>
                  {arrayCausasSolucoes.map((causa,index)=>(
                   <div style={{display: 'flex', justifyContent: "space-around",
                   alignItens: "center", margin: '10px'}}>
                    <p
                    style={{  padding: '10px',margin: '0'}}
                    >{index+1} - {causa.nomeCausa}</p>
                    
                    <FiEdit  onClick={()=>{
                      console.log(index);
                      setIsEditCausa(true);
                      setCurrentCausaIdx(index);
                      openModal()
                    }}
                    style={{  padding: '10px',margin: '0'}}
                    color="#E1000F" />
                    <FaTrashAlt 

                      onClick={()=>{
                      handleRemoveCausa(causa,index)
                      }}
                     style={{  padding: '10px',margin: '0'}}
                    color="#E1000F"  />
                   </div>
                  ))}
                </div>

                
              </CardInput>

              <CardInput>
                <TitleInput>Imagens</TitleInput>
                <input
                style={{padding:'10px', marginLeft:'5%'}}
                  type="file"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                  accept=".jpg, .jpeg, .png"
                />
                

                <TitleInput style={{marginTop:'8%'}}>Formulário Aceito</TitleInput>
                <form>
                  <Select onChange={(v) => setFormProblemas(v.target.value)}>
                    <option defaultValue={""}>Selecione um formulário</option>
                    <option value={"1"}>Labeling</option>
                    <option value={"0"}>EOL</option>
                    <option value={"2"}>Higiênicos</option>
                  </Select>
                </form>

                {console.log(loadingPalavra, "vendo o valor de loading")}
                {loadingPalavra &&
                  (() => {
                    return arrayDePalavraChave.map((palavra, idx) => (
                      <>
                        <TitleInput key={idx}>Palavra Chave</TitleInput>
                        <div
                          key={idx}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItens: "center",
                          }}
                        >
                          <div>
                            <Input
                              style={{ width: 320, height: 45 }}
                              onChange={async (e) => {
                                let temp = palavra_chave;
                                temp[idx] = e.target.value;
                                setPalavraChave(temp);
                                verifyCode(e.target.value);
                              }}
                            />
                            {errorPalavraChave && (
                              <p style={{ color: "red" }}>
                                Palavra já cadastrada
                              </p>
                            )}
                          </div>

                          

                          <ButtonIcon
                            onClick={() => {
                              handleRemovePalavraChave(palavra, idx);
                            }}
                            style={{
                              width: 30,
                              border: "none",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: 30,
                              borderCollor: "white",
                            }}
                          >
                            <FaTrashAlt size={25} color="red" />
                          </ButtonIcon>
                        </div>
                      </>
                    ));
                  })()}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItens: "center",
                      gap: "10%",
                    }}
                  >
                  <ButtonIcon
                            onClick={() => {
                              handleAddPalavraChave();
                            }}
                            style={{
                              width: 30,
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: 30,
                              borderCollor: "white",
                            }}
                          >
                            <IoAddCircle size={30} color="red" />
                          </ButtonIcon>
                          </div>
              </CardInput>
              </div>

              <div
                  style={{
                    display: "flex",
                    gap: "10%",
                    justifyContent: "center",
                  }}
                >
                   <Button
                    style={{ borderColor: "red" }}
                    onClick={() => history.push("/list_guia")}
                  >
                    <LabelButton2 style={{ color: "red", textAlign: "center" }}>
                      Cancelar
                    </LabelButton2>
                  </Button>
                  <Button
                    onClick={() =>{ 
                    postGuias()
                    closeModal()
                    }}
                    style={{ backgroundColor: "red" }}
                  >
                    <LabelButton2 style={{ color: "white" }}>
                      Cadastrar
                    </LabelButton2>
                  </Button>

                 
                </div>

              </div>
            </ContainerInput>
          }
        </ComponentTable>
      </ContainerCadastro>
    </Container>
  );
}

export default CadastroGuia;
