import React, { useEffect, useState, useContext } from "react";
import { Container, Body, Logo, Title, LabelButton2 } from "./styles";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { useAuth } from "../../hooks/useAuth";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ReCAPTCHA from "react-google-recaptcha";
function Login() {
  const [isOpen, setIsOpen] = useState("");
  const [captcha, setCaptcha] = useState(false);
  const { Login: SignIn, setEmail, setSenha } = useAuth();
  async function Logar() {
    try {
      if (!captcha) {
        window.alert("Erro no Captcha");
      } else {
        await SignIn();
        window.location.reload();
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  return (
    <Container>
      <Body>
        <Logo />
        <Title>Log In Dashboard</Title>
        <Input
          label={"E-mail"}
          type="text"
          name="email"
          placeholder="Insira seu e-mail"
          onChange={({ target }) => setEmail(target.value)}
        ></Input>

        <Input
          label={"Senha"}
          type={!isOpen ? "password" : "text"}
          name="senha"
          placeholder="Insira sua senha"
          onChange={({ target }) => setSenha(target.value)}
        ></Input>

        {!isOpen ? (
          <AiOutlineEyeInvisible
            onClick={() => setIsOpen(!isOpen)}
            style={{
              position: "absolute",
              marginTop: 200,
              marginLeft: 410,
              zIndex: 999,
              color: "#9FA2B4",
            }}
          />
        ) : (
          <AiOutlineEye
            onClick={() => setIsOpen(!isOpen)}
            style={{
              position: "absolute",
              marginTop: 200,
              marginLeft: 410,
              zIndex: 999,
              color: "#9FA2B4",
            }}
          />
        )}
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={setCaptcha}
        />
        <Button onClick={() => Logar()}>
          <LabelButton2>ENTRAR</LabelButton2>
        </Button>
      </Body>
    </Container>
  );
}

export default Login;
