import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { ReactSVG } from "react-svg";

import {
  Container,
  LatealMenu,
  Logo,
  Title,
  InfoContainers,
  ButtonSave,
  ComponentEdit,
  InfoContainer,
  ButtonIcon,
  LabelButton,
  ComponentTable,
  CardInfos,
  CardInfo,
  Cardlabel,
  CardlabelTitle,
  TitleContainer,
  Button,
  ButtonTitle,
  ContainerInput,
  CardInput,
  TitleInput,
  Select,
  Input,
} from "./styles";
import MaterialTable from "material-table";
import { FaTrashAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { BsSearch } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import Modal from "react-modal";
import { useAuth } from "../../../hooks/useAuth";

function ListSupervisor() {
  const customStyles = {
    content: {
      width: "70%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      height: "80%",
      zIndex: 999,
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
    },
  };

  let history = useHistory();
  const { user, setUser } = useAuth();
  const [supervisors, setSupervisors] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [cliente_nome, setClienteNome] = useState();
  const [supervisor, setSupervisor] = useState();
  const [login, setLogin] = useState();
  const [senha, setSenha] = useState();
  const [nome_cliente, setNomeCliente] = useState(``);
  const [cliente, setCliente] = useState();
  const [aparelho, setAparelho] = useState();
  const [cliente_id, setClienteId] = useState();
  const [planta_id, setPlantaId] = useState();
  const [admin_id, setAdminId] = useState(user?.id);
  const [local_planta_cliente, setLocalPlantaCliente] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [currentSupervisor, setCurrentSupervisor] = React.useState({});
  const [loading, setLoading] = useState(true);
  const [status_mudanca, setStatusMudanca] = useState(user?.nivel_acesso !== '999' ? "Pendente" : "Aprovado");
  const [isTrue, setIsTrue] = useState(true);
  

  async function putSupervisors(id_supervisor) {
    try {
      let data;
      data = {
        supervisor,
        login,
        senha,
        cliente_nome,
        admin_id,
        local_planta_cliente,
        cliente_id,
        status_mudanca,
      };

      const response = await api.put(
        `/update_supervisor/${id_supervisor}`,
        data
      );
      console.log(data);

      console.log("Supervisor atualizada com sucesso!");
      closeModal();
      window.location.reload();
      history.push("/list_supervisor");
    } catch (error) {
      console.log(error);
      console.log("Supervisor não atualizada.");
    }
  }

  const handleLogout = () => {
    try{
      setUser(null);
      Cookies.remove("authToken");
      Cookies.remove("authUser");
      history.push("/login");
      window.location.reload();
    }catch(error){
      console.log(error);
      console.log("Não foi possível deslogar");
    }
  }

  async function getSupervisors() {
    const response = await api.get("/supervisor");
    setSupervisors(response?.data?.supervisor);
    console.log(response.data?.supervisor);
  }

  async function getPlanta() {
    try {
      setLoading(false);
      const response = await api.post("/get_cliente_planta", {
        id_cliente: cliente_id,
      });
      console.log(response.data.local_planta[0]?.nome_planta, "aaaa");
      console.log(
        response.data.local_planta,
        currentSupervisor,
        cliente_id,
        "vendo o objeto"
      );
      setPlantas(response.data.local_planta[0]?.nome_planta);
      setIsTrue(true);
      setLoading(true);
    } catch (error) {
      setIsTrue(false);
      console.log(error);
    }
  }

  async function getClientes() {
    setLoading(false);
    const response = await api.get("/cliente");
    setClientes(response?.data?.clientes);
    setClienteId(response?.data?.clientes?.id);
    setLoading(true);
    console.log(response.data.clientes, "dasghfdasfgh");
  }

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  async function deleted(id_supervisor) {
    console.log("deleted: " + id_supervisor);
    try {
      const response = await api.delete(`/delete_supervisor/${id_supervisor}`);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getSupervisors();
  }, []);

  useEffect(() => {
    if (currentSupervisor) {
      setClienteId(currentSupervisor.cliente_id);
      getPlanta();
    }
  }, [currentSupervisor]);

  useEffect(() => {
    getClientes();
  }, []);

 

  const filteredPlanta = clientes?.filter(
    (cliente) => +cliente?.id === +cliente_id);
  const flatPlanta = filteredPlanta[0]?.planta?.flat()
  const plantasNome = flatPlanta?.map((flatPlanta) => flatPlanta?.nome[0]?.nome);
  console.log({ plantasNome, filteredPlanta, flatPlanta});
  

  return (
    <Container>
      <LatealMenu>
        <Logo />
        <InfoContainers>
          <InfoContainer>
            {user?.nivel_acesso === "999" ? (
              <LabelButton
                style={{ gap: "5%" }}
                onClick={() => {
                  history.push("/list_relatorio");
                }}
              >
                <ReactSVG src="/images/Relatorio.svg" />
                Relatórios
              </LabelButton>
            ) : (
              <></>
            )}

            {user?.nivel_acesso === "999" ? (
              <LabelButton
                style={{ gap: "4.5%" }}
                onClick={() => {
                  history.push("/list_mudanca");
                }}
              >
                <ReactSVG src="/images/SolicitacaoMudanca.svg" />
                Solicitações de Mudança
              </LabelButton>
            ) : (
              <></>
            )}

            {user?.nivel_acesso === "999" ? (
              <LabelButton
                style={{ gap: "5%" }}
                onClick={() => {
                  history.push("/list_forms_respondidos");
                }}
              >
                <ReactSVG src="/images/FormRespondido.svg" />
                Formulários Respondidos
              </LabelButton>
            ) : (
              <></>
            )}

            {user?.nivel_acesso === "999" ? (
              <LabelButton
                style={{ gap: "4.5%" }}
                onClick={() => {
                  history.push("/list_adm_intermediario");
                }}
              >
                <ReactSVG src="/images/AdmInter.svg" />
                Cadastro de ADM Intermediário
              </LabelButton>
            ) : (
              <></>
            )}

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_cliente");
              }}
            >
              <ReactSVG src="/images/CadastroCliente.svg" />
              Cadastro de Clientes
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_aparelho");
              }}
            >
              <ReactSVG src="/images/CadastroAparelho.svg" />
              Cadastro de Aparelhos
            </LabelButton>

            <LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_guia");
              }}
            >
              <ReactSVG src="/images/CadastroGuia.svg" />
              Cadastro de Guias
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_adesivo");
              }}
            >
              <ReactSVG src="/images/CadastroAdesivo.svg" />
              Cadastro de Adesivos
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_produto");
              }}
            >
              <ReactSVG src="/images/CadastroProduto.svg" />
              Cadastro de Produtos
            </LabelButton>
            <LabelButton
              style={{ gap: "4%", fontWeight: "bold" }}
              onClick={() => {
                history.push("/list_supervisor");
              }}
            >
              <ReactSVG src="/images/CadastroSupervisor.svg" />
              Cadastro de Supervisores
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_linha");
              }}
            >
              <ReactSVG src="/images/CadastroLinha.svg" />
              Cadastro de Linha
            </LabelButton>

            <LabelButton onClick={()=> {
                handleLogout()
              }}>
                <ReactSVG src="/images/logOut.svg" />
              Log Out
            </LabelButton>
            
          </InfoContainer>
        </InfoContainers>
      </LatealMenu>
      <ComponentTable>
        <TitleContainer>
          <Title>Dashboard Henkel</Title>
          <Title>Seja muito bem-vindo</Title>
        </TitleContainer>
        <CardInfos>
          <CardInfo>
            <CardlabelTitle>Total de Guias</CardlabelTitle>
            <Cardlabel>350</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Guias Labeling</CardlabelTitle>
            <Cardlabel>50</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Guias EOL</CardlabelTitle>
            <Cardlabel>20</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Guias Higiêncios</CardlabelTitle>
            <Cardlabel>10</Cardlabel>
          </CardInfo>
        </CardInfos>

        <MaterialTable
          style={{
            marginTop: 20,
            width: "100%",
            maxHeight: "100%",
            overflow: "auto",
          }}
          localization={{
            header: {
              actions: "",
            },
          }}
          actions={[
            {
              icon: () => <FiEdit color="#E1000F" />,
              tooltip: "Update",
              onClick: (event, rowData) => {
                setCurrentSupervisor(rowData);
                setClienteId(rowData.cliente_id);
                getPlanta(rowData.planta);
                openModal();
              },
            },
            {
              icon: () => (
                <FaTrashAlt
                  color="#E1000F"
                  style={{ position: "absolute", left: "10px" }}
                />
              ),
              tooltip: "Delete",
              onClick: (event, rowData) => {
                deleted(rowData?.id);
                window.location.reload();
              },
            },
            {
              icon: () => (
                <Button onClick={() => history.push("/cadastro_supervisor")}>
                  <ButtonTitle>Cadastrar novo</ButtonTitle>
                </Button>
              ),
              isFreeAction: true,
            },
          ]}
          // icons={{
          //   Search:() => <BsSearch color="#E1000F" />
          // }}
          columns={[
            { title: "Cliente", field: "nome_cliente" },
            { title: "Local da Planta", field: "local_planta_cliente" },
            { title: "Nome do supervisor", field: "supervisor" },
            { title: "Login do supervisor", field: "login" },
            { title: "Status da Mudança", field: "status_mudanca" },
          ]}
          data={supervisors}
          options={{
            headerStyle: {
              zIndex: modalIsOpen && -1,
            },
          }}
          title="Cadastro de Supervisores"
        />
        {console.log(supervisors, "gsahasdg")}
      </ComponentTable>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ComponentEdit>
          <Title>Edição de Supervisores</Title>
          <ContainerInput>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CardInput>
                  <TitleInput>Cliente</TitleInput>
                  <form>
                    <Select
                      defaultValue={currentSupervisor?.cliente_id}
                      onChange={(v) => {
                        setLoading(false);
                        setClienteId(v.target.value);
                        setLocalPlantaCliente(undefined);
                        let temp = clientes.find(
                          (c) => c.id === +v.target.value
                        );
                        console.log("vendo as plantas", temp);
                        setPlantas(temp.planta);
                        setTimeout(() => {
                          setLoading(true);
                        }, 500);
                      }}
                    >
                      {console.log(clientes, "desde")}
                      {clientes?.map((item, index) => (
                        <option key={index} value={item?.id}>
                          {item?.nome}
                        </option>
                      ))}
                    </Select>
                  </form>

                  <TitleInput>Nome do supervisor</TitleInput>
                  <Input
                    style={{ width: 320 }}
                    defaultValue={currentSupervisor?.supervisor}
                    onChange={(e) => setSupervisor(e.target.value)}
                  />

              <TitleInput>Senha</TitleInput>
              <Input
                style={{ width: 320 }}
                defaultValue={''}
                onChange={(e) => setSenha(e.target.value)}
              />
                
                </CardInput>

                <CardInput>
                  <TitleInput>Local da planta do cliente</TitleInput>
                  <form>
                    {loading
                      ? (() => (
                          <Select
                            defaultValue={
                              currentSupervisor?.local_planta_cliente
                            }
                            onChange={(v) => {
                              setLocalPlantaCliente(v.target.value);
                            }}
                          >
                            {plantasNome?.map((item, index) => (
                              <>
                                <option value={item} key={index}>
                                  {item}
                                </option>
                              </>
                            ))}
                          </Select>
                        ))()
                      : (() => (
                          <Select
                            defaultValue={
                              currentSupervisor?.local_planta_cliente
                            }
                            onChange={(v) => {
                              setLocalPlantaCliente(v.target.value);
                            }}
                          ></Select>
                        ))()}
                  </form>

              <TitleInput>Login do supervisor</TitleInput>
              <Input
                style={{ width: 320 }}
                defaultValue={currentSupervisor?.login}
                onChange={(e) => setLogin(e.target.value)}
              />

                </CardInput>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10%",
                }}
              >
               

                <ButtonSave
                  style={{ borderColor: "red" }}
                  onClick={() => setIsOpen(false)}
                >
                  <ButtonTitle style={{ color: "red", textAlign: "center" }}>
                    Cancelar
                  </ButtonTitle>
                </ButtonSave>
                <ButtonSave
                  onClick={() => putSupervisors(currentSupervisor?.id)}
                  style={{ backgroundColor: "red" }}
                >
                  <ButtonTitle style={{ color: "white", textAlign: "center" }}>
                    Salvar
                  </ButtonTitle>
                </ButtonSave>
              </div>
            </div>
          </ContainerInput>
        </ComponentEdit>
      </Modal>
    </Container>
  );
}
export default ListSupervisor;
