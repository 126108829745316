import React, { lazy } from "react";
import { Route, Redirect } from "react-router-dom";

import Login from "../views/Login";

export default function SignRoutes() {
  return (
    <>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route exact path="/login" component={Login} />
    </>
  );
}
