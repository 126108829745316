import React, { useState } from "react";
import { IoAddCircle } from "react-icons/io5";
import { IoRemoveCircle } from "react-icons/io5";
import { ReactSVG } from "react-svg";
import Cookies from "js-cookie";

import {
  Container,
  LatealMenu,
  Logo,
  Title,
  InfoContainers,
  InfoContainer,
  LabelButton,
  ComponentTable,
  ContainerCadastro,
  ContainerInput,
  TitleInput,
  Input,
  CardInput,
  Button,
  LabelButton2,
  ButtonIcon,
} from "./styles";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import { ToastNotifier } from "../../../helpers/ToastNotifier";
import { useAuth } from "../../../hooks/useAuth";

function CadastroCliente() {
  let history = useHistory();
  const {user, setUser} = useAuth();
  const [nome, setNome] = useState();
  const [plantasArray, setPlantasArray] = useState([{ planta: "" }]);
  const [id_henkel, setIdHenkel] = useState();
  const [admin_id, setAdminId] = useState(user?.id);
  const [responsavel, setResponsavel] = useState();
  const [vendedor, setVendedor] = useState();
  const [tcs_responsavel, setTcsResponsavel] = useState();
  const [frase_abertura_app, setFraseAberturaApp] = useState();
  const [status_mudanca, setStatusMudanca] = useState(user?.nivel_acesso !== '999' ? "Pendente" : "Aprovado");
  

  async function postClientes() {
    try {
      const plantasAux = [];

      for (let i = 0; i < plantasArray.length; i++) {
        plantasAux.push(plantasArray[i].planta);
      }

      const separatedPlantas = plantasAux.join(", ");

      const data = {
        id_henkel,
        responsavel,
        planta_id: separatedPlantas,
        nome,
        vendedor,
        tcs_responsavel,
        frase_abertura_app,
        status_mudanca,
        admin_id,
      };

      const response = await api.post("/cliente", {
        data,
      });

      if (response?.status === 201) {
        history.push("/list_cliente");

        ToastNotifier({
          toastMessage: "Cliente cadastrado com sucesso!",
          toastType: "success",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleLogout = () => {
    try{
      setUser(null);
      Cookies.remove("authToken");
      Cookies.remove("authUser");
      history.push("/login");
      window.location.reload();
    }catch(error){
      console.log(error);
      console.log("Não foi possível deslogar");
    }
  }

  const handleChangeInput = (index, event) => {
    const values = [...plantasArray];

    values[index].planta = event.target.value;

    setPlantasArray(values);
  };

  const handleRemovePlanta = (index) => {
    if (plantasArray.length > 1) {
      let newPlantas = [...plantasArray];

      newPlantas.splice(index, 1);

      setPlantasArray(newPlantas);
    }
  };

  return (
    <Container>
      <LatealMenu>
        <Logo />
        <InfoContainers>
        <InfoContainer>
          {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_relatorio");
              }}
            >
              <ReactSVG src="/images/Relatorio.svg" />
              Relatórios
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_mudanca");
              }}
            >
              <ReactSVG src="/images/SolicitacaoMudanca.svg" />
              Solicitações de Mudança
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_forms_respondidos");
              }}
            >
              <ReactSVG src="/images/FormRespondido.svg" />
              Formulários Respondidos
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton 
            style={{gap: "4.5%"}}
              onClick={() => {
                history.push("/list_adm_intermediario");
              }}
            >
              <ReactSVG src="/images/AdmInter.svg" />
              Cadastro de ADM Intermediário
            </LabelButton>): (<></>)}

            <LabelButton
              style={{ gap: "5%", fontWeight: "bold" }}
              onClick={() => {
                history.push("/list_cliente");
              }}
            >
              <ReactSVG src="/images/CadastroCliente.svg" />
              Cadastro de Clientes
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_aparelho");
              }}
            >
              <ReactSVG src="/images/CadastroAparelho.svg" />
              Cadastro de Aparelhos
            </LabelButton>

            <LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_guia");
              }}
            >
              <ReactSVG src="/images/CadastroGuia.svg" />
              Cadastro de Guias
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_adesivo");
              }}
            >
              <ReactSVG src="/images/CadastroAdesivo.svg" />
              Cadastro de Adesivos
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_produto");
              }}
            >
              <ReactSVG src="/images/CadastroProduto.svg" />
              Cadastro de Produtos
            </LabelButton>
            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_supervisor");
              }}
            >
              <ReactSVG src="/images/CadastroSupervisor.svg" />
              Cadastro de Supervisores
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_linha");
              }}
            >
              <ReactSVG src="/images/CadastroLinha.svg" />
              Cadastro de Linha
            </LabelButton>

            <LabelButton onClick={()=> {
                handleLogout()
              }}>
                <ReactSVG src="/images/logOut.svg" />
              Log Out
            </LabelButton>
            
          </InfoContainer>
        </InfoContainers>
      </LatealMenu>
      <ContainerCadastro>
        <ComponentTable>
          <Title>Cadastro de Clientes</Title>
          <ContainerInput>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CardInput>
                  <TitleInput>Cliente</TitleInput>
                  <Input
                    style={{ width: 320 }}
                    onChange={(e) => setNome(e.target.value)}
                  />

                  <TitleInput>Responsável do cliente</TitleInput>
                  <Input
                    style={{ width: 320 }}
                    onChange={(e) => setResponsavel(e.target.value)}
                  />

                  <TitleInput>TCS Responsável</TitleInput>
                  <Input
                    style={{ width: 320 }}
                    onChange={(e) => setTcsResponsavel(e.target.value)}
                  />

                  <TitleInput>Frase da Abertura do Aplicativo</TitleInput>
                  <Input
                    style={{ width: 320 }}
                    onChange={(e) => setFraseAberturaApp(e.target.value)}
                  />
                </CardInput>

                <CardInput>
                  <TitleInput>Vendedor</TitleInput>
                  <Input
                    style={{ width: 320 }}
                    onChange={(e) => setVendedor(e.target.value)}
                  />
                  <TitleInput>ID Henkel</TitleInput>
                  <Input
                    style={{ width: 320 }}
                    onChange={(e) => setIdHenkel(e.target.value)}
                  />

                  <TitleInput>Plantas</TitleInput>
                  {plantasArray?.map((item, index) => (
                    <>
                      <div key={index}>
                        <TitleInput></TitleInput>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItens: "center",
                            gap: "10%",
                          }}
                        >
                          <Input
                            style={{ width: 320, height: 45 }}
                            value={item.planta}
                            onChange={(e) => handleChangeInput(index, e)}
                          />

                          <ButtonIcon
                            onClick={() => {
                              handleRemovePlanta(index);
                            }}
                            style={{
                              width: 30,
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: 30,
                              borderCollor: "white",
                            }}
                          >
                            <IoRemoveCircle size={30} color="red" />
                          </ButtonIcon>
                        </div>
                      </div>
                    </>
                  ))}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItens: "center",
                      gap: "10%",
                    }}
                  >
                    <ButtonIcon
                      style={{
                        width: 30,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 30,
                        borderCollor: "white",
                      }}
                      disabled={plantasArray.some((planta) => planta.planta === "")}
                      onClick={() =>
                        setPlantasArray([...plantasArray, { planta: "" }])
                      }
                    >
                      <IoAddCircle size={30} color="red" />
                    </ButtonIcon>
                  </div>
                </CardInput>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "10%",
                  justifyContent: "center",
                }}
              >

                  <Button
                  style={{ borderColor: "red" }}
                  onClick={() => history.push("/list_cliente")}
                >
                  <LabelButton2 style={{ color: "red", textAlign: "center" }}>
                    Cancelar
                  </LabelButton2>
                </Button>
                <Button
                disabled={plantasArray.some((planta) => planta.planta === "")}
                  onClick={() => postClientes()}
                  style={{ backgroundColor: "red" }}
                >
                  <LabelButton2 style={{ color: "white" }}>
                    Cadastrar
                  </LabelButton2>
                </Button>

               
              </div>
            </div>
          </ContainerInput>
        </ComponentTable>
      </ContainerCadastro>
    </Container>
  );
}
export default CadastroCliente;
