import React, { useState, useEffect, createContext } from "react";
import { ToastNotifier } from "../helpers/ToastNotifier";
import api from "../services/api";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();

  useEffect(() => {
    const loadStoragedData = async () => {
      try{
      const storagedUser = Cookies.get("authUser");

      const storagedToken = Cookies.get("authToken");

      await new Promise((resolve) => setTimeout(resolve, 800));

      const response = await api.post("generateToken", {
        token: storagedToken,
      });
      console.log(response);

      const hasTokenExpired = response.data.isTokenExpired;

      if (hasTokenExpired === true){
        Logout();
      }

      if (storagedUser && storagedToken) {
        api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
        setUser(JSON.parse(storagedUser));
      }

      
    } catch (error){
      const status = error.response.status
      console.log(history)
      if(status === 401 && history.location.pathname !== "/login" ){
        Logout();
      }
      console.log(error.response.status)
    }
    finally{
      setIsLoading(false);
    }

      
      
    };

    loadStoragedData();
  }, []);

  const Login = async () => {
    try {
      const response = await api.post("auth_admin", {
        email,
        senha,
      });
      console.log("entrou aqui ", response);
      setUser(response.data.admin);
      history.push("/list_aparelho");
      api.defaults.headers.Authorization = `Bearer ${response.data.token.token}`;

      Cookies.set("authUser", JSON.stringify(response.data.admin));

      Cookies.set("authToken", response.data.token.token, {
        expires: 7,
      });

      ToastNotifier({
        toastMessage: "Administrador logado com sucesso!",

        toastType: "success",
      });
    } catch (e) {
      console.log(e, "nao entrou");
      setIsLoading(false);
      ToastNotifier({
        toastMessage: "Erro ao efetuar login na aplicação!",
        toastType: "error",
      });
    }
  };

  const Logout = () => {
    setUser(null);

    Cookies.remove("authToken");
    Cookies.remove("authUser");
    history.push("/login");
    window.location.reload();

    if (Cookies.get("acceptCookies")) Cookies.remove("acceptCookies");

    if (+user.userType !== 1) {
      ToastNotifier({
        toastMessage: "Você foi desconectado com sucesso!",
        toastType: "success",
      });
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <div
          className="container"
          style={{
            width: "300px",
            height: "300px",
          }}
        />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        signed: Boolean(user),
        user,
        Login,
        Logout,
        setUser,
        setEmail,
        setSenha,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
