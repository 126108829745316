import React, { useEffect, useState, useContext } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Cookies from "js-cookie";
import { ReactSVG } from "react-svg";

import {
  Container,
  LatealMenu,
  Logo,
  Title,
  InfoContainers,
  ButtonSave,
  ComponentEdit,
  ComponentEditAparelho,
  ComponentEditAdesivo,
  ComponentEditCliente,
  ComponentEditLinha,
  ComponentEditSupervisor,
  ComponentEditProduto,
  InfoContainer,
  ButtonIcon,
  LabelButton,
  LabelButton2,
  ComponentTable,
  CardInfos,
  CardInfo,
  Cardlabel,
  CardlabelTitle,
  TitleContainer,
  Button,
  ButtonTitle,
  ContainerInput,
  CardInput,
  TitleInput,
  Select,
  Input,
} from "./styles";
import MaterialTable from "material-table";
import { FaTrashAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { BsSearch } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import Modal from "react-modal";
import { useAuth } from "../../../hooks/useAuth";

function ListMudanca() {
  const customStyles = {
    content: {
      width: "70%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      height: "80%",
      zIndex: 999,
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      overflow: "scroll",
    },
  };

  let history = useHistory();
  const { user, setUser } = useAuth();
  const [Mudanca, setMudanca] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [aparelhos, setAparelhos] = useState([]);
  const [adesivos, setAdesivos] = useState([]);
  const [linhas, setLinhas] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [problemas, setProblemas] = useState([]);

  const [currentStatus, setCurrentStatus] = React.useState({});
  const [nome, setNome] = useState();
  const [emails_array, setEmailsArray] = useState([{ email: "" }]);
  const [plantas_array, setPlantasArray] = useState([{ planta: "" }]);
  const [id_henkel, setIdHenkel] = useState();
  const [idh_sku, setIdhSku] = useState();
  const [supervisor, setSupervisor] = useState();
  const [login, setLogin] = useState();
  const [volume, setVolume] = useState();

  const [nomeCliente, setNomeCliente] = useState();
  const [cliente_nome, setClienteNome] = useState();
  const [formularios, setFormularios] = useState();
  const [id_aparelho, setIdAparelho] = useState();
  const [aparelho_indef, setAparelhoIndef] = useState();
  const [senha, setSenha] = useState();
  const [aparelho_id, setAparelhoId] = useState();
  const [email_id, setEmailId] = useState();
  const [responsavel, setResponsavel] = useState();
  const [area_adesivo, setAreaAdesivo] = useState();
  const [nome_adesivo, setNomeAdesivo] = useState();
  const [local_planta_cliente, setLocalPlantaCliente] = useState();
  const [plantas, setPlantas] = useState([]);
  const [loadingPlanta, setLoadingPlanta] = useState(true);
  const [planta_id, setPlantaId] = useState();
  const [cliente_id, setClienteId] = useState();
  const [vendedor, setVendedor] = useState();
  const [tcs_responsavel, setTcsResponsavel] = useState();
  const [frase_abertura_app, setFraseAberturaApp] = useState();
  const [currentAparelho, setCurrentAparelho] = React.useState({});
  const [loading, setLoading] = useState(true);
  const [status_mudanca, setStatusMudanca] = useState(
    user?.nivel_acesso !== "999" ? "Pendente" : "Aprovado"
  );
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenProblema, setIsOpenProblema] = React.useState(false);
  const [modalIsOpenAparelho, setIsOpenAparelho] = React.useState(false);
  const [modalIsOpenAdesivo, setIsOpenAdesivo] = React.useState(false);
  const [modalIsOpenProduto, setIsOpenProduto] = React.useState(false);
  const [modalIsOpenLinha, setIsOpenLinha] = React.useState(false);
  const [modalIsOpenSupervisor, setIsOpenSupervisor] = React.useState(false);
  const [isTrue, setIsTrue] = useState(true);

  async function getStatus() {
    const response = await api.get("/status");

    const statusAparelho = response?.data?.aparelhos.map((aparelho) => {
      return {
        id: aparelho?.id,
        user: aparelho?.admins?.nome,
        categoria: "Aparelhos",
        status_mudanca: aparelho?.status_mudanca,
        data_hora: aparelho?.updated_at,
        nome_cliente: aparelho?.nome_cliente,
        cliente_id: aparelho?.cliente_id,
        vendedor: aparelho?.vendedor,
        senha: aparelho?.senha,
        local_planta_cliente: aparelho?.local_planta_cliente,
        tcs_responsavel: aparelho?.tcs_responsavel,
        formularios: aparelho?.formularios,
        id_aparelho: aparelho.id_aparelho,
        aparelho_email: aparelho?.aparelho_emails.map((aparelho_email)=> aparelho_email),
        email: aparelho?.aparelho_emails.map((aparelho_email)=> aparelho_email?.emails),
      };
    });
    

    const statusAdesivo = response?.data?.adesivos.map((adesivo) => {
      return {
        id: adesivo?.id,
        user: adesivo?.admins?.nome,
        categoria: "Adesivos",
        status_mudanca: adesivo?.status_mudanca,
        data_hora: adesivo?.updated_at,
        local_planta_cliente: adesivo?.local_planta_cliente,
        nome_adesivo: adesivo?.nome_adesivo,
        aparelho_indef: adesivo?.aparelho_indef,
        idh_sku: adesivo?.idh_sku,
        cliente_id: adesivo?.cliente_id,
      };
    });

    const statusCliente = response?.data?.clientes.map((cliente) => {
      return {
        id: cliente?.id,
        categoria: "Clientes",
        user: cliente?.admins?.nome,
        status_mudanca: cliente?.status_mudanca,
        data_hora: cliente?.updated_at,
        frase_abertura_app: cliente?.frase_abertura_app,
        tcs_responsavel: cliente?.tcs_responsavel,
        vendedor: cliente?.vendedor,
        nome: cliente?.nome,
        id_henkel: cliente?.id_henkel,
        responsavel: cliente?.responsavel,
        cliente_planta: cliente?.cliente_plantas.map((cliente_planta)=> cliente_planta),
        planta: cliente?.cliente_plantas.map((cliente_planta)=> cliente_planta?.plantas),
       
      };
    });
    console.log(response.data.clientes, "clientes");

    const statusSupervisor = response?.data?.supervisors.map((supervisor) => {
      return {
        id: supervisor?.id,
        categoria: "Supervisors",
        user: supervisor?.admins?.nome,
        status_mudanca: supervisor?.status_mudanca,
        data_hora: supervisor?.updated_at,
        supervisor: supervisor?.supervisor,
        login: supervisor?.login,
        senha: supervisor?.senha,
        cliente_id: supervisor?.cliente_id,
        local_planta_cliente: supervisor?.local_planta_cliente,
      };
    });

    const statusProduto = response?.data?.produtos.map((produto) => {
      return {
        id: produto?.id,
        categoria: "Produtos",
        user: produto?.admins?.nome,
        status_mudanca: produto?.status_mudanca,
        data_hora: produto?.updated_at,
        cliente_nome: produto?.cliente_nome,
        area_adesivo: produto?.area_adesivo,
        nome_adesivo: produto?.nome_adesivo,
        local_planta_cliente: produto?.local_planta_cliente,
        cliente_id: produto?.cliente_id,
        volume: produto?.volume,
        adesivo_id: produto?.adesivo_id,
        produto: produto?.produto,
      };
    });
   
    const statusProblema = response?.data?.problemas.map((problema) => {
      return {
        id: problema?.id,
        categoria: "Guias",
        user: problema?.admins?.nome,
        status_mudanca: problema?.status_mudanca,
        data_hora: problema?.updated_at,
        titulo: problema?.titulo,
        descricao_problema: problema?.descricao_problema,
        id_categoria: problema?.id_categoria,
        img_file: problema?.img_file,
        form_problemas: problema?.form_problemas,
        acao: problema?.causas.map((causa)=> causa.acoes),
        causa: problema?.causas.map((causa)=> causa),
        palavra: problema?.palavra_chaves.map((palavra)=> palavra),
      };
    });
    

    const statusLinha = response?.data?.linhas.map((linha) => {
      return {
        id: linha?.id,
        categoria: "Linhas",
        user: linha?.admins?.nome,
        status_mudanca: linha?.status_mudanca,
        data_hora: linha?.updated_at,
        num_linha: linha?.num_linha,
        cliente_id: linha?.cliente_id,
        local_planta_cliente: linha?.local_planta_cliente,
      };
    });

    setMudanca(
      statusAparelho
        .concat(statusCliente)
        .concat(statusAdesivo)
        .concat(statusSupervisor)
        .concat(statusProduto)
        .concat(statusProblema)
        .concat(statusLinha)
    );
    console.log(
      statusAparelho
        .concat(statusCliente)
        .concat(statusAdesivo)
        .concat(statusSupervisor)
    );
  }

  async function putStatusAparelho(id_aparelho) {
    try {
      let data;
      data = {
        status_mudanca,

      };
      const responseAparelho = await api.put(
        `/update_aparelho/${id_aparelho}`,
        data
      );
      window.location.reload();
      history.push("/list_mudanca");
    } catch (error) {
      console.log(error);
    }
  }

  async function putStatusAdesivo(id_adesivo) {
    try {
      let data;
      data = {
        status_mudanca,
      };
      const responseAdesivo = await api.put(
        `/update_adesivo/${id_adesivo}`,
        data
      );
      window.location.reload();
      history.push("/list_mudanca");
    } catch (error) {
      console.log(error);
    }
  }

  async function putStatusCliente(id_cliente) {
    try {
      let data;
      data = {
        status_mudanca,
      };
      const responseCliente = await api.put(
        `/update_cliente/${id_cliente}`,
        data
      );
      window.location.reload();
      history.push("/list_mudanca");
    } catch (error) {
      console.log(error);
    }
  }

  async function putStatusProblema(id_problema) {
    try {
      let data;
      data = {
        status_mudanca,
      };
      const responseProblema = await api.put(
        `/update_problema/${id_problema}`,
        data
      );
      window.location.reload();
      history.push("/list_mudanca");
    } catch (error) {
      console.log(error);
    }
  }

  async function putStatusSupervisor(id_supervisor) {
    try {
      let data;
      data = {
        status_mudanca,
      };
      const responseSupervisor = await api.put(
        `/update_supervisor/${id_supervisor}`,
        data
      );
      window.location.reload();
      history.push("/list_mudanca");
    } catch (error) {
      console.log(error);
    }
  }

  async function putStatusProduto(id_produto) {
    try {
      let data;
      data = {
        status_mudanca,
      };
      const responseProduto = await api.put(
        `/update_produto/${id_produto}`,
        data
      );
      window.location.reload();
      history.push("/list_mudanca");
    } catch (error) {
      console.log(error);
    }
  }

  async function putStatusLinha(id_linha) {
    try {
      let data;
      data = {
        status_mudanca,
      };
      const responseLinha = await api.put(
        `/update_linha/${id_linha}`,
        data
      );
      window.location.reload();
      history.push("/list_mudanca");
    } catch (error) {
      console.log(error);
    }
  }

  const handleLogout = () => {
    try{
      setUser(null);
      Cookies.remove("authToken");
      Cookies.remove("authUser");
      history.push("/login");
      window.location.reload();
    }catch(error){
      console.log(error);
      console.log("Não foi possível deslogar");
    }
  }


  

async function deletedAparelho(id_aparelho) {
  try {
    const response = await api.delete(`/delete_aparelho/${id_aparelho}`)
  } catch (error) {
    console.log(error)
  }
}

async function deletedAdesivo(id_adesivo) {
try {
  const response = await api.delete(`/delete_adesivo/${id_adesivo}`)
} catch (error) {
  console.log(error)
}
}

async function deletedSupervisor(id_supervisor) {
  try {
    const response = await api.delete(`/delete_supervisor/${id_supervisor}`)
  } catch (error) {
    console.log(error)
  }
}

async function deletedProduto(id_produto) {
  try{
    const response = await api.delete(`/delete_produto/${id_produto}`);
  }catch(error){
    console.log(error);
  }
}

async function deletedLinha(id_linha) {
  try{
    const response = await api.delete(`/delete_linha/${id_linha}`);
  }catch(error){
    console.log(error);
  }
}

async function deletedCliente(id_cliente) {
  try{
    const response = await api.delete(`/delete_cliente/${id_cliente}`)
  }catch(error){
  }
}

async function deletedProblema(id_problema) {
  try{
    const response = await api.delete(`/delete_problema/${id_problema}`)
  } catch(error){
    console.log(error)
  }
}




  
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  function openModalLinha() {
    setIsOpenLinha(true);
  }
  function closeModalLinha() {
    setIsOpenLinha(false);
  }

  function openModalProduto() {
    setIsOpenProduto(true);
  }
  function closeModalProduto() {
    setIsOpenProduto(false);
  }

  function openModalSupervisor() {
    setIsOpenSupervisor(true);
  }
  function closeModalSupervisor() {
    setIsOpenSupervisor(false);
  }

  function openModalAdesivo() {
    setIsOpenAdesivo(true);
  }
  function closeModalAdesivo() {
    setIsOpenAdesivo(false);
  }

  function openModalAparelho() {
    setIsOpenAparelho(true);
  }
  function closeModalAparelho() {
    setIsOpenAparelho(false);
  }

  function openModalProblema() {
    setIsOpenProblema(true);
  }
  function closeModalProblema() {
    setIsOpenProblema(false);
  }

  async function deleted(id_aparelho) {
    console.log("deleted: " + id_aparelho);
    try {
      const response = await api.delete("/delete_mudanca", {
        id: id_aparelho,
      });
      console.log(response.data);
    } catch (error) {}
  }
  useEffect(() => {
    getStatus();
  }, []);

  return (
    <Container>
      <LatealMenu>
        <Logo />
        <InfoContainers>
          <InfoContainer>
            {user?.nivel_acesso === "999" ? (
              <LabelButton
                style={{ gap: "5%" }}
                onClick={() => {
                  history.push("/list_relatorio");
                }}
              >
                <ReactSVG src="/images/Relatorio.svg" />
                Relatórios
              </LabelButton>
            ) : (
              <></>
            )}

            {user?.nivel_acesso === "999" ? (
              <LabelButton
                style={{ gap: "4.5%", fontWeight: "bold" }}
                onClick={() => {
                  history.push("/list_mudanca");
                }}
              >
                <ReactSVG src="/images/SolicitacaoMudanca.svg" />
                Solicitações de Mudança
              </LabelButton>
            ) : (
              <></>
            )}

            {user?.nivel_acesso === "999" ? (
              <LabelButton
                style={{ gap: "5%" }}
                onClick={() => {
                  history.push("/list_forms_respondidos");
                }}
              >
                <ReactSVG src="/images/FormRespondido.svg" />
                Formulários Respondidos
              </LabelButton>
            ) : (
              <></>
            )}

            {user?.nivel_acesso === "999" ? (
              <LabelButton
                style={{ gap: "4.5%" }}
                onClick={() => {
                  history.push("/list_adm_intermediario");
                }}
              >
                <ReactSVG src="/images/AdmInter.svg" />
                Cadastro de ADM Intermediário
              </LabelButton>
            ) : (
              <></>
            )}

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_cliente");
              }}
            >
              <ReactSVG src="/images/CadastroCliente.svg" />
              Cadastro de Clientes
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_aparelho");
              }}
            >
              <ReactSVG src="/images/CadastroAparelho.svg" />
              Cadastro de Aparelhos
            </LabelButton>

            <LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_guia");
              }}
            >
              <ReactSVG src="/images/CadastroGuia.svg" />
              Cadastro de Guias
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_adesivo");
              }}
            >
              <ReactSVG src="/images/CadastroAdesivo.svg" />
              Cadastro de Adesivos
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_produto");
              }}
            >
              <ReactSVG src="/images/CadastroProduto.svg" />
              Cadastro de Produtos
            </LabelButton>
            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_supervisor");
              }}
            >
              <ReactSVG src="/images/CadastroSupervisor.svg" />
              Cadastro de Supervisores
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_linha");
              }}
            >
              <ReactSVG src="/images/CadastroLinha.svg" />
              Cadastro de Linha
            </LabelButton>

            <LabelButton onClick={()=> {
                handleLogout()
              }}>
                <ReactSVG src="/images/logOut.svg" />
              Log Out
            </LabelButton>
            
          </InfoContainer>
        </InfoContainers>
      </LatealMenu>
      <ComponentTable>
        <TitleContainer>
          <Title>Dashboard Henkel</Title>
          <Title>Seja muito bem-vindo</Title>
        </TitleContainer>
        <CardInfos>
          <CardInfo>
            <CardlabelTitle>Todos</CardlabelTitle>
            <Cardlabel>350</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Em Aberto</CardlabelTitle>
            <Cardlabel>50</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Reprovados</CardlabelTitle>
            <Cardlabel>20</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Aprovados</CardlabelTitle>
            <Cardlabel>10</Cardlabel>
          </CardInfo>
        </CardInfos>

        <MaterialTable
          style={{
            maxHeight: "100%",
            overflow: "auto",
            marginTop: 20,
            width: "100%",
          }}
          localization={{
            header: {
              actions: "",
            },
          }}
          actions={[
            {
              icon: () => <FiEdit color="#E1000F" />,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                console.log(rowData.categoria);
                if (rowData.categoria === "Clientes") {
                  openModal();
                  setClientes(rowData);
                }
                if (rowData.categoria === "Adesivos") {
                  openModalAdesivo();
                  setAdesivos(rowData);
                }
                if (rowData.categoria === "Linhas") {
                  openModalLinha();
                  setLinhas(rowData);
                }
                if (rowData.categoria === "Supervisors") {
                  openModalSupervisor();
                  setSupervisors(rowData);
                }
                if (rowData.categoria === "Produtos") {
                  openModalProduto();
                  setProdutos(rowData);
                }
                if (rowData.categoria === "Aparelhos") {
                  openModalAparelho();
                  setAparelhos(rowData);
                }
                if (rowData.categoria === "Guias") {
                  openModalProblema();
                  setProblemas(rowData);
                }
              },
            },
            
          ]}
          columns={[
            { title: "Usuario", field: "user" },
            { title: "Categoria", field: "categoria" },
            { title: "Data e Horário", field: "data_hora" },
            { title: "Status", field: "status_mudanca" },
          ]}
          data={Mudanca}
          options={{
            headerStyle: {
              zIndex:
                modalIsOpen ||
                modalIsOpenAparelho ||
                modalIsOpenAdesivo ||
                modalIsOpenLinha ||
                modalIsOpenProduto ||
                modalIsOpenProblema ||
                modalIsOpenSupervisor
                  ? -1
                  : false,
            },
          }}
          title="Requisições de Mudança"
        />
      </ComponentTable>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ComponentEditCliente>
          <Title>Edição de Clientes</Title>
          <ContainerInput>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CardInput>
                  <TitleInput>Cliente</TitleInput>
                  <Input disabled style={{ width: 320 }} defaultValue={clientes.nome} />
                  <TitleInput>Responsável do cliente</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={clientes.responsavel}
                  />

                  <TitleInput>TCS Responsável</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={clientes.tcs_responsavel}
                  />

                  <TitleInput>Frase de abertura</TitleInput>
                  <Input 
                  disabled
                    style={{ width: 320 }}
                    defaultValue={clientes.frase_abertura_app}
                  />
                </CardInput>

                <CardInput>
                  <TitleInput>Vendedor</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={clientes.vendedor}
                  />
                  <TitleInput>ID HENKEL</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={clientes.id_henkel}
                  />

                  <TitleInput>Plantas</TitleInput>
                  {console.log(clientes?.planta, "testePlanta")}
                  {clientes?.planta?.map((planta)=> {
                    return (
                      <Input disabled defaultValue={planta[0].nome} />
                    )
                  })}
                  
                </CardInput>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10%",
                }}
              >

                <ButtonSave
                  style={{ borderColor: "red" }}
                  onClick={() => {
                    deletedCliente(clientes.id)
                    setIsOpen(false)
                    window.location.reload()
                  }}
                >
                  <ButtonTitle style={{ color: "red", textAlign: "center" }}>
                    Apagar
                  </ButtonTitle>
                </ButtonSave>
                
                <ButtonSave 
                
                onClick={() => {
                  putStatusCliente(clientes.id)
                  window.location.reload()
                }}
                style={{ backgroundColor: "red" }}
              >
                
                  <ButtonTitle style={{ color: "white", textAlign: "center" }}>
                    Aprovar
                  </ButtonTitle>
                </ButtonSave>
              </div>
            </div>
          </ContainerInput>
        </ComponentEditCliente>
      </Modal>

      <Modal
        isOpen={modalIsOpenAdesivo}
        onRequestClose={closeModalAdesivo}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ComponentEditAdesivo>
          <Title>Edição de Adesivos</Title>
          <ContainerInput>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CardInput>
                  <TitleInput>Cliente</TitleInput>

                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={adesivos?.cliente_id}
                  />

                  <TitleInput>IDH do adesivo</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={adesivos?.idh_sku}
                  />
                  <TitleInput>APARELHO *****</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={adesivos?.aparelho_indef}
                  />
                </CardInput>

                <CardInput>
                  <TitleInput>Local da planta do cliente</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={adesivos?.local_planta_cliente}
                  />

                  <TitleInput>Nome Adesivo</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={adesivos?.nome_adesivo}
                  />
                </CardInput>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10%",
                }}
              >
               

                <ButtonSave
                  style={{ borderColor: "red" }}
                  onClick={() => setIsOpen(false)}
                >
                  <ButtonTitle style={{ color: "red", textAlign: "center" }}>
                    Apagar
                  </ButtonTitle>
                </ButtonSave>
                <ButtonSave
                  onClick={() => {
                    putStatusAdesivo(adesivos.id)
                    window.location.reload()
                  }}
                  style={{ backgroundColor: "red" }}
                >
                  <ButtonTitle style={{ color: "white", textAlign: "center" }}>
                    Aprovar
                  </ButtonTitle>
                </ButtonSave>
              </div>
            </div>
          </ContainerInput>
        </ComponentEditAdesivo>
      </Modal>

      <Modal
        isOpen={modalIsOpenSupervisor}
        onRequestClose={closeModalSupervisor}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ComponentEditSupervisor>
          <Title>Edição de Supervisores</Title>
          <ContainerInput>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CardInput>
                  <TitleInput>Cliente</TitleInput>

                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={supervisors?.cliente_id}
                  />

                  <TitleInput>Nome do supervisor</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={supervisors?.supervisor}
                  />

                  <TitleInput>Senha</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={supervisors?.senha}
                  />
                </CardInput>

                <CardInput>
                  <TitleInput>Local da planta do cliente</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={supervisors?.local_planta_cliente}
                  />

                  <TitleInput>Login do supervisor</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={supervisors?.login}
                  />
                </CardInput>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10%",
                }}
              >
               

                <ButtonSave
                  style={{ borderColor: "red" }}
                  onClick={() => {
                    deletedAdesivo(adesivos.id)
                    setIsOpen(false)
                    window.location.reload()
                  }}
                >
                  <ButtonTitle style={{ color: "red", textAlign: "center" }}>
                    Apagar
                  </ButtonTitle>
                </ButtonSave>
                <ButtonSave 
                onClick={() => {
                  putStatusSupervisor(supervisors.id)
                  window.location.reload()
                }}
                style={{ backgroundColor: "red" }}
                >
                  <ButtonTitle style={{ color: "white", textAlign: "center" }}>
                    Aprovar
                  </ButtonTitle>
                </ButtonSave>
              </div>
            </div>
          </ContainerInput>
        </ComponentEditSupervisor>
      </Modal>

      <Modal
        isOpen={modalIsOpenAparelho}
        onRequestClose={closeModalAparelho}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ComponentEditAparelho>
          <Title>Edição de Aparelhos</Title>
          {console.log(aparelhos.rowData, "CURRENT APARELHO")}
          <ContainerInput>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CardInput>
                  <TitleInput>Cliente</TitleInput>

                  <Input disabled defaultValue={aparelhos?.cliente_id} />

                  <TitleInput>Vendedor</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={aparelhos?.vendedor}
                  />
                  <TitleInput>Senha</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={aparelhos?.senha}
                  />

                  <TitleInput>Guias de soluções</TitleInput>

                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={aparelhos?.formularios}
                  />
                </CardInput>

                <CardInput>
                  <TitleInput>Local da planta do cliente</TitleInput>

                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={aparelhos?.local_planta_cliente}
                  />

                  <TitleInput>ID aparelho</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={aparelhos?.id_aparelho}
                  />
                  <TitleInput>TCS Responsável</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={aparelhos?.tcs_responsavel}
                  />

                  <TitleInput>Emails</TitleInput>
                  {console.log(aparelhos?.email,"testeEmail")}
                  {aparelhos?.email?.map((email)=> {
                    return (
                      <Input disabled defaultValue={email[0].nome} />
                    )
                  })}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItens: "center",
                      gap: "10%",
                    }}
                  >
                    
                  </div>
                </CardInput>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10%",
                }}
              >
                

                <ButtonSave
                  style={{ borderColor: "red" }}
                  onClick={() => {
                    deletedAparelho(aparelhos.id)
                    setIsOpen(false)
                    window.location.reload()
                  }}
                >
                  <ButtonTitle style={{ color: "red", textAlign: "center" }}>
                    Apagar
                  </ButtonTitle>
                </ButtonSave>
                <ButtonSave
                onClick={() => {
                  putStatusAparelho(aparelhos.id)
                  window.location.reload()
                }}
                style={{ backgroundColor: "red" }}
                >
                  <ButtonTitle style={{ color: "white", textAlign: "center" }}>
                    Aprovar
                  </ButtonTitle>
                </ButtonSave>
              </div>
            </div>
          </ContainerInput>
        </ComponentEditAparelho>
      </Modal>

      <Modal
        isOpen={modalIsOpenLinha}
        onRequestClose={closeModalLinha}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ComponentEditLinha>
          <Title>Edição de Linhas</Title>
          <ContainerInput>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CardInput>
                  <TitleInput>Cliente</TitleInput>

                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={linhas?.cliente_id}
                  />

                  <TitleInput>Identificação da linha</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={linhas?.num_linha}
                  />
                </CardInput>

                <CardInput>
                  <TitleInput>Local da planta do cliente</TitleInput>

                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={linhas?.local_planta_cliente}
                  />
                </CardInput>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10%",
                }}
              >
             

                <ButtonSave
                  style={{ borderColor: "red" }}
                  onClick={() => {
                    deletedLinha(linhas.id)
                    setIsOpen(false)
                    window.location.reload()
                  }}
                >
                  <ButtonTitle style={{ color: "red", textAlign: "center" }}>
                    Apagar
                  </ButtonTitle>
                </ButtonSave>
                <ButtonSave 
                onClick={() => {
                  putStatusLinha(linhas.id)
                  window.location.reload()
                }}
                style={{ backgroundColor: "red" }}
                >
                  <ButtonTitle style={{ color: "white", textAlign: "center" }}>
                    Aprovar
                  </ButtonTitle>
                </ButtonSave>
              </div>
            </div>
          </ContainerInput>
        </ComponentEditLinha>
      </Modal>

      <Modal
        isOpen={modalIsOpenProduto}
        onRequestClose={closeModalProduto}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ComponentEditProduto>
          <Title>Edição de Produtos</Title>
          <ContainerInput>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CardInput>
                  <TitleInput>Cliente</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={produtos?.cliente_id}
                  />

                  <TitleInput>Nome do produto</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={produtos?.produto}
                  />

                  <TitleInput>Adesivo utilizado</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={produtos?.adesivo_id}
                  />
                </CardInput>

                <CardInput>
                  <TitleInput>Local da planta do cliente</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={produtos?.local_planta_cliente}
                  />

                  <TitleInput>Volume do Produto</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={produtos?.volume}
                  />

                  <TitleInput>Área Adesivo</TitleInput>
                  <Input
                  disabled
                    style={{ width: 320 }}
                    defaultValue={produtos?.area_adesivo}
                  />
                </CardInput>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10%",
                }}
              >
              

                <ButtonSave
                  style={{ borderColor: "red" }}
                  onClick={() => {
                    deletedProduto(produtos.id)
                    setIsOpen(false)
                    window.location.reload()
                  }}
                >
                  <ButtonTitle style={{ color: "red", textAlign: "center" }}>
                    Apagar
                  </ButtonTitle>
                </ButtonSave>
                <ButtonSave 
                onClick={() => {
                  putStatusProduto(produtos.id)
                  window.location.reload()
                }}
                style={{ backgroundColor: "red" }}
                >
                  <ButtonTitle style={{ color: "white", textAlign: "center" }}>
                    Aprovar
                  </ButtonTitle>
                </ButtonSave>
              </div>
            </div>
          </ContainerInput>
        </ComponentEditProduto>
      </Modal>
      <Modal
        isOpen={modalIsOpenProblema}
        onRequestClose={closeModalProblema}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <Title>Edição de Guias</Title>
        <ContainerInput>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <CardInput>
                <TitleInput>Título</TitleInput>
                <Input
                disabled
                  style={{ width: 320 }}
                  defaultValue={problemas?.titulo}
                />

                <TitleInput>Descrição</TitleInput>
                <Input
                disabled
                  style={{ width: 320 }}
                  defaultValue={problemas.descricao_problema}
                />

                <TitleInput>Tópico</TitleInput>

                <Input disabled defaultValue={problemas.id_categoria} />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "17%", 
                  }}
                >
                  <TitleInput>Causas e Soluções:</TitleInput>
                  <div>
                    {console.log("vendo as causas", problemas?.causa)}
                    {problemas.causa?.map((causa) => {
                      return (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>{causa.descricao_causa}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                          {causa.acoes.map((acao)=> <Typography>*{acao.descricao_acao}</Typography>)}
                        </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </div>
                </div>
              </CardInput>

              <CardInput>
                <TitleInput>Imagens</TitleInput>
                <Input
                disabled
                  style={{ padding: "10px", marginLeft: "5%" }}
                  defaultValue={problemas.img_file}
                />

                <TitleInput style={{ marginTop: "8%" }}>
                  Formulário Aceito
                </TitleInput>

                <Input disabled defaultValue={problemas.form_problemas} />

                <TitleInput style={{ marginTop: "8%" }}>
                  Palavra chave
                </TitleInput>
                {problemas.palavra?.map((palavra)=>{
                  return (
                  <Input 
                  disabled
                  defaultValue={palavra.descricao_chave} />
                  )
                })}
                 
              </CardInput>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10%",
                marginTop: "2%"
              }}
            >
         

                <ButtonSave
                  style={{ borderColor: "red" }}
                  onClick={() => {
                    deletedProblema(problemas.id)
                    setIsOpen(false)
                    window.location.reload()
                  }}
                >
                  <ButtonTitle style={{ color: "red", textAlign: "center" }}>
                    Apagar
                  </ButtonTitle>
                </ButtonSave>
                <ButtonSave 
                onClick={() => {
                  putStatusProblema(problemas.id)
                  window.location.reload()
                }}
                style={{ backgroundColor: "red" }}
                >
                  <ButtonTitle style={{ color: "white", textAlign: "center" }}>
                    Aprovar
                  </ButtonTitle>
                </ButtonSave>
            </div>
          </div>
        </ContainerInput>
      </Modal>
    </Container>
  );
}
export default ListMudanca;
