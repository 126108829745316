import styled from "styled-components";

export const InputText = styled.input`
  display: block;
  font-size: 1rem;
  padding: 0.8rem;
  width: 440px;
  margin-left: 20px;
  text-align: left;
  margin-bottom: 30px;
  /* grayscale / gray lightest */
  border: 1px solid #f0f1f7;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
`
export const Label = styled.label`
  display: block;
  text-align: left;
  line-height: 1;
  padding-bottom: 0.2rem;
  margin-left: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
`
