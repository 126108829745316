import React, { useEffect, useState, useContext } from "react";
import { IoAddCircle } from "react-icons/io5";
import {
    Container,
    LatealMenu,
    Logo,
    Title,
    InfoContainers,
    InfoContainer,
    LabelButton,
    ComponentTable,
    ContainerCadastro,
    ContainerInput,
    TitleInput,
    Input,
    CardInput,
    Button,
    LabelButton2,
    Select,
    ButtonIcon,
  } from "./styles";

import Modal from "react-modal";
import { FaTrashAlt } from "react-icons/fa";
  


function ModalCausa (props){


const {
    isEditCausa,
    closeModal,
    handleEdit: handleEditCausasSolucoes,
    isOpenCausa,
    contentLabel, 

    currentCausaIdx,
    currentCausa,
    
    currentProblema,
    onChangeProblema,
    handleAdd: handleAddCausasSolucoes,
    
} = props;

//Causa e soluções da modal, elas são adicionadas quando clica em cadastrar
const [causa, setCausa] = useState('');
const [solucoes, setSolucoes] = useState([{id: 'nova solucao', descricao_acao: ''}]);

let solucoesVar = [];
const [loading, setLoading] = useState(false);

  //Adiciona novo campo de solucao
  const handleAddSolucoes = () => {
    setLoading(false)
    let temp = solucoes;
    temp.push({id: 'nova solucao', descricao_acao: ''});
    setSolucoes(temp);
  };

  const handleRemoveSolucao = (solucao, idx)=> {
   if(solucoes.length > 1){
    setLoading(false);
    let newSolucoes = solucoes.filter((solucao, index)=> index !== idx );

    setSolucoes(newSolucoes);
   }
  }
 
  if(currentCausa && solucoes === []){
    setSolucoes(currentCausa.solucoes)
  }


  //Adiciona nova causa no currentProblema
  const handleAddCausaSolucoes = ()=>{
    console.log(solucoes, 'vendo soluções')
    let novaCausa = {
        descricao_causa: causa,
        solucoes,
    }

    handleAddCausasSolucoes(novaCausa);
  }

  const handleEditCausaSolucoes = ()=> {
    console.log(causa, solucoes, 'funcao de edição')
    onChangeProblema(causa, solucoes, currentCausaIdx, currentProblema)
    setCausa('')
    setSolucoes([''])
  }


  const closeModalCausa = ()=>{
    // console.log(currentProblema,causa, solucoes, 'sssssss funcao para fechar')
      closeModal()
  }


  useEffect(()=>{
    if(isEditCausa && isOpenCausa === true){
     console.log(currentProblema.causas[currentCausaIdx].solucoes, 'sssssss');
     solucoesVar = currentProblema.causas[currentCausaIdx].solucoes;
     setCausa(currentProblema.causas[currentCausaIdx].descricao_causa);
     
     setSolucoes(currentProblema.causas[currentCausaIdx].solucoes.map((solucao) => {return {id: solucao.id, descricao_acao: solucao.descricao_acao}}));

    }
   },[isOpenCausa]); 

  if(!loading){
      setLoading(true);
  }

  console.log(loading, isOpenCausa)

  return ( loading &&
    <Modal
    isOpen={isOpenCausa}
    onRequestClose={()=>{
        setCausa('')
      let temp = ['']
        setSolucoes(temp)
        closeModal()
    }}
    contentLabel={contentLabel}
  >
    <Title>Cadastro de Causas</Title>
    <div>
     {loading && (()=>{
      return(
        <>
           <ContainerInput styles={{ border: "1px solid #000" }}>
           <CardInput>
             <TitleInput>Causa</TitleInput>
             <Input
             defaultValue={causa}
               style={{ width: 320 }}
               onChange={(e) => {
               
                setCausa(e.target.value)}}
             />
           </CardInput>
          <div style={{
            display: "flex",
            flexDirection: "column"
          }}>
            {console.log(solucoes, 'dentro do componente')}
          {loading && solucoes?.map((solucao, index)=>{
          
            return (
           <CardInput key={index}>
             <TitleInput>Solução</TitleInput>
             <div
               style={{
                 display: "flex",
                 justifyContent: "space-between",
                 alignItens: "center",
               }}
             >
               <Input
               defaultValue={solucao.descricao_acao}
                 style={{ width: 320, height: 45 }}
                onChange={(e) =>{
                  let temp = solucoes;
                  console.log(solucoesVar, 'oooooooooooooooo')
                  temp[index].descricao_acao = e.target.value;
                  setSolucoes(temp)

                }}
               />
               <ButtonIcon
               onClick={()=>handleAddSolucoes()}
                 style={{
                   width: 30,
                   borderRadius: "50%",
                   display: "flex",
                   justifyContent: "center",
                   alignItems: "center",
                   height: 30,
                   borderCollor: "white",
                 }}
               >
                 <IoAddCircle size={30} color="red" />
               </ButtonIcon>

               <FaTrashAlt 

                onClick={()=>{
                     handleRemoveSolucao(solucao,index)
                }}
                style={{  padding: '10px',margin: '0'}}
                color="#E1000F"  
                />
             </div>
           </CardInput>
        )})}
          </div>

         </ContainerInput>
        </>
      )
     })()}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10%",
        }}
      >
        <Button
          onClick={()=>{
            if(isEditCausa){
              handleEditCausaSolucoes()
            }else{
              handleAddCausaSolucoes()
            }
                
            setCausa('')
            let temp = ['']
            setSolucoes(temp)
            closeModal()
          }}
          style={{ backgroundColor: "red"}}
        >
          <LabelButton2 style={{ color: "white", textAlign: "center"}}>
            Salvar
          </LabelButton2>
        </Button>

        <Button
          style={{ borderColor: "red" }}
          onClick={()=>{
            setCausa('')
            let temp = ['']
            setSolucoes(temp)
            closeModalCausa()


        }}
        >
          <LabelButton2 style={{ color: "red", textAlign: "center" }}>
            Cancelar
          </LabelButton2>
        </Button>
      </div>
    </div>
  </Modal> )
};

export default ModalCausa;
