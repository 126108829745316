import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { ReactSVG } from "react-svg";






import {
  Container,
  LatealMenu,
  Logo,
  Title,
  InfoContainers,
  InfoContainer,
  LabelButton,
  ComponentTable,
  ContainerCadastro,
  ContainerInput,
  TitleInput,
  Input,
  CardInput,
  Button,
  LabelButton2,
  Select,
} from "./styles";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import { ToastNotifier } from "../../../helpers/ToastNotifier";
import { useAuth } from "../../../hooks/useAuth";

function CadastroAdesivo() {
  let history = useHistory();
  const {user, setUser} = useAuth();
  const [adesivos, setAdesivos] = useState([]);
  const [cliente_nome, setClienteNome] = useState();
  
  const [aparelho_indef, setIdAparelho] = useState();
  const [planta_id, setPlantaId] = useState();
  const [clientes, setClientes] = useState([]);
  const [aparelhos, setAparelhos] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [cliente_id, setClienteId] = useState();
  const [idh_sku, setIdhSku] = useState();
  const [admin_id, setAdminId] = useState(user?.id);
  const [cliente, setCliente] = useState();
  const [planta, setPlanta] = useState();
  const [aparelho, setAparelho] = useState();
  const [local_planta_cliente, setLocalPlantaCliente] = useState();
  const [nome_adesivo, setNomeAdesivo] = useState();
  const [status_mudanca, setStatusMudanca] = useState(user?.nivel_acesso !== '999' ? "Pendente" : "Aprovado");
  const [isTrue, setIsTrue] = useState(true);
  

  async function postAdesivos() {
    try {

      const data = {
        idh_sku,
        cliente_nome,
        admin_id,
        planta_id,
        local_planta_cliente,
        aparelho_indef,
        cliente_id,
        aparelho,
        nome_adesivo,  
        status_mudanca,
      };

      const response = await api.post("/adesivo", {
        data,
      });
      
      if (response.status === 201) {
        ToastNotifier({
          toastMessage: "Adesivo cadastrado com sucesso!",
          toastType: "success",
        });
        history.push("/list_adesivo");
      } else if (response.status === 403) {
        ToastNotifier({
          toastMessage: "Adesivo já cadastrado.",
          toastType: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleLogout = () => {
    try{
      setUser(null);
      Cookies.remove("authToken");
      Cookies.remove("authUser");
      history.push("/login");
      window.location.reload();
    }catch(error){
      console.log(error);
      console.log("Não foi possível deslogar");
    }
  }



  async function getPlanta() {
    try {
      const response = await api.post("/get_cliente_planta", {
        id_cliente: cliente_id,
      });
      console.log(response.data.local_planta[0]);
      setPlantas(response.data.local_planta[0].nome_planta);
      setIsTrue(true);
    } catch (error) {
      setIsTrue(false);
      console.log(error);
    }
  }

  async function getPlantaId() {
    try {
      const response = await api.post("/getPlantaId", {
        id: planta_id,
      });
      setLocalPlantaCliente(response.data.planta[0].nome)
      setIsTrue(true);
    } catch (error) {
      setIsTrue(false);
      console.log(error);
    }
  }

  async function getAparelhos() {
    const response = await api.get("/aparelho");
    setAparelhos(response.data.aparelhos);
    setIdAparelho(response.data.aparelhos?.[0].id);
  }

  async function getClientes() {
    const response = await api.get("/cliente");
    setClientes(response.data.clientes);
    setClienteId(response.data.clientes?.[0].id);
  }
  useEffect(() => {
    if (cliente_id) {
      getPlanta();
    }
  }, [cliente_id]);
  useEffect(() => {
    getClientes();
  }, []);

  useEffect(() => {
    getAparelhos();
  }, []);

  useEffect(() => {
    getPlantaId()
  }, [planta_id]);
 

  return (
    <Container>
      <LatealMenu>
        <Logo />
        <InfoContainers>
        <InfoContainer>
          {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_relatorio");
              }}
            >
              <ReactSVG src="/images/Relatorio.svg" />
              Relatórios
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_mudanca");
              }}
            >
              <ReactSVG src="/images/SolicitacaoMudanca.svg" />
              Solicitações de Mudança
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_forms_respondidos");
              }}
            >
              <ReactSVG src="/images/FormRespondido.svg" />
              Formulários Respondidos
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton 
            style={{gap: "4.5%"}}
              onClick={() => {
                history.push("/list_adm_intermediario");
              }}
            >
              <ReactSVG src="/images/AdmInter.svg" />
              Cadastro de ADM Intermediário
            </LabelButton>): (<></>)}

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_cliente");
              }}
            >
              <ReactSVG src="/images/CadastroCliente.svg" />
              Cadastro de Clientes
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_aparelho");
              }}
            >
              <ReactSVG src="/images/CadastroAparelho.svg" />
              Cadastro de Aparelhos
            </LabelButton>

            <LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_guia");
              }}
            >
              <ReactSVG src="/images/CadastroGuia.svg" />
              Cadastro de Guias
            </LabelButton>

            <LabelButton
              style={{ gap: "5%", fontWeight: "bold" }}
              onClick={() => {
                history.push("/list_adesivo");
              }}
            >
              <ReactSVG src="/images/CadastroAdesivo.svg" />
              Cadastro de Adesivos
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_produto");
              }}
            >
              <ReactSVG src="/images/CadastroProduto.svg" />
              Cadastro de Produtos
            </LabelButton>
            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_supervisor");
              }}
            >
              <ReactSVG src="/images/CadastroSupervisor.svg" />
              Cadastro de Supervisores
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_linha");
              }}
            >
              <ReactSVG src="/images/CadastroLinha.svg" />
              Cadastro de Linha
            </LabelButton>

            <LabelButton onClick={()=> {
                handleLogout()
              }}>
                <ReactSVG src="/images/logOut.svg" />
              Log Out
            </LabelButton>
            
          </InfoContainer>
        </InfoContainers>
      </LatealMenu>
      <ContainerCadastro>
        <ComponentTable>
          <Title>Cadastro de Adesivos</Title>
          <ContainerInput>
          <div style={{display: "flex", flexDirection: "column"}}>
              <div style={{display: "flex", flexDirection: "row"}}>
            <CardInput>
            <TitleInput>Cliente</TitleInput>
              <form>
                <Select onChange={(v) => setClienteId(v.target.value)}>
                <option defaultValue={""}>Selecione um cliente</option>
                    {clientes?.map((item) => (
                    <option value={item.id}>{item.nome}</option>
                  ))}
                </Select>
              </form>

              <TitleInput>IDH do adesivo</TitleInput>
              <Input
                style={{ width: 320 }}
                onChange={(e) => setIdhSku(e.target.value)}
              />
              
              <TitleInput>Aparelho</TitleInput>
              
                <form>
                <Select onChange={(v) => setIdAparelho(v.target.value)}>
                  <option defaultValue={""}>Selecione um aparelho</option>
                  {aparelhos?.map((item) => (
                    <option value={item.id}>{item.id_aparelho}</option>
                  ))}
                
                </Select>
                </form>
             
              
              
            </CardInput>

            
            <CardInput>
            <TitleInput>Local da Planta do Cliente</TitleInput>
              <form>
                  <Select onChange={(v) => setPlantaId(v.target.value)}>
                    <option defaultValue={""}>Selecione uma planta</option>
                    {plantas?.map((item) => (
                      <>
                        <option value={item.id}>{item.nome}</option>
                      </>
                    ))}
                  </Select>
              </form>

              <TitleInput>Nome do Adesivo</TitleInput>
              <Input
                style={{ width: 320 }}
                onChange={(e) => setNomeAdesivo(e.target.value)}
              />

              
              
              
              
            </CardInput>
            </div>

            <div
              style={{display: "flex",
              gap: "10%",
              justifyContent: "center",
              }}
              >
                  <Button
                style={{ borderColor: "red" }}
                onClick={() => history.push("/list_adesivo")}>
                <LabelButton2 style={{ color: "red", textAlign: "center" }}>
                  Cancelar
                </LabelButton2>
              </Button>
              <Button
                onClick={() => postAdesivos()}
                style={{ backgroundColor: "red" }}
              >
                <LabelButton2 style={{ color: "white" }}>
                  Cadastrar
                </LabelButton2>
              </Button>
              
            
              </div>

            </div>
          </ContainerInput>
        </ComponentTable>
      </ContainerCadastro>
    </Container>
  );
}
export default CadastroAdesivo;
