import styled from "styled-components";
import Logoimg from "../../../assets/logo.png";

export const Container = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  background-color: #eff1f9;
  width: 100%;
`;
export const Logo = styled.div`
  background-image: url(${Logoimg});
  background-size: cover;
  background-repeat: no-repeat;
  height: 55.25px;
  width: 90px;
  border-radius: 0px;
  padding: 50px;
  margin-bottom: 55px;
  padding-left: 80px;
  align-self: center;
`;
export const Title = styled.h1`
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.3px;
  margin-bottom: 40px;
  text-align: center;
  margin-top: 50px;
`;
export const LatealMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 244px;
  box-shadow: 2px 2px #d5d6de;
  background-color: white;
`;
export const CardInfos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const CardInfo = styled.div`
  width: 247.04px;
  height: 86px;
  background-color: #e1000f;
  align-items: flex-start;
  padding-left: 30px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const CardlabelTitle = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11.9973px;
  line-height: 16px;
  color: white;
  margin-bottom: 5px;
`;
export const Cardlabel = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  color: white;
`;
export const ComponentTable = styled.div`
  background-color: white;
  width: 940px;
  align-self: center;
`;
export const ContainerInput = styled.div`
  align-self: center;
  flex-direction: row;
  justify-content: space-around;
  display: flex;
`;
export const CardInput = styled.div`
  width: 400px;
`;
export const ContainerCadastro = styled.div`
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  align-self: center;
  display: flex;
`;
export const TitleInput = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  text-align: flex-start;
  color: black;
  font-size: 18px;
  margin-left: 25px;
  margin-bottom: 10px;
`;
export const Input = styled.input`
  display: block;
  font-size: 1rem;
  padding: 0.8rem;
  width: 80%;
  margin-left: 20px;
  text-align: left;
  margin-bottom: 30px;
  /* grayscale / gray lightest */
  border: 1px solid #f0f1f7;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  background-color: #f9f9f9;
`;
export const InfoContainers = styled.div`
  flex-direction: column;
`;
export const InfoContainer = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 25px;
  align-items: center;
`;
export const LabelButton = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction:row ;
  margin-bottom: 25px;
  align-items: center;
  color: #e1000f;
  cursor:pointer;

`;
export const LabelButton2 = styled.div`
  margin-bottom: 25px;
  justify-content: center;
  margin-top:14px;
`;
export const Select = styled.select`
display: block;
font-size: 1rem;
padding: 0.8rem;
width: 80%;
margin-left: 20px;
text-align: left;
margin-bottom: 30px;
/* grayscale / gray lightest */
border: 1px solid #f0f1f7;
box-sizing: border-box;
border-radius: 8px;
font-family: Arial, Helvetica, sans-serif;
font-style: normal;
font-weight: 700;
font-size: 14px;
background-color: #f9f9f9;
`

export const Button = styled.div`
  margin-top: 40px;
  cursor: pointer;
  width: 150px;
  height: 50px;
  background-color: #eff0f9;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  text-align:center;
  border-color:red;
  border-width: 1px;
  margin-bottom: 40px;
  border-style: solid;
`;
export const ButtonTitle = styled.h3`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12.9973px;
  color: black;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
`;
