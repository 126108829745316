import React, { useEffect, useState, useContext } from "react";
import { IoAddCircle } from "react-icons/io5";
import {
    Container,
    LatealMenu,
    Logo,
    Title,
    InfoContainers,
    InfoContainer,
    LabelButton,
    ComponentTable,
    ContainerCadastro,
    ContainerInput,
    TitleInput,
    Input,
    CardInput,
    Button,
    LabelButton2,
    Select,
    ButtonIcon,
  } from "./styles";

  import Modal from "react-modal";
import { FaTrashAlt } from "react-icons/fa";
  


function ModalCausa (props){

// console.log({props})
const {
    isEditCausa,
    currentCausaIdx,
    closeModal,
    handleEdit: handleEditCausasSolucoes,
    isOpen,
    contentLabel, 
    handleAdd: handleAddCausasSolucoes,
    array ,
} = props;

//Causa e soluções da modal, elas são adicionadas quando clica em cadastrar
const [causa, setCausa] = useState('');
const [solucoes, setSolucoes] = useState(['']);
const [loading, setLoading] = useState(false);


const handleAddSolucoes = () => {
    setLoading(false)
    let temp = solucoes;
    temp.push('');
    setSolucoes(temp);
  };



  

  const handleRemoveSolucao = (solucao, idx)=> {
   if(solucoes.length > 1){
    setLoading(false);

    let newSolucoes = solucoes.filter((solucao, index)=> index !== idx );

    setSolucoes(newSolucoes);
   }
  }
 
  console.log(solucoes,   'fora')

  useEffect(()=>{
    setLoading(false);
   
  },[solucoes]);






useEffect(()=>{
    setLoading(false);
   if(isEditCausa){
    
    setCausa(array[currentCausaIdx].nomeCausa)
    setSolucoes(array[currentCausaIdx].solucoes)
   }
  },[isOpen]);

  if(!loading){
    setLoading(true);
}

const handleAddCausaSolucoes = ()=>{
    let causaSolucoes = {
        nomeCausa: causa,
        solucoes,
    }

    console.log(causaSolucoes, currentCausaIdx, 'eeeeeeee')
    handleAddCausasSolucoes(causaSolucoes);
    setCausa('')
    setSolucoes([''])
  }

  const handleEditCausaSolucoes = ()=>{
    let causaSolucoes = {
        nomeCausa: causa,
        solucoes,
    }

    console.log(causaSolucoes, currentCausaIdx, 'eeeeeeee')
    handleEditCausasSolucoes(causaSolucoes, currentCausaIdx);
    setCausa('')
    setSolucoes([''])
  }


  return (
    <Modal
    isOpen={isOpen}
    onRequestClose={()=>{
        setCausa('')
        setSolucoes([''])
        closeModal()
    }}
    contentLabel={contentLabel}
  >
    <Title>Cadastro de Causas</Title>
    <div>
     {loading && (()=>{
      return(
        <>
        
           <ContainerInput styles={{ border: "1px solid #000" }}>
           <CardInput>
             <TitleInput>Causa</TitleInput>
             <Input
             defaultValue={causa}
               style={{ width: 320 }}
               onChange={(e) => setCausa(e.target.value)}
             />
           </CardInput>
          <div style={{
            display: "flex",
            flexDirection: "column"
          }}>
            {console.log(solucoes, 'dentro do componente')}
          {loading && solucoes?.map((solucao, index)=>{
            let temp = solucao;
            return (
           <CardInput key={index}>
             <TitleInput>Solução</TitleInput>
             <div
               style={{
                 display: "flex",
                 justifyContent: "space-between",
                 alignItens: "center",
               }}
             >
               <Input
               value={temp}
                 style={{ width: 320, height: 45 }}
                onChange={(e) => {
                    setLoading(false)
                    temp = e.target.value
                    solucoes[index] = e.target.value;
                }}
               />
               <ButtonIcon
               onClick={()=>handleAddSolucoes()}
                 style={{
                   width: 30,
                   borderRadius: "50%",
                   display: "flex",
                   justifyContent: "center",
                   alignItems: "center",
                   height: 30,
                   borderCollor: "white",
                 }}
               >
                 <IoAddCircle size={30} color="red" />
               </ButtonIcon>

               <FaTrashAlt 

                onClick={()=>{
                     handleRemoveSolucao(solucao,index)
                }}
                style={{  padding: '10px',margin: '0'}}
                color="#E1000F"  
                />
             </div>
           </CardInput>
        )})}
          </div>

         </ContainerInput>
        </>
      )
     })()}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItens: "center",
        }}
      >
        <Button
          onClick={()=>{
            if (isEditCausa){
              handleEditCausaSolucoes()
            }else{
            handleAddCausaSolucoes()
            }
            closeModal()
          }}
          style={{ backgroundColor: "red", marginLeft: "190px" }}
        >
          <LabelButton2 style={{ color: "white" }}>
            Salvar
          </LabelButton2>
        </Button>

        <Button
          style={{ borderColor: "red" }}
          onClick={()=>{
            setCausa('')
            setSolucoes([''])
            closeModal()
        }}
        >
          <LabelButton2 style={{ color: "red", textAlign: "center" }}>
            Cancelar
          </LabelButton2>
        </Button>
      </div>
    </div>
  </Modal> )
};

export default ModalCausa;
