import styled from "styled-components";

export const ButtonText = styled.div`
    font-size: 1rem;
    cursor: pointer;
    margin-top: 30px;
    border: none;
    margin-left: 20px;
    border-radius: 0.4rem;
    background: #E1000F;
    color: white;
    min-width: 8rem;
    padding: 0.8rem 1.2rem;
    box-sizing: border-box;
    transition: 0.1s;
    width: 70%;
  /*#E01B48*/
  button:hover, button:focus{
    outline: none;
    box-shadow: 0 0 0 3px #E01B48, 0 0 0 4px #E01B48;
  }
  
  button:disabled{
    opacity: 0.5;
    cursor: wait;
  }
  `
