import React, { useEffect, useState, useContext } from "react";
import { IoAddCircle } from "react-icons/io5";
import { IoRemoveCircle } from "react-icons/io5";
import { ReactSVG } from "react-svg";
import Cookies from "js-cookie";

import {
  Container,
  LatealMenu,
  Logo,
  Title,
  InfoContainers,
  CardInputOut,
  InfoContainer,
  LabelButton,
  ButtonIcon,
  ComponentTable,
  ContainerCadastro,
  ContainerInput,
  TitleInput,
  Input,
  CardInput,
  Button,
  LabelButton2,
  Select,
} from "./styles";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import { ToastNotifier } from "../../../helpers/ToastNotifier";
import { useAuth } from "../../../hooks/useAuth";

function CadastroAparelho() {
  let history = useHistory();
  const {user, setUser} = useAuth();
  const [clientes, setClientes] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [emails_array, setEmailsArray] = useState([{ email: "" }]);
  const [cliente_id, setClienteId] = useState();
  const [vendedor, setVendedor] = useState();
  const [senha, setSenha] = useState();
  const [email_id, setEmailId] = useState();
  const [id_aparelho, setIdAparelho] = useState();
  const [local_planta_cliente, setLocalPlanta] = useState();
  const [planta_id, setPlantaId] = useState();
  const [formularios, setFormularios] = useState();
  const [admin_id, setAdminId] = useState(user?.id);
  const [aparelho_emails, setAparelhoEmails] = useState();
  const [tcs_responsavel, setTcsResponsavel] = useState();
  const [status_mudanca, setStatusMudanca] = useState(user?.nivel_acesso !== '999' ? "Pendente" : "Aprovado");
  const [isTrue, setIsTrue] = useState(true);

  async function postAparelhos() {
    try {
      const emailsAux = [];
      for (let i = 0; i < emails_array.length; i++) {
        emailsAux.push(emails_array[i].email);
      }
      const separatedEmails = emailsAux.join(", ");
      console.log({separatedEmails});

      const data = {
        cliente_id,
        vendedor,
        admin_id,
        senha,
        local_planta_cliente,
        planta_id,
        formularios,
        email_id: separatedEmails,
        id_aparelho,
        tcs_responsavel,
        status_mudanca,
      };

      const response = await api.post("/aparelho", {
        data,
      });

      if (response.status === 201) {
        ToastNotifier({
          toastMessage: "Aparelho cadastrado com sucesso!",
          toastType: "success",
        });
        history.push("/list_aparelho");
      } else if (response.status === 403) {
        ToastNotifier({
          toastMessage: "Aparelho já cadastrado.",
          toastType: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  

  const handleLogout = () => {
    try{
      setUser(null);
      Cookies.remove("authToken");
      Cookies.remove("authUser");
      history.push("/login");
      window.location.reload();
    }catch(error){
      console.log(error);
      console.log("Não foi possível deslogar");
    }
  }


  const handleChangeInput = (index, event) => {
    const values = [...emails_array];

    values[index].email = event.target.value;

    setEmailsArray(values);
  };

  
  const handleRemoveEmail = (index) => {
    if (emails_array.length > 1) {
      let newEmails = [...emails_array];

      newEmails.splice(index, 1);

      setEmailsArray(newEmails);
    }
  };



  async function getPlanta() {
    try {
      const response = await api.post("/get_cliente_planta", {
        id_cliente: cliente_id,
      });
      console.log(response.data.local_planta[0]);
      setPlantas(response.data.local_planta[0].nome_planta);
      setIsTrue(true);
    } catch (error) {
      setIsTrue(false);
      console.log(error);
    }
  }

  async function getPlantaId() {
    try {
      const response = await api.post("/getPlantaId", {
        id: planta_id,
      });
      setLocalPlanta(response.data.planta[0].nome)
      setIsTrue(true);
    } catch (error) {
      setIsTrue(false);
      console.log(error);
    }
  }

  async function getClientes() {
    const response = await api.get("/cliente");
    setClientes(response.data.clientes);
    setClienteId(response.data.clientes?.[0].id);
  }
  useEffect(() => {
    if (cliente_id) {
      getPlanta();
    }
  }, [cliente_id]);
  useEffect(() => {
    getPlantaId();
  }, [planta_id]);

  useEffect(() => {
    getClientes();
  }, []);

  return (
    <Container>
      <LatealMenu>
        <Logo />
        <InfoContainers>
        <InfoContainer>
          {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_relatorio");
              }}
            >
              <ReactSVG src="/images/Relatorio.svg" />
              Relatórios
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_mudanca");
              }}
            >
              <ReactSVG src="/images/SolicitacaoMudanca.svg" />
              Solicitações de Mudança
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_forms_respondidos");
              }}
            >
              <ReactSVG src="/images/FormRespondido.svg" />
              Formulários Respondidos
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton 
            style={{gap: "4.5%"}}
              onClick={() => {
                history.push("/list_adm_intermediario");
              }}
            >
              <ReactSVG src="/images/AdmInter.svg" />
              Cadastro de ADM Intermediário
            </LabelButton>): (<></>)}

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_cliente");
              }}
            >
              <ReactSVG src="/images/CadastroCliente.svg" />
              Cadastro de Clientes
            </LabelButton>

            <LabelButton
              style={{ gap: "5%", fontWeight: "bold" }}
              onClick={() => {
                history.push("/list_aparelho");
              }}
            >
              <ReactSVG src="/images/CadastroAparelho.svg" />
              Cadastro de Aparelhos
            </LabelButton>

            <LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_guia");
              }}
            >
              <ReactSVG src="/images/CadastroGuia.svg" />
              Cadastro de Guias
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_adesivo");
              }}
            >
              <ReactSVG src="/images/CadastroAdesivo.svg" />
              Cadastro de Adesivos
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_produto");
              }}
            >
              <ReactSVG src="/images/CadastroProduto.svg" />
              Cadastro de Produtos
            </LabelButton>
            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_supervisor");
              }}
            >
              <ReactSVG src="/images/CadastroSupervisor.svg" />
              Cadastro de Supervisores
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_linha");
              }}
            >
              <ReactSVG src="/images/CadastroLinha.svg" />
              Cadastro de Linha
            </LabelButton>
            
            <LabelButton onClick={()=> {
                handleLogout()
              }}>
                <ReactSVG src="/images/logOut.svg" />
              Log Out
            </LabelButton>

          </InfoContainer>
        </InfoContainers>
      </LatealMenu>
      <ContainerCadastro>
        <ComponentTable>
          <Title>Cadastro de Aparelhos</Title>
          <ContainerInput>
            <div style={{display: "flex", flexDirection: "column"}}>
              <div style={{display: "flex", flexDirection: "row"}}>
            <CardInput>
              <TitleInput>Cliente</TitleInput>
              <form>
                <Select onChange={(v) => setClienteId(v.target.value)}>
                <option defaultValue={""}>Selecione um cliente</option>
                  {clientes?.map((item) => (
                    <option value={item.id}>{item.nome}</option>
                  ))}
                </Select>
              </form>
              <TitleInput>Vendedor</TitleInput>
              <Input
                style={{ width: 320 }}
                value={vendedor}
                onChange={(e) => setVendedor(e.target.value)}
              />
              <TitleInput>Senha</TitleInput>
              <Input
                style={{ width: 320 }}
                onChange={(e) => setSenha(e.target.value)}
              />
              <TitleInput>Guias de soluções</TitleInput>
              <form>
                <Select onChange={(v) => setFormularios(v.target.value)}>
                <option value={"Labeling"}>Labeling</option>
                  <option value={"EOL"}>EOL</option>
                  <option value={"Higiênicos"}>Higiênicos</option>
                  <option value={"Labeling/EOL"}>Labeling/EOL</option>
                  <option value={"Higiênicos/EOL"}>Higiênicos/EOL</option>
                  
                </Select>
              </form>
              
            </CardInput>

            <CardInput>
              <TitleInput>Local da Planta do Cliente</TitleInput>
              <form>
                {isTrue ? (
                  <Select onChange={(v) => setPlantaId(v.target.value)}>
                    <option defaultValue={""}>Selecione uma planta</option>
                    {plantas?.map((item) => (
                      <>
                        <option value={item.id}>{item.nome}</option>
                      </>
                    ))}
                  </Select>
                ) : (
                  <Input
                    style={{ width: 320 }}
                    readOnly
                    value={"Este cliente não possui plantas"}
                  />
                )}
              </form>
              <TitleInput>ID aparelho</TitleInput>
              <Input
                style={{ width: 320 }}
                value={id_aparelho}
                onChange={(e) => setIdAparelho(e.target.value)}
              />
              <TitleInput>TCS Responsável</TitleInput>
              <Input
                style={{ width: 320 }}
                onChange={(e) => setTcsResponsavel(e.target.value)}
              />

<TitleInput>Emails</TitleInput>
              {emails_array?.map((item, index) => (
                
                  <div  key={index}>
                  <TitleInput></TitleInput>
                  <div
                  style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItens: "center",
                  gap: "10%",
                }}
              >
                    <Input
                      required
                      placeholder='E-mail'
                      style={{ width: 320, height: 45 }}
                      value= {item.email}
                      onChange={(e) => handleChangeInput(index, e)}
                    />
                    <ButtonIcon
                    style={{
                      width: 30,
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 30,
                      borderCollor: "white",
                    }}
                    onClick={() =>{ 
                      handleRemoveEmail(index);
                    }}
                  >
                    <IoRemoveCircle size={30} color="red" />
                  </ButtonIcon>
                  </div>
                  </div>               
              ))}

                <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",                 
                  alignItens: "center",
                  gap: "10%",
                }}
              >
                <ButtonIcon
                  style={{
                    width: 30,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 30,
                    borderCollor: "white",
                  }}
                  disabled={emails_array.some((email) => email.email === "")}
                  onClick = {() => 
                    
                    setEmailsArray([...emails_array, { email: "" }])
                  }
                >
                  <IoAddCircle size={30} color="red" />
                </ButtonIcon>
                </div>
            </CardInput>
            </div>
            <div
                style={{
                  display: "flex",
                  gap: "10%",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{ borderColor: "red" }}
                  onClick={() => history.push("/list_aparelho")}
                >
                  <LabelButton2 style={{ color: "red", textAlign: "center" }}>
                    Cancelar
                  </LabelButton2>
                </Button>
                <Button
                disabled={emails_array.some((email) => email.email === "")}
                  onClick={() => postAparelhos()}
                  style={{ backgroundColor: "red" }}
                >
                  <LabelButton2 style={{ color: "white" }}>
                    Cadastrar
                  </LabelButton2>
                </Button>

                
              </div>
              </div>
          </ContainerInput>
        </ComponentTable>
      </ContainerCadastro>
    </Container>
  );
}
export default CadastroAparelho;
