import React, { useEffect, useState, useContext } from "react";
import { IoAddCircle } from "react-icons/io5";
import { IoRemoveCircle } from "react-icons/io5";
import { ReactSVG } from "react-svg";
import Cookies from "js-cookie";



import {
  Container,
  LatealMenu,
  Logo,
  Title,
  InfoContainers,
  InfoContainer,
  ButtonSave,
  LabelButton,
  ComponentTable,
  ComponentEdit,
  CardInfos,
  CardInfo,
  Cardlabel,
  CardlabelTitle,
  TitleContainer,
  Button,
  ButtonTitle,
  ContainerInput,
  CardInput,
  TitleInput,
  ButtonIcon,
  Input,
} from "./styles";
import MaterialTable from "material-table";
import { FaTrashAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import Modal from 'react-modal';
import { useAuth } from "../../../hooks/useAuth";


function ListCliente() {
  const customStyles = {
    content: {
     width: "70%",
     position: "absolute",
     alignItems:'center',
     justifyContent:'center',
     alignSelf:'center',
     height: '80%',
     zIndex: 999,
     top: '50%',
     left: '50%',
    right: 'auto',
    bottom: 'auto',
     transform: 'translate(-50%, -50%)',
     overflow: "scroll",
     
    },
    
  };

  let history = useHistory();
  const {user, setUser} = useAuth();
  const [clientes, setClientes] = useState([]);
  const [nome, setNome] = useState();
  const [plantas_array, setPlantasArray] = useState([{ planta: "" }]);
  const [id_henkel, setIdHenkel] = useState();
  const [responsavel, setResponsavel] = useState();
  const [plantas, setPlantas] = useState([]);
  const [loadingPlanta, setLoadingPlanta] = useState(true);
  const [planta_id, setPlantaId] = useState();
  const [cliente_id, setClienteId] = useState();
  const [vendedor, setVendedor] = useState();
  const [tcs_responsavel, setTcsResponsavel] = useState();
  const [frase_abertura_app, setFraseAberturaApp] = useState();
  const [admin_id, setAdminId] = useState(user?.id);
  const [status_mudanca, setStatusMudanca] = useState(user?.nivel_acesso !== '999' ? "Pendente" : "Aprovado");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [currentCliente, setCurrentCliente] = React.useState({});
  const [currentPlantas, setCurrentPlantas] = React.useState([{}]);
  const [isTrue, setIsTrue] = useState(true);
  
  


  async function putClientes(id_cliente) {
    try {
      const plantasAux = [];
      for (let i = 0; i < plantas_array.length; i++) {
        plantasAux.push({
          nome: plantas_array[i].planta, 
          id: plantas_array[i].planta_id});
      }
      console.log(plantas_array);

      
      
      let data;
      
      if (plantas.length === 0){
        data = {
        id_henkel,
        responsavel,
        planta_id: plantasAux,
        nome,
        vendedor,
        admin_id,
        tcs_responsavel,
        frase_abertura_app,
        status_mudanca,
        }
      }else{
        data = {
        id_henkel,
        plantas,
        responsavel,
        planta_id: plantasAux,
        nome,
        vendedor,
        tcs_responsavel,
        frase_abertura_app,
        status_mudanca,
        admin_id,
        }
      }
    
      

      const response = await api.put(`/update_cliente/${id_cliente}`, data);
      console.log(data);
      
      console.log("cliente atualizado com sucesso!");
      closeModal();
      window.location.reload();
      history.push("/list_cliente");
    } catch (error) {
      console.log(error);
      console.log("cliente não foi atualizado.");
    }
  }

  const handleLogout = () => {
    try{
      setUser(null);
      Cookies.remove("authToken");
      Cookies.remove("authUser");
      history.push("/login");
      window.location.reload();
    }catch(error){
      console.log(error);
      console.log("Não foi possível deslogar");
    }
  }
  
  
  
  async function getClientes() {
    const response = await api.get("/cliente");
    setClientes(response?.data?.clientes);
    
  }

  async function getPlanta() {
    try {
      const response = await api.post("/get_cliente_planta", {
        id_cliente: cliente_id,
      });
      console.log(response.data.local_planta[0]);
      setPlantas(response.data.local_planta[0].nome_planta);
      setIsTrue(true);
    } catch (error) {
      setIsTrue(false);
      console.log(error);
    }
  }

  const  handleRemovePlanta = (planta, idx)=>{
    
   if(plantas_array.length > 1){
    let newPlantas = plantas_array.filter((s, index)=> index !== idx );
console.log(newPlantas)
    setPlantasArray(newPlantas);
   }
  }



  async function getClientes() {
    const response = await api.get("/cliente");
    setClientes(response.data.clientes);
    setClienteId(response.data.clientes?.[0].id);
  }

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  async function deleted(id_cliente) {
    console.log("deleted: " + id_cliente);
    try{
      const response = await api.delete(`/delete_cliente/${id_cliente}`)
      console.log(response.data);
    }catch(error){
    }
  }

  useEffect(() => {
    if (cliente_id) {
      getPlanta();
    }
  }, [cliente_id]);

  useEffect(() => {
    getClientes();
  }, []);

  useEffect(() => {
    // const timer = setInterval(() => {
      getClientes();
    // }, 500);
    // return () => clearInterval(timer);
  }, []);
  return (
    <Container>
      

      <LatealMenu>
        <Logo />
        <InfoContainers>
        <InfoContainer>
          {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_relatorio");
              }}
            >
              <ReactSVG src="/images/Relatorio.svg" />
              Relatórios
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_mudanca");
              }}
            >
              <ReactSVG src="/images/SolicitacaoMudanca.svg" />
              Solicitações de Mudança
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_forms_respondidos");
              }}
            >
              <ReactSVG src="/images/FormRespondido.svg" />
              Formulários Respondidos
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton 
            style={{gap: "4.5%"}}
              onClick={() => {
                history.push("/list_adm_intermediario");
              }}
            >
              <ReactSVG src="/images/AdmInter.svg" />
              Cadastro de ADM Intermediário
            </LabelButton>): (<></>)}

            <LabelButton
              style={{ gap: "5%", fontWeight: "bold" }}
              onClick={() => {
                history.push("/list_cliente");
              }}
            >
              <ReactSVG src="/images/CadastroCliente.svg" />
              Cadastro de Clientes
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_aparelho");
              }}
            >
              <ReactSVG src="/images/CadastroAparelho.svg" />
              Cadastro de Aparelhos
            </LabelButton>

            <LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_guia");
              }}
            >
              <ReactSVG src="/images/CadastroGuia.svg" />
              Cadastro de Guias
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_adesivo");
              }}
            >
              <ReactSVG src="/images/CadastroAdesivo.svg" />
              Cadastro de Adesivos
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_produto");
              }}
            >
              <ReactSVG src="/images/CadastroProduto.svg" />
              Cadastro de Produtos
            </LabelButton>
            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_supervisor");
              }}
            >
              <ReactSVG src="/images/CadastroSupervisor.svg" />
              Cadastro de Supervisores
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_linha");
              }}
            >
              <ReactSVG src="/images/CadastroLinha.svg" />
              Cadastro de Linha
            </LabelButton>

            <LabelButton onClick={()=> {
                handleLogout()
              }}>
                <ReactSVG src="/images/logOut.svg" />
              Log Out
            </LabelButton>
            
          </InfoContainer>
        </InfoContainers>
      </LatealMenu>
      <ComponentTable>
        <TitleContainer>
          <Title>Dashboard Henkel</Title>
          <Title>Seja muito bem-vindo</Title>
        </TitleContainer>
        <CardInfos>
          <CardInfo>
            <CardlabelTitle>Total de colaboradores</CardlabelTitle>
            <Cardlabel>350</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Total de x</CardlabelTitle>
            <Cardlabel>50</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Info Y</CardlabelTitle>
            <Cardlabel>20</Cardlabel>
          </CardInfo>
          <CardInfo>
            <CardlabelTitle>Info Y</CardlabelTitle>
            <Cardlabel>10</Cardlabel>
          </CardInfo>
        </CardInfos>

        <MaterialTable
          style={{
            marginTop: 20,
            width: "100%",
            maxHeight: '100%', 
            overflow: 'auto'
          }}
          localization={{
            header: {
              
              actions: "",
            },
          }}
          actions={[
            {
              icon: () => <FiEdit color="#E1000F" />,
              tooltip: "Update",
              onClick: (event, rowData) => {
                setCurrentCliente(rowData);
                setPlantasArray(rowData.planta);
                openModal();
              },
            },
            {
              icon: () => (
                <FaTrashAlt
                  color="#E1000F"
                  style={{ position: "absolute", left: "10px" }}
                />
              ),
              tooltip: "Delete",
              onClick: (event, rowData) => {
                deleted(rowData.id);
                window.location.reload();
              },
            },
            {
              icon: () => (
                <Button onClick={() => history.push("/cadastro_cliente")}>
                  <ButtonTitle>Cadastrar novo</ButtonTitle>
                </Button>
              ),
              isFreeAction: true,
            },
            
          ]}
          // icons={{
          //   Search:() => <BsSearch color="#E1000F" />
          // }}
          columns={[
            { title: "Nome", field: "nome" },
            { title: "Responsável", field: "responsavel" },
            { title: "Plantas", field: "totalPlanta" },
            { title: "Vendedor", field: "vendedor" },
            { title: "TCS Responsável", field: "tcs_responsavel" },
            { title: "ID Henkel", field: "id_henkel" },
            { title: "Frase", field: "frase_abertura_app" },
            { title: "Status da Mudança", field: "status_mudanca" },
          ]}
          data={clientes}
          options={{
            headerStyle: {
              zIndex: modalIsOpen && -1
            }
          }}
          {...console.log(clientes)}
          title="Cadastro de Clientes"
        />
      </ComponentTable>
      <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={{customStyles}}
        >
            <ComponentEdit>
         <Title>Edição de Clientes</Title>
         <ContainerInput>
         <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "row"}}>
            <CardInput>
            <TitleInput>Cliente</TitleInput>
              <Input
                style={{ width: 320 }}
                defaultValue={currentCliente.nome}
                onChange={(e) => setNome(e.target.value)}
              />
              <TitleInput>Responsável do cliente</TitleInput>
              <Input
                style={{ width: 320 }}
                defaultValue={currentCliente.responsavel}
                onChange={(e) => setResponsavel(e.target.value)}
              />
             
              

              <TitleInput>TCS Responsável</TitleInput>
              <Input
                style={{ width: 320 }}
                defaultValue={currentCliente.tcs_responsavel}
                onChange={(e) => setTcsResponsavel(e.target.value)}
              />

              <TitleInput>Frase de abertura</TitleInput>
              <Input
                style={{ width: 320 }}
                defaultValue={currentCliente.frase_abertura_app}
                onChange={(e) => setFraseAberturaApp(e.target.value)}
              />
              
              
            </CardInput>

            <CardInput>
              
              <TitleInput>Vendedor</TitleInput>
              <Input
                style={{ width: 320 }}
                defaultValue={currentCliente.vendedor}
                onChange={(e) => setVendedor(e.target.value)}
              />
              <TitleInput>ID HENKEL</TitleInput>
              <Input
                style={{ width: 320 }}
                defaultValue={currentCliente.id_henkel}
                onChange={(e) => setIdHenkel(e.target.value)}
              />

              <TitleInput>Plantas</TitleInput>
              {loadingPlanta && plantas_array?.map((item, idx) => (
                
              
                <div key={item.id}>
                  <TitleInput></TitleInput>
               
                  <div
                  
                  style={{
                  display: "flex",
                  flexDirection: "row",                 
                  alignItens: "center",
                  gap: "10%",
                }}
              >
                  <Input                  
                    style={{ width: 320, height: 45 }}
                    defaultValue= {item.nome?.[0].nome}
                    onChange={(e) =>   {
                      item.planta = e.target.value;
                      setPlantasArray([...plantas_array]);
                      setPlantaId(e.target.value);
                    }}
                  />

                  <ButtonIcon
                    style={{
                      width: 30,
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 30,
                      borderCollor: "white",
                    }}
                    onClick={() => {
                      handleRemovePlanta(item, idx)
                      console.log(idx, item);
                    }}
                  >
                    <IoRemoveCircle size={30} color="red" />
                  </ButtonIcon>
                  </div>
                </div>
              ))}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",                 
                  alignItens: "center",
                  gap: "10%",
                }}
              >
                <ButtonIcon
                  style={{
                    width: 30,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 30,
                    borderCollor: "white",
                  }}
                  disabled={plantas_array.some((planta) => planta.planta === "")}
                  onClick={() =>
                    
                    setPlantasArray([...plantas_array, { planta: "" }])
                  }
                >
                  <IoAddCircle size={30} color="red" />
                </ButtonIcon>
                
              </div>
              
              
            </CardInput>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10%",
                }}>
          

              <ButtonSave
                style={{ borderColor: "red" }}
                onClick={() => setIsOpen(false)}
              >
                <ButtonTitle style={{ color: "red", textAlign: "center" }}>Cancelar</ButtonTitle>
              </ButtonSave>
              <ButtonSave 
              disabled={plantas_array.some((planta) => planta.planta === "")}
                onClick={() => 
                  putClientes(currentCliente.id)}
                style={{ backgroundColor: "red"  }}>
                <ButtonTitle style={{ color: "white", textAlign: "center" }}>Salvar</ButtonTitle>
              </ButtonSave>
              </div>


            </div>
          </ContainerInput>
          </ComponentEdit>

      </Modal>



    </Container>
  );
}
export default ListCliente;
