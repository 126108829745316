import styled from "styled-components";
import Background from "../../../assets/Background.png";
import Logoimg from "../../../assets/logo.png";


export const Container = styled.div`
  display: flex;
  justify-content: row;
  background-color: #eff1f9;
  width: 100%;
  height: 100%;
`;
export const Logo = styled.div`
  background-image: url(${Logoimg});
  background-size: cover;
  background-repeat: no-repeat;
  height: 45.25px;
  width: 70px;
  border-radius: 0px;
  padding: 50px;
  margin-bottom: 25px;
  padding-left: 80px;
  align-self: center;
`;
export const Title = styled.h1`
  font-family: "MiloWebPro",Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  line-height: 30px;
  letter-spacing: 0.3px;
  margin-bottom: 40px;
`;
export const miniTitle = styled.h1`
  font-family: "MiloWebPro",Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.3px;
  margin-bottom: 40px;
`;
export const LatealMenu = styled.div`
 font-family: "MiloWebPro",Arial,sans-serif;
  display: flex;
  flex-direction: column;
  width: 244px;
  box-shadow: 2px 2px #d5d6de;
  background-color: white;
  @media screen and (max-width: 1352px) {
  & {
    width: 244px;

  }
}

`;
export const CardInfos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5%;
  @media screen and (max-width: 1400px) {
  & {
    justify-content: space-between;

  }
}
`;
export const CardInfo = styled.div`
  width: 247.04px;
  height: 86px;
  background-color: #e1000f;
  align-items: flex-start;
  padding-left: 30px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
  @media screen and (max-width: 1400px) {
  & {
    width: 75%;

  }
}
`;
export const TitleContainer = styled.div`
  font-family: "MiloWebPro",Arial,sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap:30%;

`;


export const CardlabelTitle = styled.div`
  font-family: "MiloWebPro",Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11.9973px;
  line-height: 16px;
  color: white;
  margin-bottom: 5px;
`;
export const Cardlabel = styled.div`
  font-family: "MiloWebPro",Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  color: white;
`;
export const ComponentTable = styled.div`
  font-family: "MiloWebPro",Arial,sans-serif;
  margin-top: 40px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  width: 84vw;;
  height: 100vh;
  @media screen and (max-width: 1352px) {
  & {
    width: 80%;
    height: 80%;

  }
}
`;
export const InfoContainers = styled.div`
  flex-direction: column;
  align-self: center;
  justify-content: center;
  @media screen and (max-height: 700px) {
  .InfoContainers {
      margin-bottom: 0;
      margin-top: 0px;

  }
}

`;
export const InfoContainer = styled.div`
  left: 5px;
  margin-bottom: 25px;
  align-items: center;
  @media screen and (max-height: 700px) {
  .InfoContainer {
      margin-bottom: 0px;

  }
}

`;
export const LabelButton = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction:row ;
  margin-bottom: 25px;
  color: #e1000f;
  align-items: center;
  cursor:pointer;

`;

export const ButtonSave = styled.div`
  width: 150px;
  height: 50px;
  background-color: #eff0f9;
  border-radius: 20px;
  align-items: center;
  font-family: "MiloWebPro",Arial,sans-serif;
  font-size: 19px;
  cursor:pointer;
  /* margin-left: auto;
  margin-right: auto; */
`;

export const Button = styled.div`
  width: 220px;
  height: 50px;
  background-color: #eff0f9;
  border-radius: 20px;
  align-items: center;
  font-family: "MiloWebPro",Arial,sans-serif;
  font-size: 19px;
  cursor:pointer;
  margin-left: auto;
  margin-right: auto;
`;
export const ButtonTitle = styled.h3`
  font-family: "MiloWebPro",Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: black;
  text-align: center;
  line-height: 50px;
`;
export const ContainerCadastro = styled.div`
  top: 50%;
  background-color: blue;
  width: 10%;
  height: 10%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-20%, -20%);
  z-index:999;
`;
export const ContainerInput = styled.div`
  align-self: center;
  flex-direction: row;
  justify-content: space-around;
  display: flex;
`;
export const CardInput = styled.div`
  width: 400px;
`;
export const TitleInput = styled.div`
  text-align: flex-start;
  color: black;
  font-size: 18px;
  margin-left: 25px;
  margin-bottom: 10px;
  font-family: "MiloWebPro",Arial,sans-serif;

`;
export const Select = styled.select`
  display: block;
  font-size: 1rem;
  padding: 0.8rem;
  width: 80%;
  margin-left: 20px;
  text-align: left;
  margin-bottom: 30px;
  /* grayscale / gray lightest */
  border: 1px solid #f0f1f7;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "MiloWebPro",Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  background-color: #f9f9f9;
`;
export const Input = styled.input`
  display: block;
  font-size: 1rem;
  padding: 0.8rem;
  width: 80%;
  margin-left: 20px;
  text-align: left;
  margin-bottom: 30px;
  /* grayscale / gray lightest */
  border: 1px solid #f0f1f7;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "MiloWebPro",Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  background-color: #f9f9f9;
`;
export const LabelButton2 = styled.div`
  margin-bottom: 25px;
  justify-content: center;
  margin-top: 14px;
`;
export const ButtonIcon = styled.div`
  cursor: pointer;
  width: 150px;
  height: 50px;
  background-color: #eff0f9;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  text-align:center;
  border-color:red;
  border-width: 1px;
  border-style: solid;
`;
export const ComponentEdit = styled.div`
    z-index: 999;
    height: 70%;
    font-family: "MiloWebPro",Arial,sans-serif;
    width: 80%;
     align-items: center;
     align-self: center;
     justify-content: center;
     margin-left: auto;
     margin-right: auto;


     @media screen and (max-width: 1352px) {
  & {
    width: 100%;
    height: 100%;
    

  }
}

`;
