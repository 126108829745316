import React, { useEffect, useState, useContext } from "react";
import { ReactSVG } from "react-svg";
import Cookies from "js-cookie";

import {
  Container,
  LatealMenu,
  Logo,
  Title,
  InfoContainers,
  InfoContainer,
  LabelButton,
  ComponentTable,
  ContainerCadastro,
  ContainerInput,
  TitleInput,
  Input,
  CardInput,
  Button,
  LabelButton2,
  Select,
} from "./styles";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/useAuth";

function CadastroMudanca() {
  let history = useHistory();
  const [id, setId] = useState();
  const [numero_linha, setNumeroLinha] = useState();
  const [cliente, setCliente] = useState();
  const [aparelho, setAparelho] = useState();
  const [nome_adesivo, setNomeAdesivo] = useState();
  const [idh_sku, setIdhSku] = useState();
  const [planta, setPlanta] = useState();
  const [area, setArea] = useState();
  const [senha, setSenha] = useState();
  const [nome, setNome] = useState();
  const [email, setEmail] = useState();
  const [isTrue, setIsTrue] = useState(true);
  const [status_mudanca, setStatusMudanca] = useState("Aprovado");
  const {user, setUser} = useAuth();

  async function postMudancas() {
    try {
      const response = await api.post("/list_mudanca", {
        id,
        area,
        numero_linha,
        aparelho,
        nome_adesivo,
        cliente,
        planta,
        idh_sku,
        senha,
        nome,
        email,
        status_mudanca,
      });
      history.push("/list_mudanca");
    } catch (error) {
      console.log(error);
    }
  }
 

  return (
    <Container>
      <LatealMenu>
        <Logo />
        <InfoContainers>
        <InfoContainer>
          {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_relatorio");
              }}
            >
              <ReactSVG src="/images/Relatorio.svg" />
              Relatórios
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_mudanca");
              }}
            >
              <ReactSVG src="/images/SolicitacaoMudanca.svg" />
              Solicitações de Mudança
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_forms_respondidos");
              }}
            >
              <ReactSVG src="/images/FormRespondido.svg" />
              Formulários Respondidos
            </LabelButton>): (<></>)}

            {user?.nivel_acesso === '999' ? (<LabelButton 
            style={{gap: "4.5%"}}
              onClick={() => {
                history.push("/list_adm_intermediario");
              }}
            >
              <ReactSVG src="/images/AdmInter.svg" />
              Cadastro de ADM Intermediário
            </LabelButton>): (<></>)}

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_cliente");
              }}
            >
              <ReactSVG src="/images/CadastroCliente.svg" />
              Cadastro de Clientes
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_aparelho");
              }}
            >
              <ReactSVG src="/images/CadastroAparelho.svg" />
              Cadastro de Aparelhos
            </LabelButton>

            <LabelButton
              style={{ gap: "4.5%" }}
              onClick={() => {
                history.push("/list_guia");
              }}
            >
              <ReactSVG src="/images/CadastroGuia.svg" />
              Cadastro de Guias
            </LabelButton>

            <LabelButton
              style={{ gap: "5%" }}
              onClick={() => {
                history.push("/list_adesivo");
              }}
            >
              <ReactSVG src="/images/CadastroAdesivo.svg" />
              Cadastro de Adesivos
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_produto");
              }}
            >
              <ReactSVG src="/images/CadastroProduto.svg" />
              Cadastro de Produtos
            </LabelButton>
            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_supervisor");
              }}
            >
              <ReactSVG src="/images/CadastroSupervisor.svg" />
              Cadastro de Supervisores
            </LabelButton>

            <LabelButton
              style={{ gap: "4%" }}
              onClick={() => {
                history.push("/list_linha");
              }}
            >
              <ReactSVG src="/images/CadastroLinha.svg" />
              Cadastro de Linha
            </LabelButton>
          </InfoContainer>
        </InfoContainers>
      </LatealMenu>
      <ContainerCadastro>
        <ComponentTable>
          <Title>Solicitações</Title>
          <ContainerInput>
            <CardInput>
            <TitleInput>Categoria:</TitleInput>
            <TitleInput>Usuário:</TitleInput>
            <TitleInput>Cliente:</TitleInput>
              <form>
                <Select onChange={(v) => setCliente(v.target.value)}>
                  <option defaultValue={"Labeling"}>Labeling</option>
                  <option value={"EOL"}>EOl</option>
                </Select>
              </form>

              <TitleInput>Número da Linha:</TitleInput>
              <Input
                style={{ width: 320 }}
                onChange={(e) => setNumeroLinha(e.target.value)}
              />

              <TitleInput>IDH do SKU:</TitleInput>
              <Input
                style={{ width: 320 }}
                onChange={(e) => setIdhSku(e.target.value)}
              />
              
              
              <div
              style={{display: "flex",justifyContent: "space-between", alignItens: "center"}}>
              <Button
                onClick={() => postMudancas()}
                style={{ backgroundColor: "red", marginLeft: "190px" }}
              >
                <LabelButton2 style={{ color: "white" }}>
                  Cadastrar
                </LabelButton2>
              </Button>
              
              <Button
                style={{ borderColor: "red" }}
                onClick={() => history.push("/list_mudanca")}>
                <LabelButton2 style={{ color: "red", textAlign: "center" }}>
                  Cancelar
                </LabelButton2>
              </Button>
              </div>
            </CardInput>

            
            <CardInput>
            <TitleInput>Data e Horário:</TitleInput>
            <TitleInput>Planta:</TitleInput>
              <form>
                <Select onChange={(v) => setPlanta(v.target.value)}>
                  <option defaultValue={"Labeling"}>Labeling</option>
                  <option value={"EOL"}>EOl</option>
                </Select>
              </form>

              <TitleInput>Nome do Adesivo:</TitleInput>
              <Input
                style={{ width: 320 }}
                onChange={(e) => setNomeAdesivo(e.target.value)}
              />

              <TitleInput>Aparelho:</TitleInput>
              <Input
                style={{ width: 320 }}
                onChange={(e) => setAparelho(e.target.value)}
              />
              
              
              
            </CardInput>
          </ContainerInput>
        </ComponentTable>
      </ContainerCadastro>
    </Container>
  );
}
export default CadastroMudanca;
